import { userDetails, loginStatus ,childIdData,paymentDetails} from './Redux.Type'

export const UserDetails = (userDetailsJson) => {
    return {
        type: userDetails,
        userDetailsJson
    }
}

export const LoginStatus = (userLoginStatus) => {
    return {
        type: loginStatus,
        userLoginStatus
    }
}
export const ChildIdData = (childIdValue) => {
    return {
        type: childIdData,
        childIdValue
    }
}
export const PaymentDetails = (paymentDetailsJson) => {
    return {
        type: paymentDetails,
        paymentDetailsJson
    }
}
