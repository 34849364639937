import React, { Component } from 'react';
import Header from '../DashBoard/DashBoard';
import Footer from '../Footer/Footer';

class Gallery extends Component {
    render() {
        return (
            <div>
                <Header></Header>
                <main role="main" class="Maincontainer">
                    <div class="container" id="gallery" style={{height:'calc(100vh - 212px)'}}>
                        <div class="col-md-12">
                            <div class="row">
                                <div style={{marginTop:'1%'}}>
                                    <h2 class="title text-center text-capitalize gallery ">Gallery</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer></Footer>
            </div>
        );
    }
}

export default Gallery;