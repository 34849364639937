import React, { Component } from 'react';
import Header from '../DashBoard/DashBoard';
import Footer from '../Footer/Footer';
import './Services.css'; 

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0, paymentProviderdetails: [],
        }
        this.assessmentToFocus = React.createRef()
        this.consultantsToFocus = React.createRef()
        this.remedialToFocus = React.createRef()
        this.counsellingToFocus=React.createRef()
        this.trainingToFocus = React.createRef()
    }
    handleassessment = () => {
        if (this.assessmentToFocus.current) {
            this.assessmentToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    handleconsultant = () => {
        if (this.consultantsToFocus.current) {
            this.consultantsToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    handleremedial = () => {
        if (this.remedialToFocus.current) {
            this.remedialToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    handletraining = () => {
        if (this.trainingToFocus.current) {
            this.trainingToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    handlecounselling = () => {
        if (this.counsellingToFocus.current) {
            this.counsellingToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    render() {
        return (
            <div>
                <Header></Header>
                <main role="main" class="Maincontainer">
                    <div class="serbg">
                        <div class="container">
                            <div class="col-md-12" id="service">
                                <div class="row">
                                    <div>
                                        <h2 class="wow fadeInDown text-center">Services</h2>
                                        <div class="col-md-1 text-center center-block"></div>
                                        <div class="col-md-2 text-center center-block img-zoomin ">
                                            <a onClick={this.handleassessment}>
                                                <img src={process.env.PUBLIC_URL + "/Images/assessmentimg.jpg"} class="img-responsive center-block" />
                                                <h5>Assessment of Academic difficulties</h5>
                                            </a>
                                        </div>
                                        <div class="col-md-2 text-center center-block img-zoomout " >
                                            <a onClick={this.handleconsultant}>
                                                <img src={process.env.PUBLIC_URL + "/Images/consulatioimg.jpg"} class="img-responsive center-block" />
                                                <h5>Consultation</h5>
                                            </a>
                                        </div>
                                        <div class="col-md-2 text-center center-block img-zoomin">
                                            <a onClick={this.handleremedial}>
                                                <img src={process.env.PUBLIC_URL + "/Images/remedialimg.jpg"} class="img-responsive center-block" />
                                                <h5>Remedial Education</h5>
                                            </a>
                                        </div>
                                        <div class="col-md-2 text-center center-block img-zoomout">
                                            <a onClick={this.handletraining}>
                                                <img src={process.env.PUBLIC_URL + "/Images/trainingimg.jpg"} class="img-responsive center-block" />
                                                <h5>Training Programme</h5>
                                            </a>
                                        </div>
                                        <div class="col-md-2 text-center center-block img-zoomin">
                                            <a onClick={this.handlecounselling}>
                                                <img src={process.env.PUBLIC_URL + "/Images/assessmentimg.jpg"} class="img-responsive center-block" />
                                                <h5>Counselling</h5>
                                            </a>
                                        </div>
                                        <div class="col-md-1 text-center center-block wow pulse animated"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="activtybg">
                            <div class="container-fluid">
                                <div class="container">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="serviceBox2" style={{ overflow: 'hidden' }} id="div_Assessment" ref={this.assessmentToFocus}>
                                                    <img src={process.env.PUBLIC_URL + "/Images/assessmentimg2.jpg"} alt="Daycare" class="blob img-fluid" />
                                                    <p class="title">Assessment of Academic difficulties</p>
                                                    <p class="text-left">Comprehensive assessment to identify academic difficulties in the children. This assessment helps to find out difficulties related to writing, spelling, reading, and in Mathematics.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="serviceBox2" style={{ overflow: 'hidden' }} id="div_Consultation" ref={this.consultantsToFocus}>
                                                    <img src={process.env.PUBLIC_URL + "/Images/consulationimg.jpg"} alt="Daycare" class="blob img-fluid" />
                                                    <p class="title">Consultation</p>
                                                    <p class="text-left">
                                                        Well qualified and experienced Doctors consultation for medical, speech, motor skills, etc.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="serviceBox2" style={{ overflow: 'hidden' }} id="div_RemedialEducation" ref={this.remedialToFocus}>
                                                    <img src={process.env.PUBLIC_URL + "/Images/remedialimgnew.jpg"} alt="Daycare" class="blob img-fluid" />
                                                    <p class="title">Remedial Education</p>
                                                    <p class="text-left">
                                                        Remedial Education services are offered by well trained and qualified Special Educators in learning disability to overcome academic difficulties
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="mtop4">
                                                <div class="col-md-4">
                                                    <div class="serviceBox2 divscroll" id="div_SLDTraining" ref={this.trainingToFocus}>
                                                        <div>
                                                            <img src={process.env.PUBLIC_URL + "/Images/consulationimg.jpg"} alt="Daycare" class="blob img-fluid" />
                                                            <p class="title">SLD Training Programme</p>
                                                            <p class="text-left">
                                                                Specialized programme for parents / teachers / other health professionals who work with children. This training helps you to screen children with learning difficulties and offer remedial measures to them.
                                                            </p>
                                                            <p><strong>Do you observe the following in your child?</strong></p>
                                                            <ul class="lists">
                                                                <li>Difficulty in concentration.</li>
                                                                <li>Inconsistency in school marks / grades.</li>
                                                                <li>Gets angry / throw tantrums when asked to study.</li>
                                                                <li>Avoids study situations by giving excuses such as, I am hungry, I want to go to restroom, etc.</li>
                                                                <li>Difficulty in reading words / sentences fluently.</li>
                                                                <li>Difficulty recalling answers during exams/ tests.</li>
                                                                <li>Makes spelling mistakes while writing eg. chair as chare, beautiful as butiful, etc.</li>
                                                                <li>Mirror writing of letters /numbers eg. b as d,  m as w, 9 as p, 12 as 21, 25 as 52, etc. </li>
                                                                <li>Could spell letter by letter but unable to read the words.</li>
                                                                <li>Incomplete answers.</li>
                                                                <li>Forgets / gets blank in exams/tests.</li>
                                                                <li>Difficulty in doing basic operations (addition, subtraction, multiplication and division) in Mathematics.</li>
                                                            </ul>
                                                            <p><strong>Course Content</strong></p>
                                                            <div class="lists">
                                                                <ul>
                                                                    <li>Definition & History of LD.</li>
                                                                    <li>LD & Intelligence.</li>
                                                                    <li>Types and Classification of LD.</li>
                                                                    <li>Assessment of LD.</li>
                                                                    <li>Remedial teaching.</li>
                                                                    <li>Multisensory approach</li>
                                                                    <li>Formulation of Individualized Educational Programme (IEP).</li>
                                                                    <li>Behavioural Disorders in Children.</li>
                                                                    <li>Counselling.</li>
                                                                </ul>
                                                            </div>
                                                            <p><strong>Eligibility Criteria</strong></p>
                                                            <div class="lists">
                                                                <ul>
                                                                    <li>Minimum 12 std.</li>
                                                                    <li>Knowledge of English are essential.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="serviceBox2" style={{ overflow: 'hidden' }} id="div_Counsellingservices" ref={this.counsellingToFocus}>
                                                        <img src={process.env.PUBLIC_URL + "/Images/remedialimgnew.jpg"} alt="Daycare" class="blob img-fluid" />
                                                        <p class="title">Counselling Services</p>
                                                        <p class="text-left">
                                                           Counselling is an appraisal to parents / caregivers about the children's academic / behavioral issues, developmental 
                                                           disorders if any for proper understanding and further management.
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-4">
                                                    <div class="serviceBox2" style={{ overflow: 'hidden' }}>
                                                        <img src={process.env.PUBLIC_URL+"/Images/consulationimg.jpg"} alt="Daycare" class="blob img-fluid" />
                                                      
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer></Footer>
            </div>
        );
    }
}

export default Services;