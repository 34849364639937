import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../DashBoard/DashBoard';
import '../MediaCss/viri.css';
import '../MediaCss/bootstrap.min.css';
import { Input, Modal, Button, Space, Card, Form, Row, Col, Checkbox, Descriptions } from 'antd';
import 'antd/dist/antd.css';
import { RegisterUser } from './RegisterService';
import { openPayModal, savepaymentresponse } from '../../Components/Payment/RazorPayService'
import { GetUserInfo, SaveCartSession, PreProcessSessionOrder, ProcessOrder } from '../Payment/PaymentService';
import './Register.css';
import { CheckOutlined } from '@ant-design/icons';
import { validateInteger, validateName } from '../../Utils/Validation';

class Register extends Component {
    constructor() {
        super();
        this.state = {
            userfullData: [], userId: '', showConsentForm: false, termsChecked: false, showPaymentMethod: false,
            registerDetails: {}, paymentProviderdetails: [], sessionId: "", providerId: "", showNEFTPayment: false,
            transactionId: '', registerLoading: false,registerRedirect:false
        }
    }
    RegisterUser = async () => {
        debugger;
        // if (this.state.termsChecked === true) {
        this.setState({ registerLoading: true })
        var userdata = this.state.userfullData;
        if (userdata.Password === userdata.ConfirmPassword) {
            userdata.Zipcode = parseInt(userdata.Zipcode);
            userdata.GroupId="3";
            let registerUserResponse = await RegisterUser(userdata);
            if (registerUserResponse.businessRules.length === 0) {
                var registerUserId = registerUserResponse.token;
                var registerdetails = {
                    Email: userdata.Email,
                    Mobile: userdata.MobileNo,
                    Amount: 1
                }
                this.setState({ userId: registerUserId, registerDetails: registerdetails, registerLoading: false })
                Modal.success({
                    title: 'Register Success',
                    content: 'Thank you for registering with Viriddham!'
                })
                this.props.history.push('/Login');

                // this.onSubmitConsentForm();
            }
            else {
                this.setState({ registerLoading: false })
                Modal.error({
                    title: 'Error Message',
                    content: registerUserResponse.businessRules[0].description
                })
            }
        }
        else {
            this.setState({ registerLoading: false })
            Modal.error({
                title: 'Error Message',
                content: "Enter Passwords correctly!"
            })
        }

        // }
        // else {
        //     Modal.error({
        //         title: 'Error Message',
        //         content: 'Please Agree the Terms and Conditions'
        //     })
        // }
    }
    onInputChange = async (e) => {
        var name = '';
        var value = '';
        if (e.target.name === 'Email') {
            var re = /^[0-9a-zA-Z/@/.]+$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                name = e.target.name;
                value = e.target.value;
            }
        }
        else if (e.target.name === 'MobileNo') {
            if (e.target.value.length <= 10) {
                var inputValue = await validateInteger(e.target.value);
                name = e.target.name;
                value = inputValue;
            }
        }
        else if (e.target.name === 'Zipcode') {
            if (e.target.value.length <= 6) {
                var inputValue = await validateInteger(e.target.value);
                name = e.target.name;
                value = inputValue;
            }
        }
        else {
            name = e.target.name;
            value = e.target.value;
        }
        this.setState((prevState) => ({
            userfullData: { ...prevState.userfullData, [name]: value }
        }))
    }
    onInputNameChange = async (e) => {
        debugger;
        var name = '';
        var value = '';
        var inputnameValue = await validateName(e.target.value);
        name = e.target.name;
        value = inputnameValue;
        this.setState((prevState) => ({
            userfullData: { ...prevState.userfullData, [name]: value }
        }))
    }
    onCancel = () => {
        this.setState({ showPaymentMethod: false, showNEFTPayment: false })
    }
    onSubmitOtherPaymentmethod = async () => {
        var userInfoRequest = this.state.registerDetails;
        let userResponse = await GetUserInfo(userInfoRequest)
        if (userResponse.businessRules.length === 0) {
            var items = [];
            var orderItems = {
                priceId: parseInt("1"),
                amount: userInfoRequest.Amount
            }
            items.push(orderItems);
            var cartSessionData = {
                User: userResponse,
                Items: items,
                ChangedBy: userResponse.firstName
            }
            var cartSession = {
                CartSesionData: cartSessionData,
                ChangedBy: userResponse.firstName
            }
            let sesionResponse = await SaveCartSession(cartSession);
            var sessionIdResponse = sesionResponse.sessionData.sessionId;
            let preProcessresponse = await PreProcessSessionOrder(sessionIdResponse);
            if (preProcessresponse.businessRules.length === 0) {
                var paymentData = {
                    ProviderId: preProcessresponse.paymentProviders[0].providerId,
                    ProviderName: preProcessresponse.paymentProviders[0].providerName,
                    PaymentOrderId: preProcessresponse.paymentProviders[0].providerOrderCode,
                    ClientKey: preProcessresponse.paymentProviders[0].clientKey,

                    OrderAmount: preProcessresponse.order.totalAmount * 100,
                    ItemType: "Register",
                    UserId: preProcessresponse.userId,
                    ChildId: "0",
                    FirstName: preProcessresponse.order.user.firstName,
                    EMail: preProcessresponse.order.user.email,
                    MobileNo: preProcessresponse.order.user.mobileNo,
                    Price: preProcessresponse.order.items[0].amount,
                }
                var providerIdResponse = paymentData.ProviderId;
                this.setState({
                    paymentProviderdetails: paymentData, sessionId: sessionIdResponse, providerId: providerIdResponse, showConsentForm: false, showPaymentMethod: false
                })
                let razorPayResponse = await openPayModal(sessionIdResponse, paymentData);
            }
            else {
                Modal.error({
                    title: 'Error Message',
                    content: preProcessresponse.businessRules[0].description
                })
            }
        }
        else {
            Modal.error({
                title: 'Error Message',
                content: userResponse.businessRules[0].description
            })
        }
    }
    onChkterms = (e) => {
        var checked = e.target.checked;
        this.setState({ termsChecked: checked, showConsentForm: true })
    }
    onNeftPaymentClick = () => {
        this.setState({ showNEFTPayment: true })
    }
    onInputTransactionChange = (e) => {
        var transId = e.target.value;
        this.setState({ transactionId: transId, showNEFTPayment: true })
    }
    onNeftTransactionClick = async () => {
        if (this.state.transactionId !== '') {
            var userInfoRequest = this.state.registerDetails;
            var userid = this.state.userId;
            let userResponse = await GetUserInfo(userInfoRequest)
            if (userResponse.businessRules.length === 0) {
                var transid = this.state.transactionId
                var dataResponse = {
                    transactionId: transid
                }
                var paymentRequest = {
                    PaymentProviderId: 'E6F92A58-A1F0-4194-80CA-DE551DF7683D',
                    Response: JSON.stringify(dataResponse),
                    ChangedBy: userResponse.firstName,
                    ChildId: "0",
                    UserId: userid,
                    PaymentType: "NEFT Register"
                }
                let NEFTPayResponse = await savepaymentresponse(paymentRequest);
                if (NEFTPayResponse.businessRules.length === 0) {
                    this.setState({ showNEFTPayment: false, showPaymentMethod: false })
                    Modal.success({
                        title: 'Payment Success',
                        content: 'Thank you for registering with Viriddham!'
                    })
                }
                else {
                    Modal.error({
                        title: 'Error Message',
                        content: userResponse.businessRules[0].description
                    })
                }
            }
            else {
                Modal.error({
                    title: 'Error Message',
                    content: userResponse.businessRules[0].description
                })
            }
        }
        else {
            Modal.error({
                title: 'Error Message',
                content: "Enter TransactionID"
            })
        }
    }
    registerRediect=()=>{
        debugger;
        this.props.history.push('/Login');
    }
    render() {
        const layout = {
            labelCol: { span: 10 },
            wrapperCol: { span: 8 },
        }
        const taillayout = {
            labelCol: { span: 10 },
            wrapperCol: { span: 18 },
        }
        return (

            <div>
                <Header></Header>
                <div>
                    <Form {...layout} id="frmFieldType" className="card-footer-right" onFinish={() => this.RegisterUser()} initialValues={{ remember: true }}>
                        <Card className="scrollHide cronusfont register-form"
                            actions={[
                                <Form.Item {...taillayout}>
                                    <Space className="pull-right">
                                        <Button type="primary" loading={this.state.registerLoading} htmlType='submit' style={{ height: '37px', fontSize: '18px' }}>Register</Button>
                                    </Space>
                                </Form.Item>
                            ]}>
                            <div className='registerbackground'>
                                <h2 class="cronusfont" style={{ paddingTop: '15px', marginLeft: '32%', fontWeight: 'bold' }}>Parent Registration</h2>
                                <Form.Item initialValue={this.state.userfullData.FirstName} name="FirstName" label="Father FirstName" rules={[{ required: true, message: 'Please enter First Name!' }]}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="FirstName" value={this.state.userfullData.FirstName} onChange={e => this.onInputNameChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.LastName} name="LastName" label="Father LastName" rules={[{ required: true, message: 'Please enter Last Name!' }]}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="LastName" value={this.state.userfullData.LastName} onChange={e => this.onInputNameChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.MotherFirstName} name="MotherFirstName" label="Mother FirstName" rules={[{ required: true, message: 'Please enter Mother FirstName!' }]}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="MotherFirstName" value={this.state.userfullData.MotherFirstName} onChange={e => this.onInputNameChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.MotherLastName} name="MotherLastName" label="Mother LastName" >
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="MotherLastName" value={this.state.userfullData.MotherLastName} onChange={e => this.onInputNameChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.CareTakerFirstName} name="CareTakerFirstName" label="CareTaker FirstName">
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="CareTakerFirstName" value={this.state.userfullData.CareTakerFirstName} onChange={e => this.onInputNameChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.CareTakerLastName} name="CareTakerLastName" label="CareTaker LastName" >
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="CareTakerLastName" value={this.state.userfullData.CareTakerLastName} onChange={e => this.onInputNameChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.Email} name="Email" label="Email" rules={[{
                                    type: 'email', message: 'The input is not valid E-mail!',
                                }, { required: true, message: 'Please enter Email!' }]}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="Email" value={this.state.userfullData.Email} onChange={e => this.onInputChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.MobileNo} name="MobileNo" label="Mobile Number" rules={[{ len: 10, message: 'Please enter a valid Mobile Number!' }, { required: true, message: 'Please enter Mobile Number!' }]}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="MobileNo" maxLength={10} value={this.state.userfullData.MobileNo} onChange={e => this.onInputChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.Password} name="Password" label="Password" rules={[{ required: true, message: 'Please enter Password!' }]}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input.Password name="Password" value={this.state.userfullData.Password} onChange={e => this.onInputChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.ConfirmPassword} name="ConfirmPassword" label="Confirm Password" dependencies={['Password']} hasFeedback
                                    rules={[{ required: true, message: 'Please enter Confirm Password!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('Password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),]}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input.Password name="ConfirmPassword" value={this.state.userfullData.ConfirmPassword} onChange={e => this.onInputChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.Address} name="Address" label="Address" rules={[{ required: true, message: 'Please enter Address!' }]}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="Address" value={this.state.userfullData.Address} onChange={e => this.onInputChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.City} name="City" label="City" rules={[{ required: true, message: 'Please enter City!' }]}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="City" value={this.state.userfullData.City} onChange={e => this.onInputNameChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item initialValue={this.state.userfullData.Zipcode} name="Zipcode" label="Zipcode" rules={[{ required: true, message: 'Please enter ZipCode!' }]}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="Zipcode" value={this.state.userfullData.Zipcode} maxLength={6} onChange={e => this.onInputChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                {/* <Form.Item name="termsconditon" label="" style={{ marginLeft: '42%' }}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Checkbox onChange={e => this.onChkterms(e)}>I agree to the Terms and Conditions</Checkbox>
                                        </Col>
                                    </Row>
                                </Form.Item> */}
                                {/* <Checkbox onChange={e => this.onChkterms(e)}>I agree to the Terms and Conditions</Checkbox> */}
                            </div>
                        </Card>
                    </Form>
                    {this.state.showPaymentMethod &&
                        <Modal className="modal-custom-top"
                            maskClosable={false} height={200} bodyStyle={{ textAlign: 'center' }}
                            title="Payment Options"
                            onCancel={() => this.onCancel()}
                            visible={this.state.showPaymentMethod}
                            footer={false}>
                            <Space direction='vertical'>
                                <div>
                                    <h3 style={{ float: 'left' }}>Note:</h3><h5 className='paymentnote'>Rs.100 Registation Charges applicable.</h5>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <Space >
                                        <Button type="primary" onClick={() => this.onNeftPaymentClick()}>Pay Using NEFT</Button>
                                        <Button type="primary" onClick={() => this.onSubmitOtherPaymentmethod()}>Other Payment Mode</Button>
                                    </Space>
                                </div>
                            </Space>
                        </Modal>}
                    {this.state.showNEFTPayment &&
                        <Modal className="modal-custom-top "
                            maskClosable={false} height={200} width={600} bodyStyle={{ textAlign: 'center' }}
                            title="NEFT Payment"
                            onCancel={() => this.onCancel()}
                            visible={this.state.showNEFTPayment}
                            footer={false}>
                            <Space direction='vertical'>
                                <h3>Bank Details</h3>
                                <div>
                                    <table class="table table-bordered mb-0">
                                        <tbody>
                                            <tr>
                                                <td>Account Holder Name</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    VIRIDDHAM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Account Number</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    4397002100224888
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>IFSC Code</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    PUNB0439700
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Bank Name</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    Punjab National Bank
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <Space >
                                        <label style={{ float: 'left' }}>Transaction ID</label>
                                        <Input style={{ float: 'left' }} name="transactionId" className="form-control" value={this.state.transactionId} onChange={e => this.onInputTransactionChange(e)} />
                                        <Button type="primary" onClick={() => this.onNeftTransactionClick()}>Submit</Button>
                                    </Space >
                                </div>
                            </Space>
                        </Modal>}
                </div>
                <Footer></Footer>
            </div>
        )
    }
}
export default withRouter(Register);