export async function validateName(e) {
    return e.replace(/[^a-zA-Z. ]/ig, "");
}

export async function validateColumnName(e) {
    return e.replace(/\W/ig, "");
}

export async function validateCommonName(e) {
    return e.replace(/[^a-zA-Z0-9_ -]/ig, "");
}
export async function validateInteger(e) {
    return e.replace(/[^[0-9]/ig, "");
}
