import axios from "axios";
import {ServiceUrl} from '../../WebConfig/web.config';

export async function ChangeUserPassword(passwordRequest) {
    let passwordResponse = [];
    var data = JSON.stringify(passwordRequest);
    await axios.post(ServiceUrl + 'changeuserpassword', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        passwordResponse = response.data;
    }))
    return passwordResponse;
}