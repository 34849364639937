import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../DashBoard/DashBoard';
import '../MediaCss/viri.css';
import '../MediaCss/bootstrap.min.css';
import { Input, Modal, Button, Space, Row, Col, Form, Spin } from 'antd';
import 'antd/dist/antd.css';
import { Authenticate, ChangePasswordRequest, UpdatePassword, TempData } from './LoginService';
import { connect } from 'react-redux';
import { LoginStatus, UserDetails, PaymentDetails } from '../../Redux/Redux.Action';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LoadingOutlined } from '@ant-design/icons'

class Login extends Component {
    refforgotpasswordmail = React.createRef();
    refforgotpasswordotp = React.createRef();
    constructor() {
        super();
        this.state = {
            authData: {}, loginemail: '', loginPassword: '', mailRequest: false, otpRequest: false, updatePassworddata: [],
            userPasswordDetails: [], userMail: [], paymentRedirect: false, loading: false
        }
        AOS.init({ duration: 800 });
    }
    componentDidMount = async () => {
        let url = window.location.href;
        var childId="";
        var childName="";
        var response="";
        if (url.includes('?')) {
            let paramString = url.split('?')[1];
            let queryString = new URLSearchParams(paramString);
            for(let pair of queryString.entries()) {
               if(pair[0]=="id"){
                 childId = pair[1]; 
               }
               if(pair[0]=="name"){
                 childName = pair[1];
               }
            }
            var childdata = {
                childId: childId,
                childName: childName,
                response: response
            }
            // sessionStorage.setItem("PaymentChildId", childdata);
            // sessionStorage.setItem("PaymentUserName", childName);
            this.props.PaymentDetails(childdata);
            this.setState({paymentRedirect:true})
        }
    }
    Authenticate = async () => {
        this.setState({ loading: true })
        if ((this.state.loginemail != "") && (this.state.loginPassword != "")) {
            var logpassword = this.state.loginPassword;
            var loginRequest = {
                SignOn: this.state.loginemail,
                Password: logpassword
            }
            let loginResponse = await Authenticate(loginRequest);
            if (loginResponse.businessRules.length === 0) {
                this.props.UserDetails(loginResponse);
                this.props.LoginStatus(loginResponse.success);
                if (this.state.paymentRedirect === true) {
                    sessionStorage.setItem("PaymentSecurity", "Success");
                    this.props.history.push('./Payment');
                }
                else {
                    this.props.history.push('./UserDashboard');
                }
            }
            else {
                Modal.error({
                    title: 'Error Message',
                    content: loginResponse.businessRules[0].description
                })
            }
        }
        else {
            Modal.error({
                title: 'Error Message',
                content: 'Enter Required Fields'
            })
        }
        this.setState({ loading: false })
    }
    ForgetPassword = () => {
        this.setState({ mailRequest: true, })
    }
    onInputChange = async (e) => {
        var fieldName = e.target.name;
        var email = this.state.loginemail;
        var password = this.state.loginPassword;
        if (fieldName === 'loginUser') {
            email = e.target.value;
        }
        else {
            password = e.target.value;
        }
        this.setState({ loginemail: email, loginPassword: password })
    };
    onChangemailid = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState((prevState) => ({
            userMail: { ...prevState.userMail, [name]: value }
        }))
    }
    onNextForgotPassword = async () => {
        var userMail = this.state.userMail.userMailId;
        let passwordResponse = await ChangePasswordRequest(userMail)
        if (passwordResponse.businessRules.length === 0) {
            var responsedata = {
                Token: passwordResponse.token,
                UserId: passwordResponse.userId,
                Name: passwordResponse.name
            }
            this.setState({ mailRequest: false, otpRequest: true, userPasswordDetails: responsedata })
        }
        else {
            Modal.error({
                title: 'Error Message',
                content: passwordResponse.businessRules[0].description
            })
        }
    }
    onChangeUpdatePassword = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState((prevState) => ({
            updatePassworddata: { ...prevState.updatePassworddata, [name]: value }
        }))
    }
    onupdatenewPassword = async () => {
        var userdata = this.state.userPasswordDetails;
        var passworddata = this.state.updatePassworddata;
        var passwordRequest = {
            UserId: userdata.UserId,
            Token: userdata.Token,
            NewPassword: passworddata.newPassword,
            VerificationCode: passworddata.verificationCode,
            ChangedBy: userdata.Name,
        }
        let updatepasswordResponse = await UpdatePassword(passwordRequest);
        if (updatepasswordResponse.businessRules.length === 0) {
            this.setState({ mailRequest: false, otpRequest: false })
            Modal.success({
                content: "Password Changed Successfully!"
            })
        }
        else {
            Modal.error({
                title: 'Error Message',
                content: updatepasswordResponse.businessRules[0].description
            })
        }
    }
    onforgotPasswordCancel = () => {
        this.setState({
            mailRequest: false, otpRequest: false
        })
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.Authenticate();
        }
    }
    render() {
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

        const forgotpasswordlayout = {
            labelCol: { span: 10 },
            wrapperCol: { span: 12 },
        }
        return (
            <div>
                <Spin indicator={antIcon} tip="Loading..." spinning={this.state.loading}>
                    <Header></Header>
                    <div class="loginbackground " style={{ height: 'calc(100vh - 231px)', overflowY: 'auto' }}>
                        <div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800" class="divcontainer  container p-t-b-30 max-wid-600">
                            <div class="loginblock" id="DivLogin" runat="server" style={{ display: 'block' }} >
                                <div class="row col-12">
                                    <div class="form-group pull-left">
                                        <h3 class="col-12 p-0 cronusfont">Log in</h3>
                                    </div>
                                </div>
                                <div class="row col-12">
                                    <label id="lblsignon" class="col-sm-3 p-0">Sign In <span style={{color:'red'}}>*</span></label>
                                    <div class="col-md-12 form-group p-0">
                                        <Input name="loginUser" className="form-control" placeholder="Email or Mobile No " value={this.state.loginemail} onChange={e => this.onInputChange(e)} onKeyDown={this._handleKeyDown} required={true} />
                                    </div>
                                    <label id="lblsignon" class="col-sm-3 p-0">Password <span style={{color:'red'}}>*</span></label>
                                    <div class="col-md-12 form-group p-0">
                                        <Input.Password name="loginPassword" placeholder="Password" value={this.state.loginPassword} onChange={e => this.onInputChange(e)} onKeyDown={this._handleKeyDown} />
                                    </div>
                                </div>
                                <div class="row col-12">
                                    <div class="col-md-12 form-group">
                                        <a style={{ color: 'green', textDecoration: 'underline', cursor: 'pointer' }} class="pull-right" onClick={this.ForgetPassword}>Forgot Password</a>
                                    </div>
                                </div>
                                <div className='pull-left'>
                                    <a class="btn btn-primary loadingbtn btn-common-prime" style={{ display: 'none' }}><i class="fa fa-spinner fast-right-spinner"></i>Loading</a>
                                    <Button type="primary" className='btn btn-primary btn-common-prime' onClick={this.Authenticate}>Login</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.mailRequest
                        &&
                        <Modal className="modal-custom-top"
                            title="Forgot Password" visible={this.state.mailRequest}
                            onCancel={() => this.onforgotPasswordCancel()}
                            onOk={() => this.refforgotpasswordmail.current.submit()}
                            okText="Next"
                        >
                            <Form {...forgotpasswordlayout} id="frmmailRequest" ref={this.refforgotpasswordmail} className="card-footer-right" initialValues={{ remember: true }} onFinish={this.onNextForgotPassword} >
                                <Form.Item initialValue={this.state.userMail.userMailId} name="userMailId" label="Enter Your Registered Email" rules={[{ required: true, message: 'Please enter Valid Email!' }]} >
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="userMailId" value={this.state.userMailId} onChange={e => this.onChangemailid(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Form>
                        </Modal>
                    }
                    {this.state.otpRequest &&
                        <Modal className="modal-custom-top"
                            title="Forgot Password" visible={this.state.otpRequest}
                            onCancel={() => this.onforgotPasswordCancel()}
                            onOk={() => this.refforgotpasswordotp.current.submit()}
                            okText="Save"
                        >
                            <Form {...forgotpasswordlayout} id="frmotpRequest" ref={this.refforgotpasswordotp} className="card-footer-right" initialValues={{ remember: true }} onFinish={this.onupdatenewPassword} >
                                <Form.Item initialValue={this.state.updatePassworddata.verificationCode} name="verificationCode" label="Enter OTP" rules={[{ required: true, message: 'Please enter OTP!' }]} >
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="verificationCode" value={this.state.updatePassworddata.verificationCode} onChange={e => this.onChangeUpdatePassword(e)} />
                                        </Col>
                                    </Row>

                                </Form.Item>
                                <Form.Item initialValue={this.state.updatePassworddata.newPassword} name="newPassword" label="New PassWord" rules={[{ required: true, message: 'Please enter New Password!' }]} >
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="newPassword" value={this.state.updatePassworddata.newPassword} onChange={e => this.onChangeUpdatePassword(e)} />
                                        </Col>
                                    </Row>

                                </Form.Item>
                                <Form.Item initialValue={this.state.updatePassworddata.confirmPassword} name="confirmPassword" label="Confirm PassWord" rules={[{ required: true, message: 'Please enter Confirm Password!' }]} >
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={24}>
                                            <Input name="confirmPassword" value={this.state.updatePassworddata.confirmPassword} onChange={e => this.onChangeUpdatePassword(e)} />
                                        </Col>
                                    </Row>

                                </Form.Item>
                            </Form>
                        </Modal>
                    }
                    <Footer></Footer>
                </Spin>
            </div>
        );
    }
}
const mapStateToProp = state => {
    return {
        userLoginStatus: state.userLoginStatus,
        userDetailsJson: state.userDetailsJson,
        paymentDetailsJson: state.paymentDetailsJson
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        UserDetails: userDetailsJson => dispatch(UserDetails(userDetailsJson)),
        LoginStatus: userLoginStatus => dispatch(LoginStatus(userLoginStatus)),
        PaymentDetails: paymentDetailsJson => dispatch(PaymentDetails(paymentDetailsJson))
    }
}
export default connect(mapStateToProp, mapDispatchToProps)(withRouter(Login));