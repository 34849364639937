import axios from "axios";
import { ServiceUrl } from '../../WebConfig/web.config';

export async function GetUserInfo(userRequest) {
    let userResponse = [];
    var data = JSON.stringify(userRequest);
    await axios.post(ServiceUrl + 'getuserinfo', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        userResponse = response.data;
    }))
    return userResponse;
}
export async function SaveCartSession(cartSession) {
    let userResponse = [];
    var data = JSON.stringify(cartSession);
    await axios.post(ServiceUrl + 'savecartsession', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        userResponse = response.data;
    }))
    return userResponse;
}
export async function PreProcessSessionOrder(sessionIdRequest) {
    let userResponse = [];
    var data = JSON.stringify(sessionIdRequest);
    await axios.post(ServiceUrl + 'preprocesssessionorder', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        userResponse = response.data;
    }))
    return userResponse;
}
export async function ProcessOrder(sessionIdRequest) {
    let orderResponse = [];
    var data = JSON.stringify(sessionIdRequest);
    await axios.post(ServiceUrl + 'processorder', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        orderResponse = response.data;
    }))
    return orderResponse;
}
export async function GetChildDifficulty(childId) {
    let mailresponse = [];
    const response = await axios.post(ServiceUrl + 'getChildDifficulty?ChildId=' + childId).then((response) => {
        mailresponse = response.data;
    })
    return mailresponse;
}





