import React, { Component } from 'react';
import axios from "axios";
import { ServiceUrl } from '../../WebConfig/web.config';
import { Modal, message } from 'antd';
import 'antd/dist/antd.css';
import UserDashboard from '../UserDashboard/UserDashboard';
import Login from '../Login/Login';

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}
export async function openPayModal(sessionId, paymentDetails) {
    debugger;
    let userResponse = [];
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );
    const options = {
        key: paymentDetails.ClientKey, // Enter the Key ID generated from the Dashboard
        amount: paymentDetails.OrderAmount,
        name: paymentDetails.ProviderName,
        description: "Payment Transaction",
        image: "https://razorpay.com/favicon.png",
        order_id: paymentDetails.PaymentOrderId,

        handler: async function (response) {
            debugger;
            var dataResponse = {
                PaymentId: response.razorpay_payment_id,
                OrderId: response.razorpay_order_id,
                Signature: response.razorpay_signature,
            };
            var paymentResponse = {
                PaymentProviderId: paymentDetails.ProviderId,
                SessionId: sessionId,
                Response: JSON.stringify(dataResponse),
                ChangedBy: paymentDetails.FirstName,
                ChildId: paymentDetails.ChildId,
                UserId: paymentDetails.UserId,
                PaymentType: paymentDetails.ItemType,
                ChildName: paymentDetails.ChildName
            }
            var data = JSON.stringify(paymentResponse);
            await axios.post(ServiceUrl + 'savepaymentresponse', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
                userResponse = response.data;
            }))
            if (paymentResponse.PaymentType === "Register") {
                message.success('Thank you for registering with Viriddham!');
            }
            else {
                message.success('Payment Done Successfully!!!');
                <UserDashboard></UserDashboard>
            }
            return userResponse;
            // if (typeof response.razorpay_payment_id == 'undefined' || response.razorpay_payment_id < 1) {
            //     if(paymentDetails.ItemType==="Register"){
            //         <Login></Login>
            //     }
            //     else{
            //         <UserDashboard></UserDashboard>
            //     }
            //  } else {
            //     <Login></Login>
            //  }
            // var data = JSON.stringify(sessionId);
            // let orderResponse = [];
            // await axios.post(ServiceUrl + 'processorder', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
            //     orderResponse = response.data;
            // }))
            // if (orderResponse.businessRules.length === 0) {

            // }
            // else {
            //     Modal.error({
            //         title: 'Process Order Error Message',
            //         content: orderResponse.businessRules[0].description
            //     })
            // }
        },
        config: {
            display: {
                hide: [
                    { method: 'paylater' },
                    { method: 'emi' }
                ],
                preferences: { show_default_blocks: true }
            }
        },
        // prefill: {
        //     name: paymentDetails.FirstName,
        //     email: paymentDetails.EMail,
        //     contact: paymentDetails.MobileNo,
        // },
        theme: {
            color: "#0B30C1",
        },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  
};
export async function savepaymentresponse(paymentResponse) {
    debugger;
    let userResponse = [];
    var data = JSON.stringify(paymentResponse);
    await axios.post(ServiceUrl + 'savepaymentneftresponse', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        userResponse = response.data;
    }))
    return userResponse;
}
