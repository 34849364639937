// import logo from './logo.svg';
import './App.css';
import { withRouter } from 'react-router-dom';
import { HashRouter as Router, Route, Switch, BrowserRouter } from 'react-router-dom';
import DashBoard from './Components/DashBoard/DashBoard';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import AboutUs from './Components/AboutUs/About'
import Gallery from './Components/Gallery/Gallery';
import OurTeam from './Components/OurTeam/OurTeam';
import ContactUs from './Components/ContactUs/ContactUs';
import Services from './Components/Services/Services';
import Login from './Components/Login/Login';
import UserDashboard from './Components/UserDashboard/UserDashboard';
import Register from './Components/Register/Register';
import { store, persistor } from '../src/Redux/store';
import { Provider,connect } from 'react-redux';
import { LoginStatus } from '../src/Redux/Redux.Action';
import { PersistGate } from 'redux-persist/integration/react';

import TermsService from './Components/TermsandServices/TermsService';
import RefundPolicy from './Components/TermsandServices/RefundPolicy';
import PrivacyStatement from './Components/TermsandServices/PrivacyStatement';
import CaseHistory from './Components/CaseHistory/CaseHistory';
import Payment from './Components/Payment/Payment';
function App() {
  window.onbeforeunload = function() {
    this.props.history.push('/Login');
   }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          {/* <BrowserRouter basename='/Viriddham'> */}
          <Router>
            <Route exact path='/' component={Home} />
            <Route path='/Home' component={Home} />
            <Route path='/AboutUs' component={AboutUs} />
            <Route path='/Gallery' component={Gallery} />
            <Route path='/OurTeam' component={OurTeam} />
            <Route path='/ContactUs' component={ContactUs} />
            <Route path='/Services' component={Services} />
            <Route path='/Login' component={Login} />
            {/* <Route path='/*' component={Login} /> */}
            <Route path='/UserDashboard' component={UserDashboard} />
            <Route path='/Register' component={Register} />
            <Route path='/TermsService' component={TermsService} />
            <Route path='/RefundPolicy' component={RefundPolicy} />
            <Route path='/PrivacyStatement' component={PrivacyStatement} />
            <Route path='/CaseHistory' component={CaseHistory} />
            <Route path='/Payment' component={Payment} />
          </Router>
        </div>
      </PersistGate>
    </Provider>
  );
}
export default App;
