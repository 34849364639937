import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Input, Modal, Button, Space, Card, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import '../MediaCss/bootstrap.min.css';
import { GetUserInfo, SaveCartSession, PreProcessSessionOrder, GetChildDifficulty } from './PaymentService'
import { openPayModal,savepaymentresponse } from '../../Components/Payment/RazorPayService';
import { connect } from 'react-redux';
import { LoginStatus, UserDetails, PaymentDetails } from '../../Redux/Redux.Action';
import Footer from '../Footer/Footer';
import Header from '../DashBoard/DashBoard';

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0, paymentProviderdetails: [], sessionId: "", providerId: "", userDetailsData: [], paymentDate: [],neftpaymentLoading : false,
            parentName: "", price: "", email: "", mobileNo: "", showNEFTPayment: false, transactionId: "", difficultycontent: ""
        }
    }
    componentDidMount = async () => {
        debugger;
        if (this.props.userLoginStatus === true) {
            var paymentRequest = this.props.paymentDetailsJson;
            let paymentamountresponse = await GetChildDifficulty(paymentRequest.childId)
                                 paymentamountresponse.difficuilty = "SEVERE";
            if (paymentamountresponse.difficuilty != null) {
                var difficulty = paymentamountresponse.difficuilty;
                if (difficulty.toUpperCase() === "SEVERE") {
                    var difficultytest = "Per Session Rs.350 (5 to 6 days per week)";
                    paymentRequest.amount = 350;
                    paymentRequest.difficultytest = difficultytest;
                }
                else if (difficulty.toUpperCase() === "MODERATE") {
                    var difficultytest = "Per Session Rs.400 (3 to 4 days per week)";
                    paymentRequest.amount = 400;
                    paymentRequest.difficultytest = difficultytest;
                }
                else if (difficulty.toUpperCase() === "MILD") {
                    var difficultytest = "Per Session Rs.500 (1 to 2 days per week)";
                    paymentRequest.amount = 500;
                    paymentRequest.difficultytest = difficultytest;
                }
                var userdetails = this.props.userDetailsJson;
                var parentName = userdetails.name;
                var price = paymentRequest.amount;
                var difficultycontent = paymentRequest.difficultytest;
                var email = userdetails.email;
                var mobileNo = userdetails.mobileNo;
                this.setState({
                    userDetailsData: userdetails, paymentDate: paymentRequest, parentName: parentName,
                    price: price, email: email, mobileNo: mobileNo, difficultycontent: difficultycontent
                })
            }
            else {
                Modal.error({
                    title: 'Error Message',
                    content: "Error in getting Child Comment Status!"
                })
            }
        }
        else {
            this.props.history.push('./Login');
        }
    }
    onNeftPaymentClick = () => {
        this.setState({ showNEFTPayment: true })
    }
    onInputTransactionChange = (e) => {
        var transId = e.target.value;
        this.setState({ transactionId: transId, showNEFTPayment: true })
    }
    onprocessPayment = async () => {
        var userdetails = this.state.userDetailsData;
        var paymentDetail = this.state.paymentDate;
        var userInfoRequest = {
            Email: userdetails.email,
            Mobile: userdetails.mobileNo,
            Amount: paymentDetail.amount
        }
        let userResponse = await GetUserInfo(userInfoRequest)
        if (userResponse.businessRules.length === 0) {
            var items = [];
            var orderItems = {
                priceId: parseInt("1"),
                amount: userInfoRequest.Amount
            }
            items.push(orderItems);
            var cartSessionData = {
                User: userResponse,
                Items: items,
                ChangedBy: userResponse.firstName
            }
            var cartSession = {
                CartSesionData: cartSessionData,
                ChangedBy: userResponse.firstName
            }
            let sesionResponse = await SaveCartSession(cartSession);
            var sessionIdResponse = sesionResponse.sessionData.sessionId;
            let preProcessresponse = await PreProcessSessionOrder(sessionIdResponse);
            if (preProcessresponse.businessRules.length === 0) {
                var paymentData = {
                    ProviderId: preProcessresponse.paymentProviders[0].providerId,
                    ProviderName: preProcessresponse.paymentProviders[0].providerName,
                    PaymentOrderId: preProcessresponse.paymentProviders[0].providerOrderCode,
                    ClientKey: preProcessresponse.paymentProviders[0].clientKey,

                    OrderAmount: preProcessresponse.order.totalAmount * 100,
                    ItemType: "Remedial Session",
                    UserId: preProcessresponse.userId,
                    ChildId: paymentDetail.childId,
                    FirstName: preProcessresponse.order.user.firstName,
                    EMail: preProcessresponse.order.user.email,
                    MobileNo: preProcessresponse.order.user.mobileNo,
                    Price: preProcessresponse.order.items[0].amount,
                }
                var providerIdResponse = paymentData.ProviderId;
                this.setState({
                    paymentProviderdetails: paymentData, sessionId: sessionIdResponse, providerId: providerIdResponse, showConsentForm: false
                })
                let razorPayResponse = await openPayModal(sessionIdResponse, paymentData);
            }
            else {
                Modal.error({
                    title: 'Error Message',
                    content: preProcessresponse.businessRules[0].description
                })
            }
        }
        else {
            Modal.error({
                title: 'Error Message',
                content: userResponse.businessRules[0].description
            })
        }
    }
    onNeftTransactionClick = async() => {
        debugger;
        if (this.state.transactionId !== '') {
            this.setState({ neftpaymentLoading: true })
            var userid = this.props.userDetailsJson.token;
            var userdetail = this.state.userDetailsData;
            var paymentDetail = this.state.paymentDate;
            var userInfoDetails = {
                Email: userdetail.email,
                Mobile: userdetail.mobileNo,
                Amount: paymentDetail.amount
              }

           // var id = decodeURIComponent(userid);
            let userResponse = await GetUserInfo(userInfoDetails);
            if (userResponse.businessRules.length === 0) {
            var transid = this.state.transactionId
                var dataResponse = {
                transactionId: transid
                }
            var Paymenttype = "";
        var childID = paymentDetail.childId
        var paymentRequest = {
            PaymentProviderId: 'E6F92A58-A1F0-4194-80CA-DE551DF7683D',
            Response: JSON.stringify(dataResponse),
            ChangedBy: userResponse.firstName,
            ChildId: childID,
            UserId: userid,
            PaymentType: "NEFT Remedial Session",
            ChildName: paymentDetail.childName,
          }
          let NEFTPayResponse = await savepaymentresponse(paymentRequest);
          if (NEFTPayResponse.businessRules.length === 0) {
            this.setState({ showNEFTPayment: false, RegisterPaymentNeft: false, showPaymentMethod: false, showPreAssessmentresult: false, neftpaymentLoading: false, regPaymentDone: true, regPaymentNotDone: false })
            Modal.success({
              title: 'Payment Success',
              content: 'Payment Done Successfully!'
            })
            this.props.history.push('./UserDashboard');
          }
          else {
            Modal.error({
              title: 'Error Message',
              content: userResponse.businessRules[0].description
            })
          }
        }
    }
        else {
            Modal.error({
                title: 'Error Message',
                content: "Enter TransactionID"
            })
        }
    }
    onCancel = () => {
        this.setState({ showNEFTPayment: false })
    }
    render() {
        return (
            <div>
                <Header></Header>
                <div style={{ height: 'calc(100vh - 212px)', overflowY: 'auto', overflowX: 'hidden' }}>
                    <Card id="paymnet-card" title="Payment Confirm Details" style={{ width: '30%', marginLeft: '38%', marginTop: '2%' }} actions={[
                        <Space >
                            <Button type="primary" onClick={() => this.onNeftPaymentClick()}>Pay Using NEFT</Button>
                            <Button type="primary" onClick={() => this.onprocessPayment()}>Other Payment Mode</Button>
                        </Space>
                    ]}>
                        <label style={{ textAlign: 'center' }}>{this.state.difficultycontent}</label>
                        <div class="container">
                            <table id="paymenttable" class="table-striped table-borderless" style={{ width: '40%' }}>
                                <tr>
                                    <th>Parent Name</th>
                                    <td style={{ textAlign: 'left' }}>{this.state.parentName}</td>
                                </tr>
                                <tr>
                                    <th>Price</th>
                                    <td style={{ textAlign: 'left', color: 'blue' }}><b>₹{this.state.price}</b></td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td style={{ textAlign: 'left' }}>{this.state.email}</td>
                                </tr>
                                <tr>
                                    <th>MobileNo</th>
                                    <td style={{ textAlign: 'left' }}>{this.state.mobileNo}</td>
                                </tr>
                            </table>
                        </div>
                    </Card>
                </div>
                {this.state.showNEFTPayment &&
                    <Modal className="modal-custom-top "
                        maskClosable={false} height={200} width={600} bodyStyle={{ textAlign: 'center' }}
                        title="NEFT Payment"
                        onCancel={() => this.onCancel()}
                        visible={this.state.showNEFTPayment}
                        footer={false}>
                        <Space direction='vertical'>
                            <h3>Bank Details</h3>
                            <div>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Account Holder Name</td>
                                            <td style={{ textAlign: 'center' }}>
                                                VIRIDDHAM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Account Number</td>
                                            <td style={{ textAlign: 'center' }}>
                                                4397002100224888
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>IFSC Code</td>
                                            <td style={{ textAlign: 'center' }}>
                                                PUNB0439700
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Bank Name</td>
                                            <td style={{ textAlign: 'center' }}>
                                                Punjab National Bank
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <Space >
                                    <label style={{ float: 'left' }}>Transaction ID</label>
                                    <Input style={{ float: 'left' }} name="transactionId" className="form-control" value={this.state.transactionId} onChange={e => this.onInputTransactionChange(e)} />
                                    <Button type="primary" onClick={() => this.onNeftTransactionClick()}>Submit</Button>
                                </Space >
                            </div>
                        </Space>
                    </Modal>}
                <Footer></Footer>
            </div>
        );
    }
}
const mapStateToProp = state => {
    return {
        userLoginStatus: state.userLoginStatus,
        userDetailsJson: state.userDetailsJson,
        paymentDetailsJson: state.paymentDetailsJson
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        UserDetails: () => dispatch(UserDetails()),
        LoginStatus: userLoginStatus => dispatch(LoginStatus(userLoginStatus)),
        PaymentDetails: () => dispatch(PaymentDetails()),
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(withRouter(Payment));



