import { createStore } from 'redux';
import ReduxReducer from './Redux.Reducer' 
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage: storage,
   };
   
   const pReducer = persistReducer(persistConfig, ReduxReducer);
   
   export const store = createStore(pReducer);
   export const persistor = persistStore(store);

