import React, { Component } from 'react';
import Header from '../DashBoard/DashBoard';
import Footer from '../Footer/Footer';
import '../MediaCss/viri.css';
import './OurTeam.css';
class OurTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0, paymentProviderdetails: [],
        }
        this.managementToFocus = React.createRef()
        this.consultantsToFocus = React.createRef()
        this.technologyToFocus = React.createRef()
        this.speducatorsToFocus = React.createRef()
    }
    handlemanagement = () => {
        if (this.managementToFocus.current) {
            this.managementToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    handleconsultant = () => {
        if (this.consultantsToFocus.current) {
            this.consultantsToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    handletechnology = () => {
        if (this.technologyToFocus.current) {
            this.technologyToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    handlespeducators = () => {
        if (this.speducatorsToFocus.current) {
            this.speducatorsToFocus.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    toggleText = (response, value) => {
        var referencename = value;

        var points = document.getElementById("points_" + referencename);

        var showMoreText = document.getElementById("moreText_" + referencename);

        var buttonText = document.getElementById("textButton_" + referencename);

        if (points.style.display === "none") {

            // Hide the text between the span
            // elements
            showMoreText.style.display = "none";

            // Show the dots after the text
            points.style.display = "inline";

            // Change the text on button to
            // 'Show More'
            buttonText.innerHTML = "Show More";
        }

        // If the hidden portion is revealed,
        // we will change it back to be hidden
        else {
            showMoreText.style.display = "inline";
            points.style.display = "none";
            buttonText.innerHTML = "Show Less";
        }
    }

    render() {
        return (
            <div>
                <Header></Header>
                <div style={{ paddingBottom: '15px' }}>
                    <div class="container">
                        <div class="servicesbg" id="services">
                            <div class="col-md-12">
                                <div class="row">
                                    <div>
                                        <h2 class="heading">Our Team</h2>
                                        <div class="col-md-3">
                                            <a onClick={this.handlemanagement}>
                                                <span style={{ textAlign: 'center' }}><img src={process.env.PUBLIC_URL + "/Images/assistment-sld.jpg"} class="img-responsive" /></span>
                                                <h4>Management</h4>
                                            </a>
                                        </div>
                                        <div class="col-md-3">
                                            <a onClick={this.handleconsultant}>
                                                <img src={process.env.PUBLIC_URL + "/Images/consulation.jpg"} class="img-responsive" />
                                                <h4>Panel of Consultants</h4>
                                            </a>
                                        </div>
                                        <div class="col-md-3">
                                            <a onClick={this.handletechnology}>
                                                <img src={process.env.PUBLIC_URL + "/Images/remedial.jpg"} class="img-responsive" />
                                                <h4>Technology</h4>
                                            </a>
                                        </div>

                                        <div class="col-md-3">
                                            <a onClick={this.handlespeducators}>
                                                <img src={process.env.PUBLIC_URL + "/Images/trainingprogramme.jpg"} class="img-responsive" />
                                                <h4>Special Educators</h4>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="div_management" class="container whowearebg" ref={this.managementToFocus}>
                        <div class="col-md-12">
                            <div style={{ marginTop: '4%' }}>
                                <h3 class="heading">Management</h3>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/Seetha_new.jpg"} class="img-responsive doctorsbluebg img-autostyle" />
                                    <h3 class="clrblue cronusfontTxt" style={{ whiteSpace: 'break-spaces' }}>Mr. SEETHARAMAN GANESAN</h3>
                                    <div class="mavinprofot">
                                        <strong>Overseas Head, Viriddham Micro Learning Services<span id="points_seetharaman">...</span></strong>
                                        <div id="moreText_seetharaman" class="moreText">
                                            <ul>
                                                <li>Innovative Technology Architect with over 25 years of experience in business application architecture, design and development with a proven record for achieving results.</li>
                                                <li>Developed corporate technology strategies and product roadmaps, delivered application, and product solutions to market, and provided technological leadership to business teams.</li>
                                                <li>Managed application development teams and deployment initiatives with both internal and external customers.</li>
                                                <li>Strong knowledge of software development process and methodology. </li>
                                                <li>Excellent technology, business process re-engineering, and IT management skills.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="readmorebrn pull-right mar-right-30" style={{ marginTop: '57px' }}>
                                        <button onClick={(e) => this.toggleText(e, "seetharaman")} id="textButton_seetharaman" dataRef="seetharaman" class="btnbg">
                                            Show More
                                        </button>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/Skandakumar_new.png"} class="img-response doctorspinkbg img-autostyle" />
                                    <h3 class="clrmaroon cronusfontTxt" style={{ whiteSpace: 'break-spaces' }}>Mr. SKANDAKUMAR GANESAN</h3>

                                    <div class="mavinprofot">
                                        <strong>CEO, Viriddham Micro Learning Services</strong>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div>
                                        <img src={process.env.PUBLIC_URL + "/Images/MemberImages/DrBSVphoto.png"} class="img-response doctorsgreenbg img-autostyle" />
                                        <h3 class="clrgreen cronusfontTxt ">Dr. B.S. VIRUDHAGIRINATHAN</h3>
                                        <p class="disc">
                                            Ph.D; D.Sc.(Neuropsychology), FIACP, MINPs.S(U.S.A), AFBPsS (UK), FMMC,
                                            CHARTERED PSYCHOLOGIST (U.K.)  <br />
                                            Senior Consultant Clinical / Neuropsychologist, Chennai
                                        </p>

                                        <div class="mavinprofot">
                                            <strong>Director, Viriddham Micro Learning Services<span id="points_virudhagirinathan">...</span></strong>
                                            <div id="moreText_virudhagirinathan" class="moreText">
                                                <ul>
                                                    <li>Formerly Head, Division  of Clinical / Neuropsychology, Institute of Neurology, Government General Hospital and Madras Medical College, Chennai.</li>
                                                    <li>Presently Director, Care Institute of Behavioural Sciences, Chennai.</li>
                                                    <li>Over 40 years of experience in Clinical research, teaching, assessment, and psychological management in head injury, epilepsy, stroke, parkinson's disease, dementia, headache, writer's cramps, etc. </li>
                                                    <li><strong>Special areas of interest</strong>- Children with developmental disorders, Specific Learning Disability, ADHD & ASD.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="readmorebrn pull-right mar-right-30">
                                        <button onClick={(e) => this.toggleText(e, "virudhagirinathan")} id="textButton_virudhagirinathan" data-ref="virudhagirinathan" class="btnbg">
                                            Show More
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style={{ margin: '20px 0' }}>
                                <div class="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/Photo_PrashantRamSK.jpg"} class="img-response doctorsbluebg img-autostyle" />
                                    <h3 class="clrblue cronusfontTxt ">Mr. PRASHANT RAM</h3>
                                    <div class="mavinprofot">
                                        <strong>Financial Advisor, Viriddham Micro Learning Services<span id="points_Prashant">...</span></strong>
                                        <div id="moreText_Prashant" class="moreText">
                                            <ul>
                                                <li>He is a Chartered Accountant with over 10 years of experience.</li>
                                                <li>He is a Partner in S.K.Murthy & Associates, a 35 year old firm based in Chennai.</li>
                                                <li>He was previously employed with Ernst Young, Chennai as part of the Transaction Advisory Services team.</li>
                                                <li>He specialises in working with startups from their inception and has successfully engaged with several startups at various stages of their growth and funding cycle.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="readmorebrn pull-right mar-right-30">
                                        <button onClick={(e) => this.toggleText(e, "Prashant")} id="textButton_Prashant" data-ref="Prashant" class="btnbg">
                                            Show More
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-4"></div>
                        </div>
                    </div>

                    <div class="container" id="div_panelConsultant" ref={this.consultantsToFocus}>
                        <div class="whoweare ">
                            <div class="col-md-12">
                                <div class="">
                                    <div class="row">
                                        <div>
                                            <h3 class="heading">Panel of Consultants</h3>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/premkumar_New.png"} class="img-responsive doctorsbluebg img-autostyle" />
                                                    <h3 class="clrblue">Dr. R. PREM SEKAR</h3>
                                                    <p class="disc">
                                                        MBBS, MRCPI (Paed.), FRCP (GLASG).,
                                                    </p>
                                                    <div class="mavinprofot">
                                                        <strong>Senior Interventional Paediatric Cardiologist<span id="points_PremSekar">...</span></strong>

                                                        <div id="moreText_PremSekar" class="moreText">
                                                            <ul>
                                                                <li>Senior Interventional  Paediatric  Cardiologist  with  an experience  of  25  years  in  the  field.</li>
                                                                <li>Trained  in  Paediatrics  and Paediatric Cardiology in the United Kingdom for a period of 8 years until Dec 1999.</li>
                                                                <p><strong>Special Interests:</strong></p>
                                                                <li>Therapeutic Interventional procedures in structural heart disease such as ASD, VSD, PDA device closures, Balloon and stent angioplasties for stenotic lesions.</li>
                                                                <li>Pulmonology hypertension in the young.</li>
                                                                <li>Serving the needy through "Healing little Hearts" project.</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="readmorebrn pull-right mar-right-30" style={{ marginTop: '28px' }}>
                                                        <button onClick={(e) => this.toggleText(e, "PremSekar")} id="textButton_PremSekar" data-ref="PremSekar" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">

                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/manoharan_new.png"} class="img-responsive doctorspinkbg img-autostyle" />
                                                    <h3 class="clrmaroon">Prof. Dr. G. MANOHARAN</h3>
                                                    <p class="disc">
                                                        MS, M.Ch., FAIS, FACS.
                                                    </p>
                                                    <div class="mavinprofot">
                                                        <strong>Senior Consultant Surgical Gastroenterologist<span id="points_Manoharan">...</span></strong>

                                                        <div id="moreText_Manoharan" class="moreText">
                                                            <ul>
                                                                <li>Senior Consultant Surgical Gastroenterologist.</li>
                                                                <li>Gastrointestinal Hepatobiliary Pancreatic Surgeon, G.I. Oncologist.</li>
                                                                <li><strong>Former Director</strong>, Institute of Surgical Gastroenterology, Govt. Stanley Medical College Chennai, Tamilnadu.</li>
                                                                <li>30 years  of Teaching Training MBBS MS MCH Students in State’s Premiere Institutions.</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="readmorebrn pull-right mar-right-30" style={{ marginTop: '28px' }}>
                                                        <button onClick={(e) => this.toggleText(e, "Manoharan")} id="textButton_Manoharan" data-ref="Manoharan" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">

                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/nataranan_new.png"} class="img-responsive doctorsgreenbg img-autostyle" />
                                                    <h3 class="clrgreen">Dr. V. NATARAJAN</h3>
                                                    <p class="disc">
                                                        MD, DM (Neurology)1982, FRCP, FAAN, FIAN,
                                                    </p>
                                                    <div class="mavinprofot">
                                                        <strong>
                                                            Senior Consultant Neurologist, Chennai <br />
                                                            Common Wealth Medical Fellow UK 1989-90.<span id="points_Natarajan">...</span>
                                                        </strong>

                                                        <div id="moreText_Natarajan" class="moreText">
                                                            <ul>
                                                                <li>Former Professor of Neurology, Madras Medical College and Head, Institute of Neurology, Government General Hospital.</li>
                                                                <li>Presently Professor Emeritus Tamilnadu Dr.MGR Medical University, Chennai.</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="readmorebrn pull-right mar-right-30">
                                                        <button onClick={(e) => this.toggleText(e, "Natarajan")} id="textButton_Natarajan" data-ref="Natarajan" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mar-20">
                                                <div class="col-md-4">
                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/DrBSVphoto.png"} class="img-response doctorsbluebg img-autostyle" />
                                                    <h3 class="clrblue cronusfontTxt ">Dr. B.S. VIRUDHAGIRINATHAN</h3>
                                                    <p class="disc">
                                                        Ph.D; D.Sc.(Neuropsychology), FIACP, MINPs.S(U.S.A), AFBPsS (UK), FMMC,
                                                        CHARTERED PSYCHOLOGIST (U.K.)
                                                    </p>
                                                    <div class="mavinprofot">
                                                        <strong>Senior Consultant Clinical / Neuropsychologist, Chennai<span id="points_virudhanathan">...</span></strong>

                                                        <div id="moreText_virudhanathan" class="moreText">
                                                            <ul>
                                                                <li>Formerly Head, Division  of Clinical / Neuropsychology, Institute of Neurology, Government General Hospital and Madras Medical College, Chennai.</li>
                                                                <li>Presently Director, Care Institute of Behavioural Sciences, Chennai.</li>
                                                                <li>Over 40 years of experience in Clinical research, teaching, assessment, and psychological management in head injury, epilepsy, stroke, parkinson's disease, dementia, headache, writer's cramps, etc. </li>
                                                                <li><strong>Special areas of interest</strong>- Children with developmental disorders, Specific Learning Disability, ADHD & ASD.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="readmorebrn pull-right mar-right-30">
                                                        <button onClick={(e) => this.toggleText(e, "virudhanathan")} id="textButton_virudhanathan" data-ref="virudhanathan" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/suresh_new.png"} class="img-responsive doctorspinkbg img-autostyle" />
                                                    <h3 class="clrmaroon cronusfontTxt ">Prof. V. SURESH</h3>
                                                    <p class="disc">
                                                        MA, M.Phil., Ph.D.,  MBPsS (UK), MINPs.S (USA),  <br />
                                                    </p>
                                                    <div class="mavinprofot">
                                                        <strong>Counselling Psychologist<span id="points_Suresh">...</span></strong>

                                                        <div id="moreText_Suresh" class="moreText">
                                                            <ul>
                                                                <li>Former Professor & Head, Department of Psychology, Annamalai University, Former Director, Centre for Yoga Studies, Annamalai University. </li>
                                                                <li>Presently Course Director, Care Institute of Behavioural Sciences, Chennai. </li>
                                                                <li>Has more than three decades of teaching and research experience.</li>
                                                                <li>His areas of interest include Social psychology, Counselling Psychology, Yoga Psychology. </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="readmorebrn pull-right mar-right-30" style={{ marginTop: '70px' }}>
                                                        <button onClick={(e) => this.toggleText(e, "Suresh")} id="textButton_Suresh" data-ref="Suresh" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/andal_new.png"} class="img-responsive doctorsgreenbg img-autostyle" />
                                                    <h3 class="clrgreen">Dr. A. ANDAL</h3>
                                                    <p class="disc">
                                                        M.D. Paediatrics, MBA (Hospital Management)<br />
                                                    </p>
                                                    <div class="mavinprofot">
                                                        <strong>Senior Consultant Paediatrician<span id="points_Andal">...</span></strong>

                                                        <div id="moreText_Andal" class="moreText">
                                                            <ul>
                                                                <li> Senior Paediatrician, and a Former Director - Kanchi Kamakoti Child's Trust Hospital, Nungambakkam, Chennai.</li>
                                                                <li> Presently Visiting Consultant at Apollo Hospitals and KKCTH and Head, Child Guidance Clinic at  KKCTH for the past 20 years. </li>
                                                                <li> Areas of special interest are, addressing children with ADHD and Behavioral issues.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="readmorebrn pull-right mar-right-30" style={{ marginTop: '70px' }}>
                                                        <button onClick={(e) => this.toggleText(e, "Andal")} id="textButton_Andal" data-ref="Andal" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mar-20">
                                                <div class="col-md-4">
                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/padmaja_new.png"} class="img-responsive doctorsbluebg img-autostyle" />
                                                    <h3 class="clrblue">Mrs. B. PADMAJA</h3>
                                                    <p class="disc">M.Sc.Speech Language Pathology and Audiology from AIISH, Mysore</p>
                                                    <div class="mavinprofot">
                                                        <strong>Senior Consultant Speech Language Pathologist & Audiologist<span id="points_Padmaja">...</span></strong>

                                                        <div id="moreText_Padmaja" class="moreText">
                                                            <ul>
                                                                <li>Consultant Speech pathologist and Audiologist at Vijaya group of hospitals since 1988.</li>
                                                                <li>She had hands on training in the rehabilitation of pre and post operative cochlear implantees under the guidance of Dr.Burien at Vienna, Austria.</li>
                                                                <li><strong>Special areas of interest :</strong> Children with delayed speech and language, ADHD & ASD.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="readmorebrn pull-right mar-right-30">
                                                        <button onClick={(e) => this.toggleText(e, "Padmaja")} id="textButton_Padmaja" data-ref="Padmaja" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/SubashChandraBose.jpeg"} class="img-response doctorspinkbg img-autostyle" />
                                                    <h3 class="clrmaroon">Dr. P. SUBASH CHANDRA BOSE</h3>
                                                    <p class="disc">MD (Paediatrics)</p>
                                                    <div class="mavinprofot">
                                                        <strong>Senior Consultant Paediatrician <span id="points_SubashChandraBose">...</span></strong>
                                                        <div id="moreText_SubashChandraBose" class="moreText">
                                                            <ul>
                                                                <li>15 years of experience in Paediatric field. Senior Consultant Paediatrician in SIMS Hospital, Chennai.  </li>
                                                                <li>Worked in Paediatric Emergency Department, Kanchi Kamakoti CHILDS Trust Hospital, Chennai. </li>
                                                                <li>Senior Registrar in Pediatrics Department, Sooriya Hospital, Chennai. </li>
                                                                <li>Instructor for PALS(Paediatric Advanced Life Support) course held by Indian Academy of Pediatrics. </li>
                                                                <li>Developmental Paediatrician and Faculty for courses in Behavioural and learning disorders in children conducted by Care Institute of  Behavioural Sciences, Chennai.</li>
                                                                <li>Developmental Paediatrician for the Teacher counselling programme conducted by the State Council of Educational Research and Training (SCERT) of Tamilnadu Government.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="readmorebrn pull-right mar-right-30" style={{ marginTop: '49px' }}>
                                                        <button onClick={(e) => this.toggleText(e, "SubashChandraBose")} id="textButton_SubashChandraBose" data-ref="SubashChandraBose" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/amira_new.png"} class="img-responsive doctorsgreenbg img-autostyle" />
                                                    <h3 class="clrgreen cronusfontTxt ">Dr. AMIRA PARVEEN</h3>
                                                    <p class="disc">M.Sc.,(Applied Psychology),Ph.D.,(Neuropsychology),MBPsS (UK), MINPs.S (USA),</p>
                                                    <div class="mavinprofot">
                                                        <strong>Consultant Clinical Psychologist & Special Educator in Learning Disability<span id="points_Amira">...</span></strong>

                                                        <div id="moreText_Amira" class="moreText">
                                                            <ul>
                                                                <li>Consultant Psychologist at the Psycho Diagnostic Clinic, Chennai. </li>
                                                                <li>Faculty in Psychology / Special Education at the Care Institute of Behavioural Sciences, Chennai. </li>
                                                                <li>Involved in teaching, training and research in Psychology/Clinical Neuropsychology. </li>
                                                                <li>Areas of interest are Developmental disorders – Attention Deficit Hyperactivity Disorder, Autism Spectrum Disorder, and Specific Learning Disorder.  </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="readmorebrn pull-right mar-right-30">
                                                        <button onClick={(e) => this.toggleText(e, "Amira")} id="textButton_Amira" data-ref="Amira" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mar-20">
                                                <div class="col-md-4">
                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/sujatha_new.png"} class="img-responsive doctorsbluebg img-autostyle" />
                                                    <h3 class="clrblue">Dr. SUJATHA JAGADEESH</h3>
                                                    <p class="disc">DCH, DNB (Paed)</p>
                                                    <div class="mavinprofot">
                                                        <strong>Head, Department of Clinical Genetics, Mediscan, Chennai.<span id="points_Sujatha">...</span></strong>

                                                        <div id="moreText_Sujatha" class="moreText">
                                                            <ul>
                                                                <li>Over 22 years of experience in teaching and training fetal medicine students.</li>
                                                                <p><strong>Special Interests:</strong></p>
                                                                <li>Dysmorphology and IEM.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="readmorebrn pull-right mar-right-30">
                                                        <button onClick={(e) => this.toggleText(e, "Sujatha")} id="textButton_Sujatha" data-ref="Sujatha" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>


                                                <div class="col-md-4">
                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/DrRavikumar.JPG"} class="img-response doctorspinkbg img-autostyle" />
                                                    <h3 class="clrmaroon">Dr. T. RAVIKUMAR</h3>
                                                    <p class="disc">MBBBS. DNB(Ped). Ph.D.(Behavioral Ped). PGDip.(Adoloescent Ped).</p>
                                                    <div class="mavinprofot">
                                                        <strong>Senior Consultant Paediatrician, Chennai<span id="points_Ravi">...</span></strong>

                                                        <div id="moreText_Ravi" class="moreText">
                                                            <ul>
                                                                <li>Specialist in Child Behaviour, Development & Learning disorders.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="readmorebrn pull-right mar-right-30" style={{ marginTop: '7px' }}>
                                                        <button onClick={(e) => this.toggleText(e, "Ravi")} id="textButton_Ravi" data-ref="Ravi" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/DrAdam_new.jpeg"} class="img-response doctorsgreenbg img-autostyle" />
                                                    <h3 class="clrgreen">Mr. S. ADAM VINNARASAN</h3>
                                                    <p class="disc">B.Sc. Physics, BPT</p>
                                                    <div class="mavinprofot">
                                                        <strong>Senior Physical Therapist<span id="points_Adam">...</span></strong>

                                                        <div id="moreText_Adam" class="moreText">
                                                            <ul>
                                                                <li>Presently working as Senior Physical therapist in Rajiv Gandhi Government General Hospital, Chennai .</li>
                                                                <li>He has 30 years of experience with a variety of patients with orthopaedic and neurological problems to restore functions physically after diseases and injuries.</li>
                                                                <li>Paediatric physical therapy for children with developmental delay and motor disorders (gross and fine motor).</li>
                                                                <li>Especially enjoy working with the geriatric population in recent years.  </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="readmorebrn pull-right mar-right-30" style={{ marginTop: '28px' }}>
                                                        <button onClick={(e) => this.toggleText(e, "Adam")} id="textButton_Adam" data-ref="Adam" class="btnbg">
                                                            Show More
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="container whowearebg" id="div_technology" ref={this.technologyToFocus}>
                        <div class="col-md-12">
                            <div style={{ marginTop: '4%' }}>
                                <h3 class="heading">Technology</h3>
                                <span id="points" class="pull-right"></span>
                            </div>
                            <div class="row" style={{ marginTop: '4%' }}>
                                <div class="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/Gokul_new.JPG"} class="img-responsive doctorsbluebg img-autostyle" />
                                    <h3 class="clrblue cronusfontTxt ">Mr. S. GOKULAKRISHNAN</h3>
                                </div>
                                <div class="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/Dhinakaran.jpg"} class="img-response doctorspinkbg img-autostyle" />
                                    <h3 class="clrmaroon cronusfontTxt ">Mr. M. DHINAKARAN</h3>
                                </div>
                                <div class="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/noimg_new.jpg"} class="img-responsive doctorsgreenbg img-autostyle" />
                                    <h3 class="clrgreen cronusfontTxt ">Ms. HAMSA KRISHNAN</h3>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '4%' }}>

                                <div class="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/Venkatesh.jpg"} class="img-response doctorsbluebg img-autostyle" />
                                    <h3 class="clrblue cronusfontTxt ">Mr. C.K. VENKATESH</h3>
                                </div>

                                <div class="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/Yamuna.jpg"} class="img-response doctorspinkbg img-autostyle" />
                                    <h3 class="clrmaroon cronusfontTxt ">Ms. K. YAMUNA</h3>
                                </div>
                                <div class="col-md-4">
                                    <img src={process.env.PUBLIC_URL + "/Images/MemberImages/noimg_new.jpg"} class="img-responsive doctorsgreenbg img-autostyle" />
                                    <h3 class="clrgreen cronusfontTxt ">Mr. M. VENKATESHWARAN</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container" id="div_specialEducator" ref={this.speducatorsToFocus}>
                        <div class="whoweare ">
                            <div class="col-md-12">
                                <div class="">
                                    <div class="row">
                                        <div>
                                            <h3 class="heading">Special Educators</h3>

                                            <div class="col-md-4">
                                                <img src={process.env.PUBLIC_URL + "/Images/MemberImages/Sylviaphoto.jpg"} class="img-response doctorsbluebg img-autostyle" />
                                                <h3 class="clrblue">Mrs. NEOLINE SYLVIA</h3>
                                                <p class="disc">B.Sc., MA.,</p>
                                                <div class="mavinprofot">
                                                    <strong>
                                                        Senior Special Educator in LD <br />
                                                        HELP CHILD Learning Center, a unit of HELP CHILD Charitable Trust, Chennai<span id="points_NeolineSylvia">...</span>
                                                    </strong>

                                                    <div id="moreText_NeolineSylvia" class="moreText">
                                                        <p>She has 20 years of experience in Assessment and Remedial Education for children with Specific Learning Difficulty and co-morbid disorders Attention Deficit Hyperactivity Disorder (ADHD) and Autism Spectrum Disorder (ASD). Besides, she offers Counselling for parents/ care givers of these children. She is part of a team in screening of SLD in School Children in different parts of Tamil Nadu, Puducherry and Karnataka. She is a resource person for imparting IEP training for the Certificate course in SLD programme for teachers organised by Care Institute of Behavioural Sciences in Tamil Nadu and Karnataka.  </p>
                                                    </div>
                                                </div>
                                                <div class="readmorebrn pull-right mar-right-30">
                                                    <button onClick={(e) => this.toggleText(e, "NeolineSylvia")} id="textButton_NeolineSylvia" data-ref="NeolineSylvia" class="btnbg">
                                                        Show More
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <img src={process.env.PUBLIC_URL + "/Images/MemberImages/Indiraphoto.jpg"} class="img-response doctorspinkbg img-autostyle" />
                                                <h3 class="clrmaroon">Mrs. J. INDIRA</h3>
                                                <p class="disc">M.Sc., (Psych) </p>
                                                <div class="mavinprofot">
                                                    <strong>
                                                        Senior Special Educator in LD <br />
                                                        HELP CHILD Learning Center, a unit of HELP CHILD Charitable Trust, Chennai<span id="points_Indira">...</span>
                                                    </strong>
                                                    <div id="moreText_Indira" class="moreText">
                                                        <p>She has 15 years of experience in Assessment and Remedial Education for children with Specific Learning Difficulty and co-morbid disorders Attention Deficit Hyperactivity Disorder (ADHD) and Autism Spectrum Disorder (ASD). Besides, she offers Counselling for parents/ care givers of these children. She is part of a team in screening of SLD in School Children in different parts of Tamil Nadu, Puducherry and Karnataka. She is a resource person for imparting IEP training for the Certificate course in SLD programme for teachers organised by Care Institute of Behavioural Sciences in Tamil Nadu and Karnataka.  </p>
                                                    </div>
                                                </div>
                                                <div class="readmorebrn pull-right mar-right-30">
                                                    <button onClick={(e) => this.toggleText(e, "Indira")} id="textButton_Indira" data-ref="Indira" class="btnbg">
                                                        Show More
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default OurTeam;