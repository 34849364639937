import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Space, Button, Col, Row, Modal, Drawer, Table, Divider, Radio, Select, Spin, Form, Input, Upload } from 'antd';
import { connect } from 'react-redux';
import { UserDetails, ChildIdData, LoginStatus } from '../../Redux/Redux.Action';
import { GetChildProfile, SaveChildprofile, SaveChildProfileDocuments, GetChildProfileDocuments } from './CaseHistoryService';
import moment from 'moment';
import { UploadOutlined, SettingOutlined } from '@ant-design/icons';
import Footer from '../Footer/Footer';
import Header from '../DashBoard/DashBoard';
import '../MediaCss/viri.css';
import '../MediaCss/bootstrap.min.css';
import TextArea from 'antd/lib/input/TextArea';
import './CaseHistory.css'

const { Option } = Select;

class CaseHistory extends Component {
    constructor() {
        super()
        this.state = {
            ChildDetails: {}, childId: '', disabledbox: false, showViewtheraphy: false, togglegeneratereport: false, selectedFields: [],
            showUploadtheraphy: false, loading: false, uploadedFiles: [], userName: '', childDocViewDetails: []
        }
    }
    componentDidMount = async () => {
        if (this.props.userLoginStatus === true) {
            var childId = sessionStorage.getItem('ChildD')
            var firstName = sessionStorage.getItem('ChildFirstName')
            var lastName = sessionStorage.getItem('ChildLastName')
            var dob = sessionStorage.getItem('ChildDOB')
            var childMRD = sessionStorage.getItem('ChildMRD')
            var profile = await GetChildProfile(childId)
            var schoolName = sessionStorage.getItem('SchoolName')
            var schoolId = sessionStorage.getItem('SchoolId')
            var username = sessionStorage.getItem('userName')
            if ((schoolId !== "") && (schoolName !== "") && (schoolId !== "2")) {
                profile.profileDetails.schoolName = schoolName;
                profile.profileDetails.schoolGoing = "yes";
                this.setState({ disabledbox: true })
            }
            else {
                this.setState({ disabledbox: false })
            }
            profile.profileDetails.childName = firstName + " " + lastName;
            profile.profileDetails.dob = dob;
            profile.profileDetails.mrd = childMRD;
            if (profile.profileDetails.profileDate === null) {
                var today = new Date();
                profile.profileDetails.profileDate = moment(today).format('YYYY-MM-DD');
            }
            else {
                profile.profileDetails.profileDate = moment(profile.profileDetails.profileDate).format('YYYY-MM-DD');
            }
            var today = new Date();
            var birthDate = new Date(dob);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            profile.profileDetails.age = age;
            this.setState({
                ChildDetails: profile.profileDetails, childId: childId, userName: username
            })
        }
        else {
            this.props.history.push('./Login');
        }
    }
    onInputChange = (e) => {
        var Child = this.state.ChildDetails;
        if (e.target.name === "birthOrder" || e.target.name === "siblings" || e.target.name === "headControlAge" || e.target.name === "sittingAge"
            || e.target.name === "walkingAge" || e.target.name === "speechDelayed") {
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                Child[e.target.name] = e.target.value;
            }
        }
        else if (e.target.name === "birthWeight") {
            const reg = /^[0-9\.\b]+$/;
            if (e.target.value === '' || reg.test(e.target.value)) {
                Child[e.target.name] = e.target.value;
            }
        }
        else if (e.target.name === "childAttendingOthers") {
            if (e.target.value === "Yes") {
                Child[e.target.name] = true;
            }
            else {
                Child[e.target.name] = false;
            }
        }
        else {
            Child[e.target.name] = e.target.value;
        }
        this.setState({ ChildDetails: Child })
    }

    onSelectChange = (e, name) => {
        var Child = this.state.ChildDetails;
        if (name === "profileDate") {
            Child[name] = moment(e._d).format('YYYY/MM/DD');
        }
        if (name === "birthCry") {
            Child[name] = e.target.value;
        }
        else {
            if (name === "childAttending") {
                if (e !== "Others") {
                    Child.childAttendingOthers = false
                }
            }
            Child[name] = e;
        }
        this.setState({ ChildDetails: Child })
    }

    onSaveCSR = async () => {
        var userName = sessionStorage.getItem('userName')
        let ChildDetails = this.state.ChildDetails;
        if (ChildDetails['birthWeight'] === "" || ChildDetails['birthWeight'] === "undefined") {
            ChildDetails['birthWeight'] = parseFloat("0");
        }
        else {
            ChildDetails['birthWeight'] = parseFloat(ChildDetails['birthWeight']);
        }
        if (ChildDetails['birthOrder'] === "" || ChildDetails['birthOrder'] === "undefined") {
            ChildDetails['birthOrder'] = parseFloat("0");
        }
        else {
            ChildDetails['birthOrder'] = parseInt(ChildDetails['birthOrder']);
        }
        if (ChildDetails['siblings'] === "" || ChildDetails['siblings'] === "undefined") {
            ChildDetails['siblings'] = parseInt("0");
        }
        else {
            ChildDetails['siblings'] = parseInt(ChildDetails['siblings']);
        }
        if (ChildDetails['headControlAge'] === "" || ChildDetails['headControlAge'] === "undefined") {
            ChildDetails['headControlAge'] = parseFloat("0");
        }
        else {
            ChildDetails['headControlAge'] = parseInt(ChildDetails['headControlAge']);
        }
        if (ChildDetails['sittingAge'] === "" || ChildDetails['sittingAge'] === "undefined") {
            ChildDetails['sittingAge'] = parseFloat("0");
        }
        else {
            ChildDetails['sittingAge'] = parseInt(ChildDetails['sittingAge']);
        }
        if (ChildDetails['walkingAge'] === "" || ChildDetails['walkingAge'] === "undefined") {
            ChildDetails['walkingAge'] = parseFloat("0");
        }
        else {
            ChildDetails['walkingAge'] = parseInt(ChildDetails['walkingAge']);
        }
        if (ChildDetails['speechDelayed'] === "" || ChildDetails['speechDelayed'] === "undefined") {
            ChildDetails['speechDelayed'] = parseFloat("0");
        }
        else {
            ChildDetails['speechDelayed'] = parseInt(ChildDetails['speechDelayed']);
        }
        var childsave = {
            ChildIdToken: this.state.childId,
            ProfileDetails: ChildDetails,
            ChangedBy: userName
        }
        var profileResponse = await SaveChildprofile(childsave);
        if (profileResponse.businessRules.length === 0) {
            this.props.history.push('./UserDashboard')
        }
        else {
            Modal.error({
                title: 'Error Message',
                content: profileResponse.businessRules[0].description
            })
        }
    }
    onProfileBackClick = () => {
        this.props.history.push('./UserDashboard')
    }
    dummyRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    theraphychange = async (e) => {
        var chkvalue = e.target.value;
        var Child = this.state.ChildDetails;
        if (chkvalue === "AddDetails") {
            this.setState({ showViewtheraphy: false, showUploadtheraphy: true })
        }
        else {
            this.setState({ showViewtheraphy: true, showUploadtheraphy: false })
            var childId = this.state.childId;
            var uploadDocRes = await GetChildProfileDocuments(childId)
            this.setState({ childDocViewDetails: uploadDocRes.documents })
        }
        Child["theraphydetails"] = e.target.value;
        this.setState({ ChildDetails: Child })
    }
    onCancel = () => {
        document.getElementById("addDetails").checked=false
        document.getElementById("viewDetails").checked=false
        this.setState({ showViewtheraphy: false, showUploadtheraphy: false })
    }
    onSaveUploadAnswer = async () => {
        this.setState({
            loading: true
        })
        var uploadeddata = this.state.uploadedFiles;
        var childID = this.state.childId;
        var userName = this.state.userName;
        var encodedData = uploadeddata[0].EncodedDocument.replace(/^data:.+;base64,/, '');
        var uploadRequestData = []
        var uploadRequest = {
            EncodedDocument: encodedData,
            ChildInfoToken: childID,
            DocumentName: uploadeddata[0].DocumentName,
            DocumentType: uploadeddata[0].DocumentType,
            ChangedBy: userName,
            DocumentTypeId: parseInt("0"),
        }
        uploadRequestData.push(uploadRequest)
        var uploadDocRes = await SaveChildProfileDocuments(uploadRequestData)
        if (uploadDocRes.businessRules.length === 0) {
            this.setState({ showUploadtheraphy: false, showViewtheraphy: false, loading: false })
            Modal.success({
                content: 'File Uploaded Successfully!',
            });
        }
        else {
            Modal.error({
                title: 'Error Message',
                content: uploadDocRes.businessRules[0].description
            })
        }
        document.getElementById("addDetails").checked=false
        document.getElementById("viewDetails").checked=false
    }
    uploadtheraphyChange = (info) => {
        var file = info.file;
        var documenttype = "";
        let uploadedFiles = this.state.uploadedFiles;
        uploadedFiles = [];
        if (info.file.status === 'done') {
            let reader = new FileReader();
            reader.onload = (e) => {
                const text = (e.target.result);
                if (file.type === "application/pdf") {
                    documenttype = "PDF"
                }
                else if (file.type === "text/plain") {
                    documenttype = "TXT"
                }
                else {
                    documenttype = "JPG"
                }
                uploadedFiles.push({
                    DocumentName: file.name,
                    DocumentType: documenttype,
                    dataContent: file,
                    EncodedDocument: text,
                })
                this.setState({ uploadedFiles });
            }
            reader.readAsDataURL(file.originFileObj);

        }
        else if (info.file.status === 'removed') {
            this.setState({ uploadedFiles })
        }
    }
    onSelectDocFileChange(selectedRowKeys, selectedRow) {
        if (selectedRowKeys.length > 0) {
            this.setState({ togglegeneratereport: false })
        }
        else {
            this.setState({ togglegeneratereport: true })
        }
        this.setState({ selectedDataFields: selectedRowKeys, selectedFields: selectedRow })
    };
    render() {
        const { selectedRowKeys } = this.state;
        const layout = { labelCol: { flex: '300px' }, wrapperCol: { flex: 1 } }
        const format = 'YYYY/MM/DD';
        const drawerformlayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 14 },
        };
        const fieldSelect = {
            selectedRowKeys,
            onChange: this.onSelectDocFileChange,
        };
        const docViewDetailsColumn = [
            {
                title: 'Document Name',
                dataIndex: 'name', width: '440px',
            },
            {
                title: 'Download File',
                dataIndex: 'url', width: '440px', align: 'center',
                render: (text, record) =>
                this.state.childDocViewDetails.length >= 1 ? (
                  <Space size="middle">
                    <a download={record.url} target="_blank" href={record.url}><i className="fa fa-download"></i></a>
                  </Space>) : null,
            }
        ];
        return (
            <div >
                {/* {!this.state.BackClick && */}
                {/* <Header></Header> */}
                <div style={{ height: 'calc(100vh - 1px)', overflowY: 'auto', overflowX: 'hidden' }}>
                    <div>
                        <Row gutter={24} style={{ marginTop: 10 }}>
                            <Col span={22}>
                                <h2>INSTRUCTION: Please read the following and fill up all the details, do not leave anything blank.</h2>
                            </Col>
                            <Col span={2}>
                                <Button type='primary' onClick={() => this.onProfileBackClick()}> Back</Button>
                            </Col>
                        </Row>
                        <Form {...layout} labelWrap style={{ width: '100%', marginLeft: '2%' }} labelAlign="left" id="frmprofile" className="card-footer-right" scrollToFirstError={true} initialValues={{ remember: true }} onFinish={this.onSaveCSR}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item name="childName" label="Child Name" initialValue={this.state.ChildDetails.childName}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="childName" disabled value={this.state.ChildDetails.childName} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item name="mrd" label="MRD" initialValue={this.state.ChildDetails.mrd}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="mrd" disabled onChange={this.onInputChange} value={this.state.ChildDetails.mrd} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item name="profileDate" label="Profile Date" initialValue={this.state.ChildDetails.profileDate}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="profileDate" disabled value={this.state.ChildDetails.profileDate} />
                                                {/* <DatePicker name="profileDate" disabled style={{ width: '100%' }} value={this.state.ChildDetails.profileDate}/> */}
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item name="dob" label="DOB" initialValue={this.state.ChildDetails.dob}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="dob" disabled value={this.state.ChildDetails.dob} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item name="age" label="Age" initialValue={this.state.ChildDetails.age}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="age" disabled value={this.state.ChildDetails.age} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item name="schoolGoing" label="If going to School, give details" initialValue={this.state.ChildDetails.schoolGoing}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.schoolGoing} disabled={this.state.disabledbox} onChange={(e) => this.onSelectChange(e, 'schoolGoing')} defaultValue={this.state.ChildDetails.schoolGoing}>
                                                    <Option value='yes'>Yes</Option>
                                                    <Option value='no'>No</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {this.state.ChildDetails.schoolGoing === 'yes' ?
                                        <Form.Item name="classSection" label="Class Section" initialValue={this.state.ChildDetails.classSection} rules={[{ required: true, message: 'Please enter Class!' }]}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name="classSection" onChange={this.onInputChange} value={this.state.ChildDetails.classSection} />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        : null}
                                    {this.state.ChildDetails.schoolGoing === 'yes' ?
                                        <Form.Item name="schoolName" label="School Name" initialValue={this.state.ChildDetails.schoolName} rules={[{ required: true, message: 'Please enter School name!' }]}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name="schoolName" onChange={this.onInputChange} disabled={this.state.disabledbox} value={this.state.ChildDetails.schoolName} />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        : null}
                                </Col>
                            </Row>
                            <Divider></Divider>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item label="Any change of Schools, please give details">
                                    </Form.Item>
                                    <Form.Item name="medium" label="Medium of Instruction" initialValue={this.state.ChildDetails.medium}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="medium" onChange={this.onInputChange} value={this.state.ChildDetails.medium} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item name="birthOrder" label="Birth Order" initialValue={this.state.ChildDetails.birthOrder}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="birthOrder" onChange={this.onInputChange} defaultValue={this.state.ChildDetails.birthOrder} value={this.state.ChildDetails.birthOrder} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item name="miscarriage" initialValue={this.state.ChildDetails.miscarriage} label="If any miscarriage/abortion, give details">
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="miscarriage" onChange={this.onInputChange} value={this.state.ChildDetails.miscarriage} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item name="siblings" label="Siblings" initialValue={this.state.ChildDetails.siblings}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="siblings" onChange={this.onInputChange} value={this.state.ChildDetails.siblings} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item label="Occupation">
                                    </Form.Item>
                                    <Form.Item name="occupFather" label="Father" initialValue={this.state.ChildDetails.occupFather}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="occupFather" onChange={this.onInputChange} value={this.state.ChildDetails.occupFather} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item name="occupMother" label="Mother" initialValue={this.state.ChildDetails.occupMother}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="occupMother" onChange={this.onInputChange} value={this.state.ChildDetails.occupMother} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item name="careGiver" label="Primary Care Giver" initialValue={this.state.ChildDetails.careGiver}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.careGiver} onChange={(e) => this.onSelectChange(e, 'careGiver')} defaultValue={this.state.ChildDetails.careGiver}>
                                                    <Option value='Father'>Father</Option>
                                                    <Option value='Mother'>Mother</Option>
                                                    <Option value='GrandFather'>Grand Father</Option>
                                                    <Option value='GrandMother'>Grand Mother</Option>
                                                    <Option value='Others'>Others Specify</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {this.state.ChildDetails.careGiver === "Others" ?
                                        <Form.Item name="careGiverOthers" label="Other" initialValue={this.state.ChildDetails.careGiverOthers}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name="careGiverOthers" onChange={this.onInputChange} value={this.state.ChildDetails.careGiverOthers} />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        : null}
                                    <Form.Item name="maritalStatus" label="Father/Mother" initialValue={this.state.ChildDetails.maritalStatus}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.maritalStatus} onChange={(e) => this.onSelectChange(e, 'maritalStatus')} defaultValue={this.state.ChildDetails.maritalStatus}>
                                                    <Option value='Married'>Married</Option>
                                                    <Option value='Separated'>Separated</Option>
                                                    <Option value='Divorced'>Divorced</Option>
                                                    <Option value='Others'>Others Specify</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {this.state.ChildDetails.maritalStatus === "Others" ?
                                        <Form.Item name="maritalStatusOthers" label="Other" initialValue={this.state.ChildDetails.maritalStatusOthers}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name="maritalStatusOthers" onChange={this.onInputChange} value={this.state.ChildDetails.maritalStatusOthers} />
                                                </Col>
                                            </Row>
                                        </Form.Item> : null}
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item label="Birth History">
                                    </Form.Item>
                                    <Form.Item name="birthNature" label="Nature Of Birth" initialValue={this.state.ChildDetails.birthNature}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="birthNature" onChange={this.onInputChange} value={this.state.ChildDetails.birthNature} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item name="adoptedDetails" label="Adopted Child" initialValue={this.state.ChildDetailsadoptedDetails}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <TextArea name="adoptedDetails" onChange={this.onInputChange} value={this.state.ChildDetailsadoptedDetails} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item name="birthWeight" label="Birth Weight" initialValue={this.state.ChildDetails.birthWeight}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name="birthWeight" onChange={this.onInputChange} defaultValue={this.state.ChildDetails.birthWeight} value={this.state.ChildDetails.birthWeight} addonAfter="Kg" />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item name="birthCry" label="Birth Cry" initialValue={this.state.ChildDetails.birthCry}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Radio.Group style={{ paddingRight: '0px !important' }} onChange={(e) => this.onSelectChange(e, 'birthCry')} value={this.state.ChildDetails.birthCry}>
                                                    <Radio value='Present'>Present </Radio>
                                                    <Radio value='Absent'>Absent </Radio>
                                                    <Radio value='Induced'>Induced</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item label="Major Milestones (Give details, in months)">
                                    </Form.Item>
                                    <Form.Item label="Head Control" initialValue={this.state.ChildDetails.headControl}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.headControl} onChange={(e) => this.onSelectChange(e, 'headControl')} defaultValue={this.state.ChildDetails.headControl}>
                                                    <Option value='Normal'>Normal</Option>
                                                    <Option value='Delayed'>Delayed</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {this.state.ChildDetails.headControl !== '' ?
                                        <Form.Item label="Head Control Age" initialValue={this.state.ChildDetails.headControlAge} rules={[{ required: true, message: 'Please enter Head Control Age!' }]}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name='headControlAge' onChange={this.onInputChange} value={this.state.ChildDetails.headControlAge} addonAfter="Months"></Input>
                                                </Col>
                                            </Row>
                                        </Form.Item> : null}
                                    <Form.Item label="Sitting" initialValue={this.state.ChildDetails.sitting}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.sitting} onChange={(e) => this.onSelectChange(e, 'sitting')} defaultValue={this.state.ChildDetails.sitting}>
                                                    <Option value='Normal'>Normal</Option>
                                                    <Option value='Delayed'>Delayed</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {this.state.ChildDetails.sitting !== '' ?
                                        <Form.Item label="Sitting Age" initialValue={this.state.ChildDetails.sittingAge} rules={[{ required: true, message: 'Please enter Sitting Age!' }]}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name='sittingAge' onChange={this.onInputChange} value={this.state.ChildDetails.sittingAge} addonAfter="Months"></Input>
                                                </Col>
                                            </Row>
                                        </Form.Item> : null}
                                    <Form.Item label="Walking" initialValue={this.state.ChildDetails.walking}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.walking} onChange={(e) => this.onSelectChange(e, 'walking')} defaultValue={this.state.ChildDetails.walking}>
                                                    <Option value='Normal'>Normal</Option>
                                                    <Option value='Delayed'>Delayed</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {this.state.ChildDetails.walking !== '' ?
                                        <Form.Item label="Walking Age" initialValue={this.state.ChildDetails.walkingAge} rules={[{ required: true, message: 'Please enter Walking Age!' }]}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name='walkingAge' onChange={this.onInputChange} value={this.state.ChildDetails.walkingAge} addonAfter="Months"></Input>
                                                </Col>
                                            </Row>
                                        </Form.Item> : null}
                                    <Form.Item label="Speech" initialValue={this.state.ChildDetails.speech}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.speech} onChange={(e) => this.onSelectChange(e, 'speech')} defaultValue={this.state.ChildDetails.speech}>
                                                    <Option value='Normal'>Normal (around 1 Year)</Option>
                                                    <Option value='Delayed'>If delayed,Specify</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {this.state.ChildDetails.speech === 'Delayed' ?
                                        <Form.Item label="Speech Delayed" initialValue={this.state.ChildDetails.speechDelayed} rules={[{ required: true, message: 'Please enter Speech Delayed!' }]}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name='speechDelayed' onChange={this.onInputChange} value={this.state.ChildDetails.speechDelayed} addonAfter="Months"></Input>
                                                </Col>
                                            </Row>
                                        </Form.Item> : null}
                                    <Form.Item label="Head Injury" initialValue={this.state.ChildDetails.headInjury}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.headInjury} onChange={(e) => this.onSelectChange(e, 'headInjury')} defaultValue={this.state.ChildDetails.headInjury}>
                                                    <Option value='Absent'>Absent</Option>
                                                    <Option value='Present'>If present,Specify</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {this.state.ChildDetails.headInjury === 'Present' ?
                                        <Form.Item label="Head Injury Details" initialValue={this.state.ChildDetails.headInjuryDetails}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name='headInjuryDetails' onChange={this.onInputChange} value={this.state.ChildDetails.headInjuryDetails}></Input>
                                                </Col>
                                            </Row>
                                        </Form.Item> : null}
                                    <Form.Item label="Any Other Illness (Give Details)" initialValue={this.state.ChildDetails.illness}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.illness} onChange={(e) => this.onSelectChange(e, 'illness')} defaultValue={this.state.ChildDetails.illness}>
                                                    <Option value='Fits'>Fits</Option>
                                                    <Option value='Jaundice'>Jaundice</Option>
                                                    <Option value='Dengue'>Dengue</Option>
                                                    <Option value='Others'>Others Specify</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {this.state.ChildDetails.illness === 'Others' ?
                                        <Form.Item label="Other Illness" initialValue={this.state.ChildDetails.illnessOthers}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name='illnessOthers' onChange={this.onInputChange} value={this.state.ChildDetails.illnessOthers}></Input>
                                                </Col>
                                            </Row>
                                        </Form.Item> : null}
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item label="Mother Tongue (Give Details)" initialValue={this.state.ChildDetails.motherTongue}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.motherTongue} onChange={(e) => this.onSelectChange(e, 'motherTongue')} defaultValue={this.state.ChildDetails.motherTongue}>
                                                    <Option value='English'>English</Option>
                                                    <Option value='Hindi'>Hindi</Option>
                                                    <Option value='Assamese'>Assamese</Option>
                                                    <Option value='Bengali'>Bengali</Option>
                                                    <Option value='Gujarati'>Gujarati</Option>
                                                    <Option value='Kannada'>Kannada</Option>
                                                    <Option value='Kashmiri'>Kashmiri</Option>
                                                    <Option value='Konkani'>Konkani</Option>
                                                    <Option value='Malayalam'>Malayalam</Option>
                                                    <Option value='Marathi'>Marathi</Option>
                                                    <Option value='Nepali'>Nepali</Option>
                                                    <Option value='Odia'>Odia</Option>
                                                    <Option value='Punjabi'>Punjabi</Option>
                                                    <Option value='Sanskrit'>Sanskrit</Option>
                                                    <Option value='Tamil'>Tamil</Option>
                                                    <Option value='Telugu'>Telugu</Option>
                                                    <Option value='Urdu'>Urdu</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item label="Language Spoken" initialValue={this.state.ChildDetails.languageSpoken}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name='languageSpoken' onChange={this.onInputChange} value={this.state.ChildDetails.languageSpoken}></Input>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item label="Language Written" initialValue={this.state.ChildDetails.languageWritten}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Input name='languageWritten' onChange={this.onInputChange} value={this.state.ChildDetails.languageWritten}></Input>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item label="Handedness" initialValue={this.state.ChildDetails.handedness}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.handedness} onChange={(e) => this.onSelectChange(e, 'handedness')} defaultValue={this.state.ChildDetails.handedness}>
                                                    <Option value='Right'>Right</Option>
                                                    <Option value='Left'>Left</Option>
                                                    <Option value='Ambidextrous'>Ambidextrous</Option>
                                                    <Option value='LeftToRight'>Forced Change From Left to Right</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item label="Present Complaints" initialValue={this.state.ChildDetails.complaints}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <TextArea name='complaints' onChange={this.onInputChange} value={this.state.ChildDetails.complaints}></TextArea>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item label="Family History" initialValue={this.state.ChildDetails.familyHistory}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.familyHistory} onChange={(e) => this.onSelectChange(e, 'familyHistory')} defaultValue={this.state.ChildDetails.familyHistory}>
                                                    <Option value='LearningDisability'>Specific Learning Disability</Option>
                                                    <Option value='Hyperactivity'>Attention Deficit Hyperactivity Disorder</Option>
                                                    <Option value='Autism'>Autism Spectrum Disorder</Option>
                                                    <Option value='Other'>Any Other Developmental Disorders in Family members(give details)</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {this.state.ChildDetails.familyHistory === 'Other' ?
                                        <Form.Item label="Other" initialValue={this.state.ChildDetails.familyMembersDisorder}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name='familyMembersDisorder' onChange={this.onInputChange} value={this.state.ChildDetails.familyMembersDisorder}></Input>
                                                </Col>
                                            </Row>
                                        </Form.Item> : null}
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item colon={false} label="Previous Consultations/ Treatment/ Theraphy (Give details):" initialValue={this.state.ChildDetails.previousTherapy}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select name='previousTherapy' value={this.state.ChildDetails.previousTherapy} onChange={(e) => this.onSelectChange(e, 'previousTherapy')} defaultValue={this.state.ChildDetails.previousTherapy}>
                                                    <Option value='yes'>Yes</Option>
                                                    <Option value='no'>No</Option>
                                                </Select>
                                                {/* <TextArea name='previousTherapy' onChange={this.onInputChange} value={this.state.ChildDetails.previousTherapy}></TextArea> */}
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                                {this.state.ChildDetails.previousTherapy === "yes" ?
                                <Col span={6}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                        <Col className="gutter-row" span={10}>
                                            <input type="radio" id="addDetails" style={{ marginRight: '8px' }} name="theraphydetails" value="AddDetails" onChange={e => { this.theraphychange(e) }}></input>
                                            <label for="addDetails" style={{ fontSize: '20px' }}>Add Details</label>
                                        </Col>
                                        <Col className="gutter-row" span={12}>
                                            <input type="radio" id="viewDetails" style={{ marginRight: '8px' }} name="theraphydetails" value="ViewDetails" onChange={e => { this.theraphychange(e) }}></input>
                                            <label for="viewDetails" style={{ fontSize: '20px' }}>View Details</label>

                                        </Col>
                                    </Row>
                                </Col>:null}
                                {/* <Col className="gutter-row" span={5}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={10}>
                                            <Button type="primary" onClick={() => this.theraphychange("AddDetails")}>Add</Button>
                                        </Col>
                                        <Col className="gutter-row" span={12}>
                                            <Button type="primary" onClick={() => this.theraphychange("ViewDetails")}>View</Button>
                                        </Col>
                                    </Row>
                                </Col> */}

                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item label="Child is Attending" initialValue={this.state.ChildDetails.childAttending}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <Select value={this.state.ChildDetails.childAttending} onChange={(e) => this.onSelectChange(e, 'childAttending')} defaultValue={this.state.ChildDetails.childAttending}>
                                                    <Option value='DayCare'>Day Care</Option>
                                                    <Option value='PaidCare'>Paid Care Giver</Option>
                                                    <Option value='Creche'>Creche</Option>
                                                    <Option value='CrecheOffice'>Creche in the Office</Option>
                                                    <Option value='GrandParents'>GrandParents</Option>
                                                    <Option value='Others'>Others-yes/No</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                                {this.state.ChildDetails.childAttending === "Others" ?
                                    <Col span={5}>
                                        <Form.Item name="childAttendingOthers" initialValue={this.state.ChildDetails.childAttendingOthers}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Radio.Group name='childAttendingOthers' onChange={this.onInputChange} style={{ paddingRight: 0 }} value={this.state.ChildDetails.childAttendingOthers === true ? "Yes" : "No"}>
                                                        <Radio value='Yes'>Yes </Radio>
                                                        <Radio value='No'>No </Radio>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col> : null}
                                {this.state.ChildDetails.childAttendingOthers === true ?
                                    <Col span={5}>
                                        <Form.Item initialValue={this.state.ChildDetails.childAttendingOthersDetails}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={24}>
                                                    <Input name='childAttendingOthersDetails' onChange={this.onInputChange} value={this.state.ChildDetails.childAttendingOthersDetails}></Input>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col> : null}
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={14}>
                                    <Form.Item label="Any Other Information (Give Details)" initialValue={this.state.ChildDetails.otherInfo}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={24}>
                                                <TextArea name='otherInfo' onChange={this.onInputChange} value={this.state.ChildDetails.otherInfo}></TextArea>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit">Save</Button>
                            </Form.Item>
                        </Form >
                        {this.state.showUploadtheraphy &&
                            <Modal className="modal-custom-top" maskClosable={false}
                                title="Add Theraphy Details" visible={this.state.showUploadtheraphy}
                                onCancel={() => this.onCancel()}
                                onOk={() => this.onSaveUploadAnswer()}
                            >
                                <Spin indicator={<SettingOutlined spin={true} />} tip="Loading..." spinning={this.state.loading}>
                                    <Space direction='vertical'>
                                        <div>
                                            <Row gutter={24}>
                                                <Col className="gutter-row" span={10}>
                                                    <h5>Upload Answer</h5>
                                                </Col>
                                                <Col className="gutter-row" span={14}>
                                                    <Upload id="upload_file" customRequest={this.dummyRequest} onChange={this.uploadtheraphyChange} accept='.jpg,.png,.txt,.pdf' maxCount={1}>
                                                        <Button icon={<UploadOutlined />}>Upload Answer Sheet</Button>
                                                    </Upload>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Space>
                                </Spin>
                            </Modal>
                        }
                        {this.state.showViewtheraphy &&
                            <Modal className="modal-custom-top" maskClosable={false}
                                title="View Theraphy Details" visible={this.state.showViewtheraphy}
                                onCancel={() => this.onCancel()}
                                Footer={null}
                            >
                                <Space direction='vertical'>
                                    <div>
                                        <Table pagination={true} size='small' rowKey="baseDocumentToken" dataSource={this.state.childDocViewDetails} columns={docViewDetailsColumn} />
                                    </div>
                                </Space>
                            </Modal>
                        }
                    </div>
                    {/* } */}

                    {/* {this.state.BackClick === true ?
                this.props.showNewChildern === true ?
                    <NewChildren></NewChildren> : this.props.showChildAssessment === true ?
                        <ChildAssessmentDetails></ChildAssessmentDetails> : null
                : null} */}
                </div>
                {/* <Footer></Footer> */}
            </div >

        )
    }
}

const mapStateToProp = state => {
    return {
        userDetailsJson: state.userDetailsJson,
        userLoginStatus: state.userLoginStatus

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        UserDetails: () => dispatch(UserDetails()),
        ChildIdData: childIdValue => dispatch(ChildIdData(childIdValue)),
        LoginStatus: userLoginStatus => dispatch(LoginStatus(userLoginStatus)),
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(withRouter(CaseHistory)); 