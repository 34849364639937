import axios from "axios";
import { ServiceUrl } from '../../WebConfig/web.config';

export async function Authenticate(loginRequest) {
    let loginResponse = [];
    loginRequest.Password = btoa(loginRequest.Password);
    var data = JSON.stringify(loginRequest);
    await axios.post(ServiceUrl + 'authenticateuser', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        loginResponse = response.data;
    }))
    return loginResponse;
}
export async function ChangePasswordRequest(mailId) {
    let mailresponse = [];
    const response = await axios.get(ServiceUrl + 'changepassword?signon=' + mailId+'&servicetyperequestid=3').then((response) => {
        mailresponse = response.data;
    })
    return mailresponse;
}
export async function UpdatePassword(passwordRequest) {
    let passwordResponse = [];
    passwordRequest.NewPassword = btoa(passwordRequest.NewPassword);
    var data = JSON.stringify(passwordRequest);
    await axios.post(ServiceUrl + 'updatepassword', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        passwordResponse = response.data;
    }))
    return passwordResponse;
}
