//  export const ServiceUrl = "http://localhost:50714/api/";
//  export const ConsentFormURL ="http://localhost:3000/ConsentForm/TemplateFilePath/ConsentTemplate.html";

// export const ServiceUrl = "https://www.viriddham.com/CoreService/api/";
// export const ConsentFormURL ="https://viriddham.com/ConsentForm/TemplateFilePath/ConsentTemplate.html"

export const ServiceUrl = "https://docxproduction.com/Coreservice/api/";
export const ConsentFormURL ="https://docxproduction.com/ConsentFormPath/TemplateFilePath/ConsentTemplate.html"

//export const ServiceUrl = "https://www.viriddham.com/QA/CoreService/api/";
//export const ConsentFormURL ="https://viriddham.com/QA/OnlinePortal/ConsentForm/TemplateFilePath/ConsentTemplate.html";


//Change ConsentTemplate-localhost in userdashboardservice

