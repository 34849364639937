import axios from "axios";
import { ServiceUrl,ConsentFormURL } from '../../WebConfig/web.config';

export async function getChildInfoResponse(userId) {
    let childResponse = [];
    userId=encodeURIComponent(userId);
    const response = await axios.get(ServiceUrl + 'getchilds?userInfoToken=' + userId).then((response) => {
        childResponse = response.data;
    })
    return childResponse;
}
export async function getChildDocuments(childId) {
    let childDocumentResponse = [];
    const response = await axios.get(ServiceUrl + 'getchilddocuments?childId=' + childId).then((response) => {
        childDocumentResponse = response.data;
    })
    return childDocumentResponse;
}
export async function saveChildDocuments(documentRequest) {
    let documentResponse = [];
    var data = JSON.stringify(documentRequest);
    await axios.post(ServiceUrl + 'savechilddocuments', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        documentResponse = response.data;
    }))
    return documentResponse;
}
export async function GetChildAdditionSeedInfo() {
    let childSeedResponse = [];
    const response = await axios.get(ServiceUrl + 'getchildadditionseedinfo').then((response) => {
        childSeedResponse = response.data;
    })
    return childSeedResponse;
}
// export async function ConsentTemplate() {
//     let data ="";
//     await  fetch('https://viriddham.com/ConsentForm/TemplateFilePath/ConsentTemplate.html')
//             .then(response => response.text())
//             .then(text => {
//                 data = text.toString()
//             })
//     return data;
// }
// export async function ConsentTemplate() {
//     let data ="";
//     await  fetch('https://viriddham.com/QA/OnlinePortal/ConsentForm/TemplateFilePath/ConsentTemplate.html')
//             .then(response => response.text())
//             .then(text => {
//                 data = text.toString()
//             })
//     return data;
// }
export async function ConsentTemplate() {
    let data ="";
    const consentFormPath = "/ConsentForm/TemplateFilePath/ConsentTemplate.html";
    await  fetch(consentFormPath)
            .then(response => response.text())
            .then(text => {
                data = text.toString()
            })
    return data;
}
export async function AddChildInfo(childRequest) {
    let childResponse = [];
    var data = JSON.stringify(childRequest);
    await axios.post(ServiceUrl + 'addchild', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        childResponse = response.data;
    }))
    return childResponse;
}
export async function UpdateChildInfo(childRequest) {
    let editedChildResponse = [];
    var data = JSON.stringify(childRequest);
    await axios.post(ServiceUrl + 'updatechild', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        editedChildResponse = response.data;
    }))
    return editedChildResponse;
}
export async function getPaymentType(userId) {
    let paymentResponse = [];
    userId=encodeURIComponent(userId);
    const response = await axios.get(ServiceUrl + 'getpaymenttype?userid=' + userId).then((response) => {
        paymentResponse = response.data;
    })
    return paymentResponse;
}
export async function AddPreAssessmentDetails(preAssessmentRequest) {
    debugger;
    let preAssessmentResponse = [];
    var data = JSON.stringify(preAssessmentRequest);
    await axios.post(ServiceUrl + 'addpreassessmentdetails', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        preAssessmentResponse = response.data;
    }))
    return preAssessmentResponse;
}
export async function getPreAssessmemtReport(childId) {
    let ReportResponse = [];
    const response = await axios.get(ServiceUrl + 'getpreassessmemtreport?childIdToken=' + childId).then((response) => {
        ReportResponse = response.data;
    })
    return ReportResponse;
}
export async function SaveConsentFormDocument(ChildId, FileUrl, changedby) {
    let consentFormResponse = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ChildId:ChildId,FileUrl: FileUrl, Changedby: changedby })

    };
    await fetch(ServiceUrl + 'saveconsentformdocument', data)
        .then((response) => response.json())
        .then((responseJson) => {
            consentFormResponse = responseJson;
        })
    return consentFormResponse;
}