import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../MediaCss/viri.css'
import '../MediaCss/bootstrap.min.css'
import '../MediaCss/menu.css'
import './DashBoard.css'
import { Input, Row, Col, Space, Dropdown, Menu, Modal, Form } from 'antd';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { LoginStatus, UserDetails } from '../../Redux/Redux.Action';
import $ from 'jquery';
import { ChangeUserPassword } from './DashBoardService'

class DashBoard extends Component {
    refCreateNewPassword = React.createRef();
    constructor(props) {
        super(props);
        this.state = { userName: '', userId: '', showChangePassword: false, passwordDetails: [], }
    }
    componentDidMount = () => {
        if (this.props.userLoginStatus === true) {
            $(".auto-width").css('max-width', '826px');
            document.getElementById("topnavbar").style.display = 'none';
            var userName = this.props.userDetailsJson.name;
            var userID = this.props.userDetailsJson.token;
            this.setState({ userName: userName, userId: userID })
        }
    }
    onChangePassword = () => {
        this.setState({ showChangePassword: true })
    }
    onLogout = () => {
        this.props.LoginStatus(false);
        this.props.history.push('/Login');
    }

    onlistClick = (x) => {
        if (x === "Home") {
            this.props.history.push('/Home');
        }
        else if (x === "AboutUs") {
            this.props.history.push('/AboutUs');
        }
        else if (x === "Gallery") {
            this.props.history.push('/Gallery');
        }
        else if (x === "UserDashboard") {
            this.props.history.push('/UserDashboard');
        }
        else if (x === "OurTeam") {
            this.props.history.push('/OurTeam');
        }
        else if (x === "ContactUs") {
            this.props.history.push('/ContactUs');
        }
        else if (x === "Services") {
            this.props.history.push('/Services');
        }
    }
    RegisterUser = (value) => {
        if (value === "Login") {
            this.props.history.push('/Login');
        }
        else if (value === "Register") {
            this.props.history.push('/Register');
        }
    }
    onPasswordChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState((prevState) => ({
            passwordDetails: { ...prevState.passwordDetails, [name]: value }
        }))
    }
    onPasswordCancel = () => {
        this.setState({ showChangePassword: false })
    }

    onSaveNewPassword = async () => {
        var passWordData = this.state.passwordDetails;
        var encodeOldPassword = btoa(passWordData.oldPassWord);
        var encodeNewPassword = btoa(passWordData.newPassWord);
        var userID = encodeURIComponent(this.state.userId);
        var userName = this.state.userName;
        var passwordRequest = {
            UserId: userID,
            ChangedBy: userName,
            OldPassword: encodeOldPassword,
            NewPassword: encodeNewPassword,
        }
        let passwordResponse = await ChangeUserPassword(passwordRequest);
        if (passwordResponse.businessRules.length === 0) {
            this.setState({ showChangePassword: false })
            Modal.success({
                content: "Password Updated Successfully!"
            })
            this.props.history.push('/Login');
        }
        else {
            Modal.error({
                title: 'Error Message',
                content: passwordResponse.businessRules[0].description
            })
        }
    }
    render() {
        const logmenu = (
            <Menu>
                <Menu.Item key="0">
                    <a onClick={this.onChangePassword}><i class="fa fa-key"></i><span>Change Password</span></a>
                </Menu.Item>
                <Menu.Item key="3">
                    <a onClick={this.onLogout}><i class="fa fa-sign-out"></i><span>Logout</span></a>
                </Menu.Item>
            </Menu>
        );
        const passwordlayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 12 },
        }
        return (
            <div>
                <header class="header-area stricky headbdr">
                    <div class="outer-box clearfix">
                        <div class="col-md-1">
                            <div class="logo wow ">
                                <a href="#">
                                    <img src={process.env.PUBLIC_URL + "/Images/viriddhamlogo.png"} class="img-responsive logo-height" alt="VIRIDDHAM" />
                                </a>
                            </div>
                        </div>
                        <div class="col-md-11">
                            <nav class="main-menu wow ">
                                <div class="navbar-header">
                                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>
                                </div>
                                <div class="navbar-collapse collapse clearfix ">
                                    <div class="top-bar-area">
                                        <ul id="topnavbar" class="nav">
                                            <li class="regi-btn mar0"><a data-toggle="tooltip" title="Login" onClick={() => this.RegisterUser("Login")}>Login</a></li>
                                            <li class="login-btn"><a data-toggle="tooltip" title="Register" onClick={() => this.RegisterUser("Register")}>Register</a></li>
                                        </ul>
                                        {this.props.userLoginStatus &&
                                            <div class="btn-group" style={{ right: 30 }}>
                                                <Space className="pull-right">
                                                    <label>Welcome {this.state.userName}</label>
                                                    <Dropdown overlay={logmenu}>
                                                        <a onClick={e => e.preventDefault()} className="logout-size"><i className="fa fa-user-circle-o" ></i></a>
                                                    </Dropdown>
                                                </Space>
                                            </div>
                                        }
                                    </div>
                                    <div class="navbar auto-width">
                                        <ul class="navigation">
                                            <li class="hvr-sweep-to-top homebtn"><a class="nav-item homebtnpad homebtn" onClick={() => this.onlistClick("Home")}>Home</a></li>
                                            {this.props.userLoginStatus && <li class="dashbrdhvr-sweep-to-top dashbrdbtn"><a class="nav-item dashbrdbtnpad" onClick={() => this.onlistClick("UserDashboard")}>Dashboard</a></li>}
                                            <li class="abthvr-sweep-to-top abtbtn"><a class="nav-item abtbtnpad" onClick={() => this.onlistClick("AboutUs")}>About Us</a></li>
                                            <li class="ourteamhvr-sweep-to-top ourteambtn"><a class="nav-item ourteampad" onClick={() => this.onlistClick("OurTeam")} >Our Team</a></li>
                                            <li class="serviceshvr-sweep-to-top servicesbtn"><a class="nav-item servicesbtnpad" onClick={() => this.onlistClick("Services")}>Services</a></li>
                                            <li class="galleryhvr-sweep-to-top  gallerybtn"><a class="nav-item contactbtnpad" onClick={() => this.onlistClick("Gallery")} >Gallery</a></li>
                                            <li class="contacthvr-sweep-to-top contactbtn"><a class="nav-item nav-item-last" onClick={() => this.onlistClick("ContactUs")} >Contact us</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
                {this.state.showChangePassword
                    &&
                    <Modal className="modal-custom-top"
                        title="Change Password" visible={this.state.showChangePassword}
                        onCancel={() => this.onPasswordCancel()}
                        onOk={() => this.refCreateNewPassword.current.submit()}
                    >
                        <Form {...passwordlayout} id="frmchangePassword" ref={this.refCreateNewPassword} className="card-footer-right" initialValues={{ remember: true }} onFinish={this.onSaveNewPassword} >
                            <Form.Item initialValue={this.state.passwordDetails.oldPassWord} name="oldPassWord" label="Old PassWord" rules={[{ required: true, message: 'Please enter Old Password!' }]} >
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={24}>
                                        <Input.Password name="oldPassWord" value={this.state.passwordDetails.oldPassWord} onChange={e => this.onPasswordChange(e)} />
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item initialValue={this.state.passwordDetails.newPassWord} name="newPassWord" label="New PassWord" rules={[{ required: true, message: 'Please enter New Password!' }]}>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={24}>
                                        <Input.Password name="newPassWord" value={this.state.passwordDetails.newPassWord} onChange={e => this.onPasswordChange(e)} />
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item initialValue={this.state.passwordDetails.confirmPassWord} name="confirmPassWord" label="Confirm PassWord" rules={[{ required: true, message: 'Please enter Confirm Password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassWord') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),]}>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={24}>
                                        <Input.Password name="confirmPassWord" value={this.state.passwordDetails.confirmPassWord} onChange={e => this.onPasswordChange(e)} />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Modal>
                }
            </div>
        );
    }
}
const mapStateToProp = state => {
    return {
        userLoginStatus: state.userLoginStatus,
        userDetailsJson: state.userDetailsJson,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        UserDetails: () => dispatch(UserDetails()),
        LoginStatus: userLoginStatus => dispatch(LoginStatus(userLoginStatus)),
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(withRouter(DashBoard));
