import axios from "axios";
import {ServiceUrl} from '../../WebConfig/web.config';

export async function RegisterUser(registerUserRequest) {
    let registerUserResponse = [];
    var data = JSON.stringify(registerUserRequest);
    await axios.post(ServiceUrl + 'registeruser', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        registerUserResponse = response.data;
    }))
    return registerUserResponse;
}