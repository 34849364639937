import axios from "axios";
import { ServiceUrl } from '../../WebConfig/web.config';


export async function GetChildProfile(ChildId) {
    let ProfileResponse = [];
    let Id = encodeURIComponent(ChildId);
    const data = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    await fetch(ServiceUrl + 'getchildprofile?childIdToken=' + Id, data)
        .then((response) => response.json())
        .then((responseJson) => {
            ProfileResponse = responseJson;
        })
    return ProfileResponse;
}
export async function SaveChildprofile(profileData) {
    debugger;
    let profileDataResponse = [];
    var data = JSON.stringify(profileData);
    await axios.post(ServiceUrl + 'savechildprofile', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        profileDataResponse = response.data;
    }))
    return profileDataResponse;
}
export async function SaveChildProfileDocuments(uploadRequestData) {
    debugger;
    let uploadDataResponse = [];
    var data = JSON.stringify(uploadRequestData);
    await axios.post(ServiceUrl + 'savechildprofiledocuments', data, { headers: { 'Content-Type': 'application/json' } }).then((response => {
        uploadDataResponse = response.data;
    }))
    return uploadDataResponse;
}
export async function GetChildProfileDocuments(ChildId) {
    debugger;
    let ProfileResponse = [];
    let Id = encodeURIComponent(ChildId);
    const data = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    await fetch(ServiceUrl + 'getchildprofiledocuments?childIdToken=' + Id, data)
        .then((response) => response.json())
        .then((responseJson) => {
            ProfileResponse = responseJson;
        })
    return ProfileResponse;
}
// export async function SaveChildprofile(profileData) {
//     debugger;
//     let profileDataResponse = [];
//     const data = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(profileData)
//     };
//     await fetch(ServiceUrl + 'savechildprofile', data)
//         .then((response) => response.json())
//         .then((responseJson) => {
//             profileDataResponse = responseJson;
//         })
//     return profileDataResponse;
// }