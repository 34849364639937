import React, { Component } from 'react';
import '../MediaCss/viri.css';
import '../MediaCss/bootstrap.min.css';
import '../MediaCss/font-awesome.min.css';
import './Footer.css'
import { Space } from 'antd';
import { withRouter } from 'react-router-dom';

class Footer extends Component {
    constructor() {
        super();
        this.state = {}
    }
    onClickFooter = (value) => {
        if (value === "PrivacyStatement") {
            this.props.history.push('/PrivacyStatement')
        }
        else if (value === "TermsServices") {
            this.props.history.push('/TermsService')
        }
        else if (value === "RefundPolicy") {
            this.props.history.push('/RefundPolicy')
        }
        else if(value === "AboutUs") {
            this.props.history.push('/AboutUs');
        }
        else if(value === "ContactUs") {
            this.props.history.push('/ContactUs');
        }
        
    }
    render() {
        return (
            <div>
                <footer id="footer">
                    <div class="header-divider"></div>
                    <div style={{ backgroundColor: '#e7e7e7' }}>
                        <div class="">
                            <div class="align-items-center justify-content-center">
                                <div class="coppy__right__inner text-center" style={{ margin: '0px', padding: '10px 0' }} >
                                    <p style={{ margin: 0 }}>Copyright <i class="fa fa-copyright"></i>2021 Viriddham Micro Learning Services Pvt Ltd<br /></p>
                                    <Space >
                                        <a onClick={() => this.onClickFooter("AboutUs")} ><h6>About Us</h6></a>
                                        <a onClick={() => this.onClickFooter("ContactUs")}><h6>Contact Us</h6></a>
                                        <a onClick={() => this.onClickFooter("PrivacyStatement")} ><h6>Privacy Statement</h6></a>
                                        <a onClick={() => this.onClickFooter("TermsServices")}><h6>Terms and Service</h6></a>
                                        <a onClick={() => this.onClickFooter("RefundPolicy")}><h6>Refund Policy</h6></a>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div >
        );
    }
}

export default withRouter(Footer);