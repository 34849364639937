import React, { Component } from 'react';
import Header from '../DashBoard/DashBoard';
import Footer from '../Footer/Footer';
import './ContactUs.css'

class ContactUs extends Component {
    render() {
        return (
            <div>
                <Header></Header>
                <main role="main" class="Maincontainer">
                    <div class="container" id="contact" style={{height:'calc(100vh - 212px)'}}>
                        <div class="col-md-12">
                            <h2 class="title text-center text-capitalize contactUs">Contact us</h2>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="parallelogram">
                                        <span class="homeicon"><i class="fa fa-home homeicon pull-left"></i></span><div class="location transformicon">Our Location</div>
                                        <p class="">
                                            No.10/10, N.No.14, <br />
                                            Hari Om Colony<br />

                                            West Vanniar Street, <br />
                                            Indian Bank Colony<br />
                                            K.K.Nagar<br />
                                            Chennai - 600078<br />
                                            Tamil Nadu, India
                                        </p>
                                    </div>

                                </div>
                                <div class="col-md-4">
                                    <div class="contactbg">
                                        <span class="homeicon"><i class="fa fa-phone phonclor pull-left"></i></span><div class="location transformicon">Our Contact</div>
                                        <p class="">044 28275747</p>
                                        <span class="homeicon"><i class="fa fa-envelope emailclr pull-left"></i></span><div class="location transformicon">Our Email</div>
                                        <p class=""><a href="mailto:admin@viriddham.com">admin@viriddham.com</a></p>
                                        <ul class="social">
                                            <li>
                                                <a href="#" target="_blank">
                                                    <i class="fa fa-facebook"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" target="_blank">
                                                    <i class="fa fa-twitter"></i>
                                                </a>
                                            </li>

                                            <li>
                                                <a href="#" target="_blank">
                                                    <i class="fa fa-linkedin"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div class="col-md-4">
                                    <div class="hourbg">
                                        <span class="homeicon"><i class="fa fa-clock-o emailclr  pull-left"></i></span><div class="location transformicon marl0">Our Working Hours</div>
                                        <ul>
                                            <li>Monday 10am–6pm</li>
                                            <li>Tuesday 10am–6pm</li>
                                            <li>Wednesday 10am–6pm</li>
                                            <li>Thursday 10am–6pm</li>
                                            <li>Friday 10am–6pm</li>
                                            <li>Saturday 10am–6pm</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer></Footer>
            </div>
        );
    }
}

export default ContactUs;