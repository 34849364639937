import React, { Component } from 'react';
import Header from '../DashBoard/DashBoard';
import Footer from '../Footer/Footer';
import '../MediaCss/viri.css';
import '../MediaCss/form.css';
import './About.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

class About extends Component {
    constructor(props) {
        super(props);
        AOS.init({duration:800});
    }
   
    render() {
        return (
            <div>
                <Header></Header>
                <div class="aboutbg">
                    <div class="container-fluid">
                        <div class="aboutsusbackground">
                            <div class="container" style={{backgroundColor: 'rgb(253, 238, 233, 0.7)' }}>
                                <div class="col-md-12">
                                    <div class="row">
                                        <h2 data-aos="fade-down" data-aos-easing="linear" >About <span class="titlevidd">Viriddham</span></h2>
                                        <div class="aboutbglist">
                                            <ul>
                                                <li><img src="Images/listicon.jpg" />Identify the academic difficulties in children by a formal standardized assessment procedure.</li>
                                                <li><img src="Images/listicon.jpg" />Assess the presence of Specific Learning Difficulty (SLD) in writing, reading, spelling and mathematics.</li>
                                                <li><img src="Images/listicon.jpg" />Screen the presence of the co-morbid disorders associated with SLD like Attention Deficit Hyperactivity Disorder (ADHD), Autism Spectrum Disorder (ASD) and other behavioural issues namely avoidance of learning situations, school refusal, temper tantrums, sleep disturbance, etc.</li>
                                                <li><img src="Images/listicon.jpg" />Bring awareness among parents, teachers, educational authorities, health care professionals and at large, people who are heading the institutions.</li>
                                                <li><img src="Images/listicon.jpg" />Offer remedial education services for children with reading, writing, spelling and arithmetic disorders through online mode / virtual interaction.</li>
                                                <li><img src="Images/listicon.jpg" />Offer consultation to the parents/ caregivers of children with academic difficulties - to identify and offer psychological intervention along with Remedial Education Services.</li>
                                                <li><img src="Images/listicon.jpg" />Offer one-on-one online classes with virtual practical sessions for learning difficulty and  associated developmental disorders.</li>
                                                <li><img src="Images/listicon.jpg" />Offer academic support to needy children and health care assistance in the form of psychological intervention, counselling, etc.</li>
                                                <li><img src="Images/listicon.jpg" />Consultation from well qualified and experienced Doctors and Allied Health Professionals of Viriddham Consultants panel for behavioural issues, delayed speech and language, difficulty in fine and gross motor skills, etc.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Footer></Footer>
            </div>
        );
    }
}

export default About;