import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../DashBoard/DashBoard';
import '../MediaCss/viri.css';
import '../MediaCss/bootstrap.min.css';
import { Input, Modal, Button, Space, Table, Row, Col, Radio, Form, Upload, Select, Drawer, Card, DatePicker, Checkbox, Spin, message, TimePicker } from 'antd';
import 'antd/dist/antd.css';
import {
  getChildInfoResponse, getChildDocuments, saveChildDocuments, GetChildAdditionSeedInfo, ConsentTemplate,
  AddChildInfo, UpdateChildInfo, getPaymentType, AddPreAssessmentDetails, getPreAssessmemtReport,SaveConsentFormDocument
} from './UserDashboardService';
import { connect } from 'react-redux';
import { ChildIdData, LoginStatus, UserDetails } from '../../Redux/Redux.Action';
import './UserDashboard.css';
import moment from 'moment';
import { UploadOutlined, SettingOutlined } from '@ant-design/icons';
import { openPayModal, savepaymentresponse } from '../../Components/Payment/RazorPayService'
import { GetUserInfo, SaveCartSession, PreProcessSessionOrder, ProcessOrder } from '../Payment/PaymentService';
import { validateName } from '../../Utils/Validation';
import { ServiceUrl,ConsentFormURL } from '../../WebConfig/web.config';

const { Option } = Select;

class UserDashboard extends Component {
  constructor() {
    super();
    this.state = {
      regPaymentDone: false, regPaymentNotDone: false,
      childInfos: [], loginemail: '', loginPassword: '', showPreAssessment: false, showPreAssessmentresult: false, showQuestions: false,
      readingDifficulty: 0, writingDifficulty: 0, spellingDifficulty: 0, mathDifficulty: 0, behaviourIssues: 0,
      readingPresence: false, readingFeatures: false, writingFeatures: false, writingPresence: false, spellingFeatures: false, spellingPresence: false,
      mathFeatures: false, mathPresence: false, behaviourFeatures: false, behaviourPresence: false, showUploadAnswer: false,
      languageData: [], uploadedFiles: [], childData: [], userName: '', showAddChild: false, addNewChildData: [],
      childSeedInfo: [], userdetails: [], showConsentForm: false, consentFormData: '', termsChecked: false, addChildTitle: '',
      showNEFTPayment: false, showPaymentMethod: false, paymentResponsedata: [], transactionId: '', loading: false, RegisterPaymentNeft: false,
      regdemo: false, consentLoading: false, neftpaymentLoading: false, childPreAssessmentData: [], childPreAssessmentReport: {},
      userType: 0, consentFormShow: false, showUploadConsentForm: false, consentFormLoading: false, fileData: [],
      docUpload: true
    }
  }
  formRef = React.createRef();
  componentDidMount = async () => {
    debugger;
    if (this.props.userLoginStatus === true) {
      var userdetails = this.props.userDetailsJson;
      var userID = this.props.userDetailsJson.token;
      var userName = this.props.userDetailsJson.name;
      var userGrpType = this.props.userDetailsJson.userType;
      if (userGrpType === 4) {
        this.setState({ consentFormShow: true })
      }
      this.setState({ userName: userName, userdetails: userdetails, userType: userGrpType });
      // let paymentResponse = await getPaymentType(userID);
      // if (paymentResponse.length > 0) {
      //   var Paymentsuccess = paymentResponse.filter((item) => ((item.paymentType === "Register") || (item.paymentType === "NEFT Register")));
      //   if (Paymentsuccess.length > 0) {
      //     this.setState({ regPaymentDone: true, regPaymentNotDone: false })
      //   }
      //   else {
      //     this.setState({ regPaymentDone: false, regPaymentNotDone: true })
      //   }
      // }
      // else {
      //   this.setState({ regPaymentDone: false, regPaymentNotDone: true })
      // }
      let childResponse = await getChildInfoResponse(userID);
      var dataSource = [];
      if (childResponse.success == true) {
        dataSource = childResponse.childInfos
        this.setState({
          childInfos: dataSource
        })
      }
      // else {
      //   Modal.error({
      //     title: 'Error Message',
      //     content: childResponse.businessRules[0].description
      //   })
      // }
    }
    else {
      this.props.history.push('/Login');
    }
  }
  PreAssessmentAgain = () => {
    let childData = this.state.childData;
    this.setState({
      showPreAssessment: true, childData: childData, showPreAssessmentresult: false,
    })
  }
  onPreAssessment = async (record) => {
    var childData = record;
    var childId = encodeURIComponent(record.token)
    var userID = this.props.userDetailsJson.token;
    let paymentResponse = await getPaymentType(userID);
    if (paymentResponse.length > 0) {
      var Paymentsuccess = paymentResponse.filter((item) => (((item.paymentType === "Register") || (item.paymentType === "NEFT Register") || (item.paymentType === "Free Access")) && (item.childId === record.token)));
      if (Paymentsuccess.length > 0) {
        this.setState({ regPaymentDone: true, regPaymentNotDone: false })
        let preAssessmentResponse = await getPreAssessmemtReport(childId);
        if (preAssessmentResponse.businessRules.length === 0) {
          if (preAssessmentResponse.status === false) {
            this.setState({
              showPreAssessment: true, childData: childData
            })
          }
          else {
            if (preAssessmentResponse.readingDifficulty === "ReadingPresence") {
              this.setState({ readingPresence: true, readingFeatures: false })
            }
            else {
              this.setState({ readingFeatures: true, readingPresence: false })
            }
            if (preAssessmentResponse.writingDifficuly === "WritingPresence") {
              this.setState({ writingPresence: true, writingFeatures: false })
            }
            else {
              this.setState({ writingFeatures: true, writingPresence: false })
            }
            if (preAssessmentResponse.spellingDifficulty === "SpellingPresence") {
              this.setState({ spellingPresence: true, spellingFeatures: false })
            }
            else {
              this.setState({ spellingFeatures: true, spellingPresence: false })
            }
            if (preAssessmentResponse.mathDifficulty === "MathPresence") {
              this.setState({ mathPresence: true, mathFeatures: false })
            }
            else {
              this.setState({ mathFeatures: true, mathPresence: false })
            }
            if (preAssessmentResponse.behaviourIssues === "BehaviourPresence") {
              this.setState({ behaviourPresence: true, behaviourFeatures: false })
            }
            else {
              this.setState({ behaviourFeatures: true, behaviourPresence: false })
            }
            this.setState({ showPreAssessmentresult: true, showPreAssessment: false, childData: childData, paymentResponsedata: paymentResponse })
          }
        }
        else {
          Modal.error({
            title: 'Error Message',
            content: preAssessmentResponse.businessRules[0].description
          })
        }
      }
      else {
        this.setState({ regPaymentDone: false, regPaymentNotDone: true, childData: childData })
      }
    }
    else {
      this.setState({ regPaymentDone: false, regPaymentNotDone: true, childData: childData })
    }
  }
  onCancel = async (cancelvalue) => {
    var userID = this.props.userDetailsJson.token;
    let childResponse = await getChildInfoResponse(userID);
    let paymentResponse = await getPaymentType(userID);
    if (paymentResponse.length > 0) {
      var dataSource = [];
      if (childResponse.success == true) {
        dataSource = childResponse.childInfos
        this.setState({
          childInfos: dataSource, paymentResponsedata: paymentResponse
        })
      }
      else {
        Modal.error({
          title: 'Error Message',
          content: paymentResponse.businessRules[0].description
        })
      }
      if (cancelvalue == "UploadAnswer") {
        this.setState({
          showPreAssessment: false, showQuestions: true, showPreAssessmentresult: false, showUploadAnswer: false
        })
      }
      else {
        this.setState({
          showPreAssessment: false, showQuestions: false, showPreAssessmentresult: false, showUploadAnswer: false
        })
      }
    }
  }
  onSavePreassessDetails = async () => {
    var childPreAssessmentData = this.state.childPreAssessmentData;
    var status = true;
    status = childPreAssessmentData.AvoidReading ? true : false;
    status = childPreAssessmentData.SlowReading ? true : false;
    status = childPreAssessmentData.LackAccuracy ? true : false;
    status = childPreAssessmentData.ReadWords ? true : false;
    status = childPreAssessmentData.DislikesWriting ? true : false;
    status = childPreAssessmentData.MessyWriting ? true : false;
    status = childPreAssessmentData.GrammaticalErrors ? true : false;
    status = childPreAssessmentData.MinorWriting ? true : false;
    status = childPreAssessmentData.DifferentSpell ? true : false;
    status = childPreAssessmentData.DifficultSpellSequence ? true : false;
    status = childPreAssessmentData.MistakesWhileWriting ? true : false;
    status = childPreAssessmentData.SpellingMistakes ? true : false;
    status = childPreAssessmentData.DifficultUnderstanding ? true : false;
    status = childPreAssessmentData.SymbolConfusion ? true : false;
    status = childPreAssessmentData.ComparisonDifficulty ? true : false;
    status = childPreAssessmentData.DifficultyCalculation ? true : false;
    status = childPreAssessmentData.DifficultyConcentration ? true : false;
    status = childPreAssessmentData.RestlessStudy ? true : false;
    status = childPreAssessmentData.TalkativeDisturbs ? true : false;
    status = childPreAssessmentData.AngryStudy ? true : false;
    if (status === true) {
      var readingDiffi = 0
      var writingDiff = 0
      var spellingdiff = 0
      var mathDiff = 0
      var behaviourIss = 0
      readingDiffi = childPreAssessmentData.AvoidReading === "Yes" ? readingDiffi + 1 : readingDiffi;
      readingDiffi = childPreAssessmentData.SlowReading === "Yes" ? readingDiffi + 1 : readingDiffi;
      readingDiffi = childPreAssessmentData.LackAccuracy === "Yes" ? readingDiffi + 1 : readingDiffi;
      readingDiffi = childPreAssessmentData.ReadWords === "Yes" ? readingDiffi + 1 : readingDiffi;

      writingDiff = childPreAssessmentData.DislikesWriting === "Yes" ? writingDiff + 1 : writingDiff;
      writingDiff = childPreAssessmentData.MessyWriting === "Yes" ? writingDiff + 1 : writingDiff;
      writingDiff = childPreAssessmentData.GrammaticalErrors === "Yes" ? writingDiff + 1 : writingDiff;
      writingDiff = childPreAssessmentData.MinorWriting === "Yes" ? writingDiff + 1 : writingDiff;

      spellingdiff = childPreAssessmentData.DifferentSpell === "Yes" ? spellingdiff + 1 : spellingdiff;
      spellingdiff = childPreAssessmentData.DifficultSpellSequence === "Yes" ? spellingdiff + 1 : spellingdiff;
      spellingdiff = childPreAssessmentData.MistakesWhileWriting === "Yes" ? spellingdiff + 1 : spellingdiff;
      spellingdiff = childPreAssessmentData.SpellingMistakes === "Yes" ? spellingdiff + 1 : spellingdiff;

      mathDiff = childPreAssessmentData.DifficultUnderstanding === "Yes" ? mathDiff + 1 : mathDiff;
      mathDiff = childPreAssessmentData.SymbolConfusion === "Yes" ? mathDiff + 1 : mathDiff;
      mathDiff = childPreAssessmentData.ComparisonDifficulty === "Yes" ? mathDiff + 1 : mathDiff;
      mathDiff = childPreAssessmentData.DifficultyCalculation === "Yes" ? mathDiff + 1 : mathDiff;

      behaviourIss = childPreAssessmentData.DifficultyConcentration === "Yes" ? behaviourIss + 1 : behaviourIss;
      behaviourIss = childPreAssessmentData.RestlessStudy === "Yes" ? behaviourIss + 1 : behaviourIss;
      behaviourIss = childPreAssessmentData.TalkativeDisturbs === "Yes" ? behaviourIss + 1 : behaviourIss;
      behaviourIss = childPreAssessmentData.AngryStudy === "Yes" ? behaviourIss + 1 : behaviourIss;
      var preassessmentReport = this.state.childPreAssessmentReport;
      if (readingDiffi > 2) {
        preassessmentReport.readingDifficulty = "ReadingPresence"
        this.setState({ readingPresence: true, readingFeatures: false })
      }
      else {
        preassessmentReport.readingDifficulty = "ReadingFeatures"
        this.setState({ readingFeatures: true, readingPresence: false })
      }
      if (writingDiff > 2) {
        preassessmentReport.WritingDifficuly = "WritingPresence"
        this.setState({ writingPresence: true, writingFeatures: false })
      }
      else {
        preassessmentReport.writingDifficuly = "WritingFeatures"
        this.setState({ writingFeatures: true, writingPresence: false })
      }
      if (spellingdiff > 2) {
        preassessmentReport.spellingDifficulty = "SpellingPresence"
        this.setState({ spellingPresence: true, spellingFeatures: false })
      }
      else {
        preassessmentReport.spellingDifficulty = "SpellingFeatures"
        this.setState({ spellingFeatures: true, spellingPresence: false })
      }
      if (mathDiff > 2) {
        preassessmentReport.mathDifficulty = "MathPresence"
        this.setState({ mathPresence: true, mathFeatures: false })
      }
      else {
        preassessmentReport.mathDifficulty = "MathFeatures"
        this.setState({ mathFeatures: true, mathPresence: false })
      }
      if (behaviourIss > 2) {
        preassessmentReport.behaviourIssues = "BehaviourPresence"
        this.setState({ behaviourPresence: true, behaviourFeatures: false })
      }
      else {
        preassessmentReport.behaviourIssues = "BehaviourFeatures"
        this.setState({ behaviourFeatures: true, behaviourPresence: false })
      }
      var childData = this.state.childData;
      var preAssessmentRequest = {
        ChildId: childData.token,
        SchoolId: childData.schoolId.toString(),
        PreAssessmentDetails: this.state.childPreAssessmentData,
        PreAssessmentReport: preassessmentReport,
        ChangedBy: this.state.userName
      }
      let preAssessmentResponse = await AddPreAssessmentDetails(preAssessmentRequest);
      if (preAssessmentResponse.businessRules.length === 0) {
        this.setState({ showPreAssessmentresult: true, showPreAssessment: false, childPreAssessmentReport: preassessmentReport })
      }
      else {
        Modal.error({
          title: 'Error Message',
          content: preAssessmentResponse.businessRules[0].description
        })
      }
    }
    else {
      Modal.warning({
        title: 'Warning Message',
        content: "All Pre-Assessment Questions are Mandetory"
      })
    }
  }
  onSkipPreassessDetails = async () => {
    debugger;
    var childData = this.state.childData;
    var preAssessmentRequest = {
      ChildId: childData.token,
      SchoolId: childData.schoolId.toString(),
      PreAssessmentDetails: this.state.childPreAssessmentData,
      PreAssessmentReport: "",
      ChangedBy: this.state.userName
    }
    //let preAssessmentResponse = await AddPreAssessmentDetails(preAssessmentRequest);
    // if (preAssessmentResponse.businessRules.length === 0) {
    this.setState({ showPreAssessmentresult: true, showPreAssessment: false })
    //}
    // else {
    //   Modal.error({
    //     title: 'Error Message',
    //     content: preAssessmentResponse.businessRules[0].description
    //   })
    // }
  }
  onChangePreAssessment = (res, groupname) => {
    var name = res.target.name;
    var value = res.target.value;
    // if (groupname == "ReadingDifficulty") {
    //   this.setState(prevState => {
    //     return { readingDifficulty: value == 'Yes' ? prevState.readingDifficulty + 1 : prevState.readingDifficulty - 1 }
    //   });
    // }
    // if (groupname == "WritingDifficulty") {
    //   this.setState(prevState => {
    //     return { writingDifficulty: value == 'Yes' ? prevState.writingDifficulty + 1 : prevState.writingDifficulty - 1 }
    //   });
    // }
    // if (groupname == "SpellingDifficulty") {
    //   this.setState(prevState => {
    //     return { spellingDifficulty: value == 'Yes' ? prevState.spellingDifficulty + 1 : prevState.spellingDifficulty - 1 }
    //   });
    // }
    // if (groupname == "MathDifficulty") {
    //   this.setState(prevState => {
    //     return { mathDifficulty: value == 'Yes' ? prevState.mathDifficulty + 1 : prevState.mathDifficulty - 1 }
    //   });
    // }
    // if (groupname == "BehaviourIssues") {
    //   this.setState(prevState => {
    //     return { behaviourIssues: value == 'Yes' ? prevState.behaviourIssues + 1 : prevState.behaviourIssues - 1 }
    //   });
    // }
    this.setState((prevState) => ({ childPreAssessmentData: { ...prevState.childPreAssessmentData, [name]: value } }))
    this.setState({ showPreAssessment: true })
  }
  onQuestionsClick = async (record) => {
    var childData = record;
    var childId = encodeURIComponent(record.token)
    var childQuesRes = await getChildDocuments(childId)
    if (childQuesRes.businessRules.length === 0) {
      var quesdoc = childQuesRes.documents;
      this.setState({ showQuestions: true, questionsData: quesdoc, childData: childData })
    }
    else {
      Modal.error({
        title: 'Error Message',
        content: childQuesRes.businessRules[0].description
      })
    }
  }
  onUploadClick = async (record) => {
    var childData = this.state.childData;
    var childId = encodeURIComponent(childData.token);
    var childQuesRes = await getChildDocuments(childId);
    if (childQuesRes.businessRules.length === 0) {
      var quesdoc = childQuesRes.documents;
      this.setState({ showUploadAnswer: true, questionsData: quesdoc, childData: childData, languageData: record })
    }
    else {
      Modal.error({
        title: 'Error Message',
        content: childQuesRes.businessRules[0].description
      })
    }
  }
  onSelectLanguageChange = (e, value) => {
    var languaguedata = [];
    languaguedata.Url = value.dataRef.url;
    languaguedata.BaseDocumentToken = value.key;
    this.setState({ languageData: languaguedata })
  }
  uploadAnswerChange = (info) => {
    var file = info.file;
    var documenttype = "";
    let uploadedFiles = this.state.uploadedFiles;
    uploadedFiles = [];
    if (info.file.status === 'done') {
      let reader = new FileReader();
      reader.onload = (e) => {
        const text = (e.target.result);
        if (file.type === "application/pdf") {
          documenttype = "PDF"
        }
        else if (file.type === "text/plain") {
          documenttype = "TXT"
        }
        else {
          documenttype = "JPG"
        }
        uploadedFiles.push({
          DocumentName: file.name,
          DocumentType: documenttype,
          dataContent: file,
          EncodedDocument: text,
        })
        this.setState({ uploadedFiles });
      }
      reader.readAsDataURL(file.originFileObj);

    }
    else if (info.file.status === 'removed') {
      this.setState({ uploadedFiles })
    }
  }
  dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  onSaveUploadAnswer = async () => {
    this.setState({
      loading: true
    })
    var language = this.state.languageData;
    var uploadeddata = this.state.uploadedFiles;
    var childID = this.state.childData.token;
    var userName = this.state.userName;
    var encodedData = uploadeddata[0].EncodedDocument.replace(/^data:.+;base64,/, '');
    var uploadRequestData = []
    var uploadRequest = {
      EncodedDocument: encodedData,
      ChildInfoToken: childID,
      DocumentName: uploadeddata[0].DocumentName,
      DocumentType: uploadeddata[0].DocumentType,
      ChangedBy: userName,
      DocumentTypeId: parseInt("0"),
      FilePathUrl: "",
      BaseDocumentUrl: language.url,
      BaseDocumentToken: language.baseDocumentToken,
    }
    uploadRequestData.push(uploadRequest)
    var uploadDocRes = await saveChildDocuments(uploadRequestData)
    if (uploadDocRes.businessRules.length === 0) {
      this.setState({ showUploadAnswer: false, loading: false })
      Modal.success({
        content: 'Answer Uploaded Successfully!',
      });
    }
    else {
      Modal.error({
        title: 'Error Message',
        content: uploadDocRes.businessRules[0].description
      })
    }
  }
  onAddChildClick = async () => {
    let infodetails = await GetChildAdditionSeedInfo()
    this.setState({ showAddChild: true, childSeedInfo: infodetails, addChildTitle: 'Add Child', addNewChildData: [] })
  }
  onCancelAddChild = () => {
    this.setState({ showAddChild: false })
  }
  onAddNewChildChange = async (e) => {
    var inputnameValue = await validateName(e.target.value);
    var name = e.target.name;
    var value = inputnameValue;
    this.setState((prevState) => ({ addNewChildData: { ...prevState.addNewChildData, [name]: value } }))
  }

  onAddChildSelectChange = (val, e) => {
    if (val === "Date") {
      if (e !== null) {
        var dateObject = new Date(e._d);
        var dob = moment(dateObject).format('YYYY-MM-DD');
        // var dateMomentObject = moment(dateObject).format('YYYY-MM-DD');
        // newChildData.dob = dateMomentObject;
        var value = "dob"
        this.setState((prevState) => ({ addNewChildData: { ...prevState.addNewChildData, [value]: dob } }))
      }
    }
    else if (val === "timeslot") {
      var startTime = "";
      var endTime = "";
      if (e === null) {
        startTime = null;
        endTime = null;
      }
      else {
        var start_time = moment(e[0]._d).format('HH:mm');
        var end_time = moment(e[1]._d).format('HH:mm');
        if (start_time > end_time) {
          this.formRef.current.setFieldsValue({
            prefferedSlot: []
          });
          Modal.error({
            title: 'Error Message',
            content: 'End time must be greater than the start time'
          })
        }
        else {
          startTime = start_time;
          endTime = end_time;
        }
      }
      var value1 = "prefferedStartTime"
      var valueEnd = "prefferedEndTime"
      this.setState((prevState) => ({ addNewChildData: { ...prevState.addNewChildData, [value1]: startTime } }))
      this.setState((prevState) => ({ addNewChildData: { ...prevState.addNewChildData, [valueEnd]: endTime } }))
    }
    else {
      this.setState((prevState) => ({ addNewChildData: { ...prevState.addNewChildData, [val]: e } }))
    }
  }

  SaveAddChild = async () => {
    var start_time = new Date("March 20, 2022 " + this.state.addNewChildData['prefferedStartTime']);
    var end_time = new Date("March 20, 2022 " + this.state.addNewChildData['prefferedEndTime']);
    var time = new Date("March 20, 2022 " + this.state.addNewChildData['prefferedStartTime']);
    time.setMinutes(time.getMinutes() + 30);
    if (this.state.addNewChildData['prefferedStartTime'] === this.state.addNewChildData['prefferedEndTime']) {
      if ((this.state.addNewChildData['prefferedStartTime'] === undefined) && (this.state.addNewChildData['prefferedEndTime'] === undefined)) {
        this.formRef.current.setFieldsValue({
          prefferedSlot: []
        });
      }
      else {
        return Modal.error({
          title: 'Error Message',
          content: 'Please select different start time and end time'
        })
      }
    }
    else if (start_time > end_time) {
      return Modal.error({
        title: 'Error Message',
        content: 'End time must be greater than the start time'
      })
    }
    else if (time > end_time) {
      return Modal.error({
        title: 'Error Message',
        content: 'Minimum 30 mins duration between start time and end time'
      })
    }
    if (this.state.addNewChildData['prefferedStartTime'] != this.state.addNewChildData['prefferedEndTime']) {
      if (this.state.addChildTitle === 'Add Child') {
        var userDetails = this.state.userdetails;
        var childseedinfo = this.state.childSeedInfo;
        var classList = childseedinfo.classes;
        var schoolList = childseedinfo.schoolInfos;
        var newChildData = this.state.addNewChildData;
        var childName = newChildData.firstName + newChildData.lastName;
        var className = classList.filter((item) => item.classId === newChildData.classId);
        var schoolName = schoolList.filter((item) => item.schoolId === newChildData.schoolId);
        if (this.state.userType === 4) {
          this.AddChild();
        }
        else {
          var today = new Date();
          var todayDate = moment(today).format('YYYY/MM/DD');
          let data = await ConsentTemplate();
          data = data.replaceAll("@ParentName", userDetails.name).replaceAll("@Address", userDetails.address).replaceAll("@CurrentPlace", userDetails.city)
            .replaceAll("@TodayDate", todayDate).replaceAll("@ChildName", childName).replaceAll("@ClassName", className[0].name).replaceAll("@SchoolName", schoolName[0].name);
          this.setState({ showConsentForm: true, consentFormData: data, consentLoading: false })
        }
      }
      else if (this.state.addChildTitle === 'Edit Child') {
        debugger;
        var userDetails = this.state.userdetails;
        var childseedinfo = this.state.childSeedInfo;
        let newChildData = this.state.addNewChildData;
        var classList = childseedinfo.classes;
        var className = classList.filter((item) => item.classId === newChildData.classId);
        var userName = this.state.userName;
        newChildData["className"] = className[0].name;
        newChildData["changedBy"] = userName;
        newChildData["userInfoToken"] = userDetails.token;
        // var dateObject = new Date(newChildData.dob);
        // var dateMomentObject = moment(dateObject).format('YYYY-MM-DD');
        // newChildData.dob = dateMomentObject;
        let editedChildResponse = await UpdateChildInfo(newChildData)

        if (editedChildResponse.businessRules.length === 0) {
          if (editedChildResponse.success === true) {
            let cildResponse = await getChildInfoResponse(userDetails.token);
            var dataSource = [];
            if (cildResponse.success == true) {
              let infodetails = await GetChildAdditionSeedInfo()
              var schooldetail = infodetails.schoolInfos.filter(value => value.schoolId === newChildData.schoolId)
              if (schooldetail[0].schoolId !== 2) {
                sessionStorage.setItem("SchoolName", schooldetail[0].name);
                sessionStorage.setItem("SchoolId", schooldetail[0].schoolId);
              }
              else {
                sessionStorage.setItem("SchoolName", "");
                sessionStorage.setItem("SchoolId", "");
              }
              sessionStorage.setItem("ChildD", newChildData.token);
              sessionStorage.setItem("ChildFirstName", newChildData.firstName);
              sessionStorage.setItem("ChildLastName", newChildData.lastName);
              sessionStorage.setItem("ChildDOB", newChildData.dob);
              sessionStorage.setItem("AddChild", "true");
              dataSource = cildResponse.childInfos
              this.setState({
                childInfos: dataSource,
              })
            }
            this.setState({ showConsentForm: false, showAddChild: false })
            Modal.success({
              content: "Child Updated Successfully!"
            })
          }
          else {
            Modal.error({
              title: 'Error Message',
              content: 'Issue in Editing Child!'
            })
          }
        }
        else {
          Modal.error({
            title: 'Error Message',
            content: editedChildResponse.businessRules[0].description
          })
        }
      }
    }
  }

  onCancelConsentForm = () => {
    this.setState({ showConsentForm: false })
  }

  onChkterms = (e) => {
    var checked = e.target.checked;
    this.setState({ termsChecked: checked, showConsentForm: true })
  }

  onSubmitConsentForm = async () => {
    if (this.state.termsChecked === true) {
      this.setState({ consentLoading: true })
      this.AddChild();
      this.setState({ consentLoading: false })
    }
    else {
      Modal.error({
        title: 'Error Message',
        content: 'Please Agree to the Above Consent Form!'
      })
    }
  }
  AddChild = async () => {
    var userDetails = this.state.userdetails;
    var childseedinfo = this.state.childSeedInfo;
    let newChildData = this.state.addNewChildData;
    var classList = childseedinfo.classes;
    var className = classList.filter((item) => item.classId === newChildData.classId);
    var userName = this.state.userName;
    newChildData["className"] = className[0].name;
    newChildData["changedBy"] = userName;
    newChildData["userInfoToken"] = userDetails.token;
    // const [day, month, year] = newChildData.dob.split('-');
    // const resultdob = [year, month, day,].join('-');
    // newChildData["dob"] = resultdob;
    let addChildResponse = await AddChildInfo(newChildData)
    if (addChildResponse.businessRules.length === 0) {
      if (addChildResponse.success === true) {
        let cildResponse = await getChildInfoResponse(userDetails.token);
        var dataSource = [];
        if (cildResponse.success == true) {
          dataSource = cildResponse.childInfos
          this.setState({
            childInfos: dataSource,
          })
        }
        var childDet = dataSource.filter((item) => item.token === addChildResponse.token);
        let infodetails = await GetChildAdditionSeedInfo()
        var schooldetail = infodetails.schoolInfos.filter(value => value.schoolId === newChildData.schoolId)
        if (schooldetail[0].schoolId !== 2) {
          sessionStorage.setItem("SchoolName", schooldetail[0].name);
          sessionStorage.setItem("SchoolId", schooldetail[0].schoolId);
        }
        else {
          sessionStorage.setItem("SchoolName", "");
          sessionStorage.setItem("SchoolId", "");
        }
        sessionStorage.setItem("ChildD", addChildResponse.token);
        sessionStorage.setItem("ChildFirstName", newChildData.firstName);
        sessionStorage.setItem("ChildLastName", newChildData.lastName);
        sessionStorage.setItem("ChildDOB", newChildData.dob);
        sessionStorage.setItem("ChildMRD", childDet[0].childMRD);
        sessionStorage.setItem("AddChild", "true");
        var user = this.state.userName;
        sessionStorage.setItem("userName", user);
        this.setState({ showConsentForm: false, showAddChild: false })
        Modal.success({
          content: "Child Added Successfully! \n Please Fill this form to Take Pre-Assessment!"
        })
        this.props.history.push('./CaseHistory')
      }
      else {
        Modal.error({
          title: 'Error Message',
          content: 'Issue in Adding Child!'
        })
      }
    }
    else {
      this.setState({ consentLoading: false })
      Modal.error({
        title: 'Error Message',
        content: addChildResponse.businessRules[0].description
      })
    }
  }
  onEditChild = async (record) => {
    debugger;
    var dateObject = new Date(record.dob);
    var dateMomentObject = moment(dateObject).format('YYYY-MM-DD');
    record.dob = dateMomentObject;
    let infodetails = await GetChildAdditionSeedInfo()
    this.setState({ showAddChild: true, childSeedInfo: infodetails, addNewChildData: record, addChildTitle: 'Edit Child' })
  }

  onEditChildProile = async (record) => {
    let infodetails = await GetChildAdditionSeedInfo()
    var schooldetail = infodetails.schoolInfos.filter(value => value.schoolId === record.schoolId)
    if (schooldetail[0].schoolId !== 2) {
      sessionStorage.setItem("SchoolName", schooldetail[0].name);
      sessionStorage.setItem("SchoolId", schooldetail[0].schoolId);
    }
    else {
      sessionStorage.setItem("SchoolName", "");
      sessionStorage.setItem("SchoolId", "");
    }
    sessionStorage.setItem("ChildD", record.token);
    sessionStorage.setItem("ChildFirstName", record.firstName);
    sessionStorage.setItem("ChildLastName", record.lastName);
    sessionStorage.setItem("ChildDOB", record.dob);
    sessionStorage.setItem("ChildMRD", record.childMRD);
    sessionStorage.setItem("AddChild", "false");
    var user = this.state.userName;
    sessionStorage.setItem("userName", user);
    this.props.history.push('./CaseHistory')
  }

  SLDPaymentCLick = () => {
    var childData = this.state.childData;
    this.setState({ showPaymentMethod: true, transactionId: '' })
  }

  onNeftPaymentClick = () => {
    this.setState({ showNEFTPayment: true })
  }

  onNeftRegPaymentClick = () => {
    this.setState({ showNEFTPayment: true, RegisterPaymentNeft: true })
  }

  onInputTransactionChange = (e) => {
    var transId = e.target.value;
    this.setState({ transactionId: transId, showNEFTPayment: true })
  }

  onPaymentCancel = () => {
    this.setState({ showPaymentMethod: false, RegisterPaymentNeft: false })
  }

  onNEFTPaymentCancel = () => {
    this.setState({ showNEFTPayment: false, RegisterPaymentNeft: false })
  }

  onNEFTRegPaymentCancel = async () => {
    var userID = this.props.userDetailsJson.token;
    this.setState({ regPaymentDone: true, regPaymentNotDone: false, RegisterPaymentNeft: false })

    // let paymentResponse = await getPaymentType(userID);
    // if (paymentResponse.length > 0) {
    //   var Paymentsuccess = paymentResponse.filter((item) => ((item.paymentType === "Register") || (item.paymentType === "NEFT Register")));
    //   if (Paymentsuccess.length > 0) {
    //     this.setState({ regPaymentDone: true, regPaymentNotDone: false, RegisterPaymentNeft: false })
    //   }
    //   else {
    //     this.setState({ regPaymentDone: false, regPaymentNotDone: true, RegisterPaymentNeft: false })
    //   }
    // }
    // else {
    //   this.setState({ regPaymentDone: false, regPaymentNotDone: true, RegisterPaymentNeft: false })
    // }
  }

  onNeftTransactionClick = async () => {
    if (this.state.transactionId !== '') {
      this.setState({ neftpaymentLoading: true })
      var childData = this.state.childData;
      var userdetail = this.state.userdetails;
      var userInfoDetails = {
        Email: userdetail.email,
        Mobile: userdetail.mobileNo,
        Amount: 1
      }
      var userid = this.props.userDetailsJson.token;
      var id = decodeURIComponent(userid);
      let userResponse = await GetUserInfo(userInfoDetails)
      if (userResponse.businessRules.length === 0) {
        var transid = this.state.transactionId
        var dataResponse = {
          transactionId: transid
        }
        var Paymenttype = "";
        var childID = childData.token
        if (this.state.RegisterPaymentNeft === true) {
          Paymenttype = "NEFT Register"
        }
        else {
          Paymenttype = "NEFT SLD Assessment";
        }
        var paymentRequest = {
          PaymentProviderId: 'E6F92A58-A1F0-4194-80CA-DE551DF7683D',
          Response: JSON.stringify(dataResponse),
          ChangedBy: userResponse.firstName,
          ChildId: childID,
          UserId: userid,
          PaymentType: Paymenttype,
          ChildName: childData.firstName,
        }
        let NEFTPayResponse = await savepaymentresponse(paymentRequest);
        if (NEFTPayResponse.businessRules.length === 0) {
          this.setState({ showNEFTPayment: false, RegisterPaymentNeft: false, showPaymentMethod: false, showPreAssessmentresult: false, neftpaymentLoading: false, regPaymentDone: true, regPaymentNotDone: false })
          Modal.success({
            title: 'Payment Success',
            content: 'Payment Done Successfully!'
          })
        }
        else {
          Modal.error({
            title: 'Error Message',
            content: userResponse.businessRules[0].description
          })
        }
      }
      else {
        Modal.error({
          title: 'Error Message',
          content: userResponse.businessRules[0].description
        })
      }
    }
    else {
      Modal.error({
        title: 'Error Message',
        content: "Enter TransactionID"
      })
    }
    var userID = this.props.userDetailsJson.token;
    let childResponse = await getChildInfoResponse(userID);
    let paymentResponse = await getPaymentType(userID);
    if (paymentResponse.length > 0) {
      var dataSource = [];
      if (childResponse.success == true) {
        dataSource = childResponse.childInfos
        this.setState({
          childInfos: dataSource, paymentResponsedata: paymentResponse
        })
      }
      else {
        Modal.error({
          title: 'Error Message',
          content: paymentResponse.businessRules[0].description
        })
      }
    }
  }

  onSubmitOtherPaymentmethod = async () => {
    var userdetail = this.state.userdetails;
    var childData = this.state.childData;
    var userInfoDetails = {
      Email: userdetail.email,
      Mobile: userdetail.mobileNo,
      Amount: 250
    }
    let userResponse = await GetUserInfo(userInfoDetails)
    if (userResponse.businessRules.length === 0) {
      var items = [];
      var orderItems = {
        priceId: parseInt("250"),
        amount: userInfoDetails.Amount
      }
      items.push(orderItems);
      var cartSessionData = {
        User: userResponse,
        Items: items,
        ChangedBy: userResponse.firstName
      }
      var cartSession = {
        CartSesionData: cartSessionData,
        ChangedBy: userResponse.firstName
      }
      let sesionResponse = await SaveCartSession(cartSession);
      var sessionIdResponse = sesionResponse.sessionData.sessionId;
      let preProcessresponse = await PreProcessSessionOrder(sessionIdResponse);
      if (preProcessresponse.businessRules.length === 0) {
        var paymentData = {
          ProviderId: preProcessresponse.paymentProviders[0].providerId,
          ProviderName: preProcessresponse.paymentProviders[0].providerName,
          PaymentOrderId: preProcessresponse.paymentProviders[0].providerOrderCode,
          ClientKey: preProcessresponse.paymentProviders[0].clientKey,

          OrderAmount: preProcessresponse.order.totalAmount * 100,
          ItemType: "SLD Assessment",
          UserId: preProcessresponse.userId,
          ChildId: childData.token,
          ChildName: childData.firstName,
          FirstName: preProcessresponse.order.user.firstName,
          EMail: preProcessresponse.order.user.email,
          MobileNo: preProcessresponse.order.user.mobileNo,
          Price: preProcessresponse.order.items[0].amount,
        }
        var providerIdResponse = paymentData.ProviderId;
        this.setState({
          paymentProviderdetails: paymentData, sessionId: sessionIdResponse, providerId: providerIdResponse, showConsentForm: false
        })
        var razorPayResponse = await openPayModal(sessionIdResponse, paymentData);
        if (razorPayResponse) {

        }
      }
      else {
        Modal.error({
          title: 'Error Message',
          content: preProcessresponse.businessRules[0].description
        })
      }
    }
    else {
      Modal.error({
        title: 'Error Message',
        content: userResponse.businessRules[0].description
      })
    }
  }

  onSubmitRegisterOtherPaymentmethod = async () => {
    var userdetail = this.state.userdetails;
    var userInfoDetails = {
      Email: userdetail.email,
      Mobile: userdetail.mobileNo,
      Amount: 100
    }
    var childData = this.state.childData;
    let userResponse = await GetUserInfo(userInfoDetails)
    if (userResponse.businessRules.length === 0) {
      var items = [];
      var orderItems = {
        priceId: parseInt("100"),
        amount: userInfoDetails.Amount
      }
      items.push(orderItems);
      var cartSessionData = {
        User: userResponse,
        Items: items,
        ChangedBy: userResponse.firstName
      }
      var cartSession = {
        CartSesionData: cartSessionData,
        ChangedBy: userResponse.firstName
      }
      let sesionResponse = await SaveCartSession(cartSession);
      var sessionIdResponse = sesionResponse.sessionData.sessionId;
      let preProcessresponse = await PreProcessSessionOrder(sessionIdResponse);
      if (preProcessresponse.businessRules.length === 0) {
        var paymentData = {
          ProviderId: preProcessresponse.paymentProviders[0].providerId,
          ProviderName: preProcessresponse.paymentProviders[0].providerName,
          PaymentOrderId: preProcessresponse.paymentProviders[0].providerOrderCode,
          ClientKey: preProcessresponse.paymentProviders[0].clientKey,

          OrderAmount: preProcessresponse.order.totalAmount * 100,
          ItemType: "Register",
          UserId: preProcessresponse.userId,
          ChildId: childData.token,
          FirstName: preProcessresponse.order.user.firstName,
          EMail: preProcessresponse.order.user.email,
          MobileNo: preProcessresponse.order.user.mobileNo,
          Price: preProcessresponse.order.items[0].amount,
        }
        var providerIdResponse = paymentData.ProviderId;
        this.setState({
          paymentProviderdetails: paymentData, sessionId: sessionIdResponse, providerId: providerIdResponse, showConsentForm: false
        })
        let razorPayResponse = await openPayModal(sessionIdResponse, paymentData);
      }
      else {
        Modal.error({
          title: 'Error Message',
          content: preProcessresponse.businessRules[0].description
        })
      }
    }
    else {
      Modal.error({
        title: 'Error Message',
        content: userResponse.businessRules[0].description
      })
    }
  }

  onVideoCallRequest = () => {
  }
  disabledHours = () => {
    const hours = this.range(0, 60);
    hours.splice(20, 4);
    return hours;
  }
  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  getFile = (e) => {
    debugger;
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }
  onConsentForm = async (record) => {
    this.setState({ showUploadConsentForm: true, addNewChildData: record })
  }
  onCancelUploadConsentForm = () => {
    this.setState({ showUploadConsentForm: false })
  }
  onSaveUploadConsentForm = async () => {
    debugger;
    const fileData = this.state.fileData;
    var childId=this.state.addNewChildData.token;
    var userName = this.state.userName;

    if ((fileData.hostedPath != '') && (fileData.hostedPath !== undefined)) {
        var fileResponse = await SaveConsentFormDocument(childId,fileData.hostedPath,userName);
        if (fileResponse.success) {
            Modal.success({ title: 'Success', content: 'File Upload Successfully' });
            this.setState({ showUploadConsentForm: false,fileData:[]})
        }
    }
    else {
         if ((fileData.hostedPath == '') || (fileData.hostedPath === undefined)) {
            Modal.error({ title: 'Error ', content: 'Select any one file to upload!' });
        }
    }
  }
  // uploadConsentForm = (info) => {
  //   var file = info.file;
  //   var documenttype = "";
  //   let uploadedFiles = this.state.consentFormUploadedFiles;
  //   uploadedFiles = [];
  //   if (info.file.status === 'done') {
  //     let reader = new FileReader();
  //     reader.onload = (e) => {
  //       const text = (e.target.result);
  //       documenttype = "JPG"
  //       uploadedFiles.push({
  //         DocumentName: file.name,
  //         DocumentType: documenttype,
  //         dataContent: file,
  //         EncodedDocument: text,
  //       })
  //       this.setState({ consentFormUploadedFiles: uploadedFiles });
  //     }
  //     reader.readAsDataURL(file.originFileObj);

  //   }
  //   else if (info.file.status === 'removed') {
  //     this.setState({ consentFormUploadedFiles: uploadedFiles })
  //   }
  // }
  customRequest = async (options,onSuccess ) => {
    debugger;
    this.setState({ consentFormLoading: true })
    const fileData = this.state.fileData;
    var ReturnedDetails = []
    const data = new FormData()
    data.append('body', options.file)
    const config = {
      method: "POST",
      body: data,
    };
    await fetch(ServiceUrl + 'DepositConsentForm', config) //https://localhost:44351/api/DepositQuestion
      .then(response => {
        if (!response.ok) { throw response }
        return response.json()  //we only get here if there is no error
      })
      .then((responseJson) => {
        ReturnedDetails = responseJson;
      });
    if (ReturnedDetails.success) {
      this.setState({ consentFormLoading: false })
      fileData.hostedPath = ReturnedDetails.hostedPath
      fileData.fileName = ReturnedDetails.fileName;
      this.setState({ fileData: fileData })
      // message.success("Successfully Uploaded")
      setTimeout(() => {
        options.onSuccess("ok");
      }, 0);
    }
    else {
      this.setState({ consentFormLoading: false })
      message.error(ReturnedDetails.error)
    }
    this.setState({ docUpload: false })
  }

  render() {
    const format = 'YYYY-MM-DD';
    const columns = [
      {
        title: 'Edit Child Details',
        dataIndex: 'operation',
        width: '15%', align: 'center',
        render: (text, record) =>
          this.state.childInfos.length >= 1 ? (
            <Space size="middle">
              <a onClick={() => this.onEditChild(record)}><i style={{ fontSize: '25px' }} className="fa fa-edit"></i></a>
            </Space>) : null,
      },
      {
        title: 'Name', width: '15%',
        dataIndex: 'firstName',
        render: (text, record) =>
          this.state.childInfos.length >= 1 ?
            record.profile == true ?
              record.ReadingStatus == 2 && record.writingStatus == 2 ?
                <Button type="link" onClick={() => this.onEditChildProile(record)}><i style={{ color: '#89d700 ' }} class="fa fa-user p-r-7"></i> <span style={{ color: '#89d700 ' }} >{record.firstName} {record.lastName}</span></Button>
                : record.ReadingStatus == 0 && record.writingStatus == 0 ?
                  <Button type="link" onClick={() => this.onEditChildProile(record)}><i style={{ color: 'saddlebrown ' }} class="fa fa-user p-r-7"></i><span style={{ color: 'saddlebrown ' }}>{record.firstName} {record.lastName}</span> </Button>
                  : <Button type="link" onClick={() => this.onEditChildProile(record)}><i style={{ color: 'deepskyblue ' }} class="fa fa-user p-r-7"></i> <span style={{ color: 'deepskyblue ' }}>{record.firstName} {record.lastName}</span></Button>
              : <Button danger type="link" onClick={() => this.onEditChildProile(record)}><i style={{ color: '#ff4d4f ' }} class="fa fa-user p-r-7"></i><span style={{ color: '#ff4d4f ' }}>{record.firstName} {record.lastName}</span></Button>
            : null,
      },
      {
        title: 'DOB',
        dataIndex: 'dob', width: '10%',
        render: (text, record) => { return (<p>{moment(record.dob).format('DD-MM-YYYY')}</p>) },
      },
      {
        title: 'Class',
        dataIndex: 'className', width: '8%',
      },
      {
        title: 'Consent Form',
        dataIndex: 'ConsentForm', width: '10%',
        render: (text, record) => this.state.childInfos.length >= 1 ? (
          <Button type="primary" onClick={() => this.onConsentForm(record)}>Consent Form</Button>
        ) : null,
      },
      {
        title: 'Pre-Assessment',
        dataIndex: 'operation',
        width: '10%', align: 'center',
        render: (text, record) =>
          this.state.childInfos.length >= 1 ? (
            record.profile == true ?
              <Button type="primary" onClick={() => this.onPreAssessment(record)}>Pre-Assessment</Button> : <Button disabled>Pre-Assessment</Button>
          ) : null,
      },
      // {
      //   title: 'Video Request',
      //   dataIndex: 'operation',
      //   width: '10%', align: 'center',
      //   render: (text, record) =>
      //     this.state.childInfos.length >= 1 ? (
      //       record.profile == true ?
      //         record.paymentType === null ? <Button type="primary" disabled onClick={() => this.onVideoCallRequest(record)} >Make Request</Button> :
      //           record.paymentType === "SLD Assessment" ? <Button type="primary" onClick={() => this.onVideoCallRequest(record)} >Make Request</Button> :
      //             record.paymentType === "NEFT SLD Assessment" ? <Button type="primary" onClick={() => this.onVideoCallRequest(record)} >Make Request</Button> :
      //               <Button type="primary" disabled onClick={() => this.onVideoCallRequest(record)} >Make Request</Button> : null
      //     ) : null,
      // }, 
      {
        title: 'Take Assessment',
        dataIndex: 'operation',
        width: '10%', align: 'center',
        render: (text, record) =>
          this.state.childInfos.length >= 1 ? (
            record.profile == true ?
              record.paymentType === null ? <Button type="primary" disabled onClick={() => this.onQuestionsClick(record)}>Take Assessment</Button> :
                record.paymentType === "SLD Assessment" ? <Button type="primary" onClick={() => this.onQuestionsClick(record)}>Take Assessment</Button> :
                  record.paymentType === "NEFT SLD Assessment" ? <Button type="primary" onClick={() => this.onQuestionsClick(record)}>Take Assessment</Button> :
                    record.paymentType === "Free Access" ? <Button type="primary" onClick={() => this.onQuestionsClick(record)}>Take Assessment</Button> :
                      <Button type="primary" disabled onClick={() => this.onQuestionsClick(record)}>Take Assessment</Button> : null
          ) : null,
      }
      // {
      //   title: 'Upload Answers',
      //   dataIndex: 'operation',
      //   width: '10%', align: 'center',
      //   render: (text, record) =>
      //     this.state.childInfos.length >= 1 ? (
      //       record.profile == true ?
      //         record.paymentType === null ? <Button type="primary" disabled onClick={() => this.onUploadClick(record)} >Upload Answers</Button> :
      //           record.paymentType === "SLD Assessment" ? <Button type="primary" onClick={() => this.onUploadClick(record)} >Upload Answers</Button> :
      //             record.paymentType === "NEFT SLD Assessment" ? <Button type="primary" onClick={() => this.onUploadClick(record)} >Upload Answers</Button> :
      //               <Button type="primary" disabled onClick={() => this.onUploadClick(record)} >Upload Answers</Button> : null
      //     ) : null,
      // },
      // {
      //   title: 'Questions',
      //   dataIndex: 'operation',
      //   width: '10%', align: 'center',
      //   render: (text, record) =>
      //     this.state.childInfos.length >= 1 ? (
      //       record.profile == true ?
      //         record.paymentType === null ? <Button type="primary" disabled onClick={() => this.onQuestionsClick(record)}>Questions</Button> :
      //           record.paymentType === "SLD Assessment" ? <Button type="primary" onClick={() => this.onQuestionsClick(record)}>Questions</Button> :
      //             record.paymentType === "NEFT SLD Assessment" ? <Button type="primary" onClick={() => this.onQuestionsClick(record)}>Questions</Button> :
      //               <Button type="primary" disabled onClick={() => this.onQuestionsClick(record)}>Questions</Button> : null
      //     ) : null,
      // },
    ];
    const questionColumns = [
      {
        title: 'Questions',
        dataIndex: 'name', align: 'left', width: '15%',
        render: (text, record) =>
          this.state.questionsData.length >= 1 ? (
            <h5><b>{record.name}</b></h5>
          ) : null,
      },
      {
        title: 'Download',
        dataIndex: 'operation',
        width: '10%', align: 'center',
        render: (text, record) =>
          this.state.questionsData.length >= 1 ? (
            <Space size="middle">
              <a download={record.url} target="_blank" href={record.url}><i className="fa fa-download"></i></a>
            </Space>) : null,
      },
      {
        title: 'Upload Answers',
        dataIndex: 'operation',
        width: '15%', align: 'center',
        render: (text, record) =>
          this.state.questionsData.length >= 1 ? (
            <Space size="middle">
              <Button type="link" onClick={() => this.onUploadClick(record)}  ><i className="fa fa-upload"></i></Button>
            </Space>) : null,
      }
    ];
    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
    const drawerformlayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
    const preAssessmentTestResult = () => {
      return (
        <tbody>
          <tr>
            <td>Reading Difficulty</td>
            <td style={{ textAlign: 'center' }}>
              {this.state.readingFeatures == true &&
                <i class="fa fa-check"></i>
              }
            </td>
            <td style={{ textAlign: 'center' }}>
              {this.state.readingPresence == true &&
                <i class="fa fa-check"></i>
              }
            </td>
          </tr>
          <tr>
            <td>Writing Difficulty</td>
            <td style={{ textAlign: 'center' }}>
              {this.state.writingFeatures == true &&
                <i class="fa fa-check"></i>
              }
            </td>
            <td style={{ textAlign: 'center' }}>
              {this.state.writingPresence == true &&
                <i class="fa fa-check"></i>
              }
            </td>
          </tr>
          <tr>
            <td>Spelling Difficulty</td>
            <td style={{ textAlign: 'center' }}>
              {this.state.spellingFeatures &&
                <i class="fa fa-check"></i>
              }
            </td>
            <td style={{ textAlign: 'center' }}>
              {this.state.spellingPresence &&
                <i class="fa fa-check"></i>
              }
            </td>
          </tr>
          <tr>
            <td>Mathematical Difficulty</td>
            <td style={{ textAlign: 'center' }}>
              {this.state.mathFeatures &&
                <i class="fa fa-check"></i>
              }
            </td>
            <td style={{ textAlign: 'center' }}>
              {this.state.mathPresence &&
                <i class="fa fa-check"></i>
              }
            </td>
          </tr>
          <tr>
            <td>Behavioural Issues</td>
            <td style={{ textAlign: 'center' }}>
              {this.state.behaviourFeatures &&
                <i class="fa fa-check"></i>
              }
            </td>
            <td style={{ textAlign: 'center' }}>
              {this.state.behaviourPresence &&
                <i class="fa fa-check"></i>
              }
            </td>
          </tr>
        </tbody>);
    }
    const rangeConfig = {
      rules: [
        {
          type: 'array',
          required: true,
          message: 'Please select Preferred Time!',
        },
      ],
    };
    return (
      <div>
        <Header></Header>
        <div style={{ minHeight: 'calc(100vh - 231px)' }}>
          {/* {this.state.regPaymentDone && */}
          <div>
            <div style={{ float: 'right', padding: '5px 15px 5px 5px' }}>
              <Button type="primary" onClick={() => this.onAddChildClick()} >Add Child</Button>
            </div>
            <div style={{ padding: '8px' }}>
              <Table dataSource={this.state.childInfos} columns={this.state.consentFormShow
                ? columns : columns.filter(ea => ea.dataIndex !== "ConsentForm")} />
              {this.state.showPreAssessment &&
                <Modal className="modal-custom-top preassessment-hg"
                  title="Pre-Assessment" width={1000} maskClosable={false}
                  visible={this.state.showPreAssessment}
                  onCancel={() => this.onCancel("preAssessment")}
                  footer={[
                    <Button onClick={() => this.onCancel("preAssessment")}>Cancel</Button>,
                    <Button type="primary" onClick={() => this.onSkipPreassessDetails()}>Skip</Button>,
                    <Button type="primary" onClick={() => this.onSavePreassessDetails()}>Submit</Button>
                  ]}>

                  <table class="table table-bordered table-striped mb-0" id="table">
                    <tbody>
                      <tr>
                        <td>1.Tend to avoid reading.</td>
                        <td>
                          <Radio.Group id='AvoidReading' name='AvoidReading' onChange={e => this.onChangePreAssessment(e, "ReadingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td><p>2.Slow in reading.</p></td>
                        <td>
                          <Radio.Group id='SlowReading' name='SlowReading' onChange={e => this.onChangePreAssessment(e, "ReadingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>3.Lacks accuracy while reading. Eg., <b>where</b> as <b>were</b>, <b>house</b> as <b>hose</b>. </td>
                        <td>
                          <Radio.Group id='LackAccuracy' name='LackAccuracy' onChange={e => this.onChangePreAssessment(e, "ReadingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>4.Reads words/ sentences without understanding.</td>
                        <td>
                          <Radio.Group id='ReadWords' name='ReadWords' onChange={e => this.onChangePreAssessment(e, "ReadingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>5.Dislikes/ avoids writing and copying</td>
                        <td>
                          <Radio.Group id='DislikesWriting' name='DislikesWriting' onChange={e => this.onChangePreAssessment(e, "WritingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          6.Writing is messy and incomplete with many cross outs and often erasing and
                          writing.
                        </td>
                        <td>
                          <Radio.Group id='MessyWriting' name='MessyWriting' onChange={e => this.onChangePreAssessment(e, "WritingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>7.Makes grammatical errors while forming sentences.</td>
                        <td>
                          <Radio.Group id='GrammaticalErrors' name='GrammaticalErrors' onChange={e => this.onChangePreAssessment(e, "WritingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>8.Has mirror writing of letters/ numbers. Eg. <b>b</b> as <b>d</b>,<b>p</b> as <b>q</b>, <b>12</b> as <b>21</b>.</td>
                        <td>
                          <Radio.Group id='MinorWriting' name='MinorWriting' onChange={e => this.onChangePreAssessment(e, "WritingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>9.Spell a word in different ways.</td>
                        <td>
                          <Radio.Group id='DifferentSpell' name='DifferentSpell' onChange={e => this.onChangePreAssessment(e, "SpellingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>10.Has difficulty to name the letters of the alphabet in sequence.</td>
                        <td>
                          <Radio.Group id='DifficultSpellSequence' name='DifficultSpellSequence' onChange={e => this.onChangePreAssessment(e, "SpellingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>11.Can give the spelling orally but mistakes are encountered while writing it down.</td>
                        <td>
                          <Radio.Group id='MistakesWhileWriting' name='MistakesWhileWriting' onChange={e => this.onChangePreAssessment(e, "SpellingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>12.Makes spelling mistakes. Eg. <b>Bat</b> as <b>But</b>, <b>Chair</b> as <b>Cher</b>.</td>
                        <td>
                          <Radio.Group id='SpellingMistakes' name='SpellingMistakes' onChange={e => this.onChangePreAssessment(e, "SpellingDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>13.Difficulty in understanding and applying the mathematical concepts</td>
                        <td>
                          <Radio.Group id='DifficultUnderstanding' name='DifficultUnderstanding' onChange={e => this.onChangePreAssessment(e, "MathDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>14.Has symbol confusion. Eg. <b>+</b>,<b>-</b>,<b>X</b>,<b>÷</b></td>
                        <td>
                          <Radio.Group id='SymbolConfusion' name='SymbolConfusion' onChange={e => this.onChangePreAssessment(e, "MathDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>15.Has difficulty with comparisons. Eg. Lesser or greater than, more or less.</td>
                        <td>
                          <Radio.Group id='ComparisonDifficulty' name='ComparisonDifficulty' onChange={e => this.onChangePreAssessment(e, "MathDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>16.Has difficulty counting rapidly or making calculations.</td>
                        <td>
                          <Radio.Group id='DifficultyCalculation' name='DifficultyCalculation' onChange={e => this.onChangePreAssessment(e, "MathDifficulty")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>17.Has difficulty in concentration particularly in learning situations.</td>
                        <td>
                          <Radio.Group id='DifficultyConcentration' name='DifficultyConcentration' onChange={e => this.onChangePreAssessment(e, "BehaviourIssues")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>18.Is restless in study situations.</td>
                        <td>
                          <Radio.Group id='RestlessStudy' name='RestlessStudy' onChange={e => this.onChangePreAssessment(e, "BehaviourIssues")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>19.Talkative / disturbs others in class.</td>
                        <td>
                          <Radio.Group id='TalkativeDisturbs' name='TalkativeDisturbs' onChange={e => this.onChangePreAssessment(e, "BehaviourIssues")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                      <tr>
                        <td>20.Gets angry when asked to study.</td>
                        <td>
                          <Radio.Group id='AngryStudy' name='AngryStudy' onChange={e => this.onChangePreAssessment(e, "BehaviourIssues")} buttonStyle="solid">
                            <Radio.Button value="Yes">Yes</Radio.Button>
                            <Radio.Button value="No">No</Radio.Button>
                          </Radio.Group>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Modal>
              }
              {this.state.showQuestions &&
                <Drawer
                  width={500} title="Questions"
                  onClose={() => this.onCancel("Questions")}
                  visible={this.state.showQuestions}
                  maskClosable={false}>
                  <div style={{ padding: '10px' }}>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={24}>
                        <h6 style={{color:'blue'}}>INSTRUCTIONS : Once the child finishes the assessment (all the subjects), the parent should scan each and every page and upload it individually.</h6>
                        <h6 style={{color:'red'}}>NOTE : Please do not assist or help your child to do the Learning Difficulty Assessment. 
                          Your help will mask your child's issue in learning and diagnosis will not be reliable.</h6>
                      </Col>
                    </Row>
                    <Table size='small' dataSource={this.state.questionsData} columns={questionColumns} />
                  </div>
                </Drawer>

                // <Modal className="modal-custom-top preassessment-hg"
                //   title="Questions" visible={this.state.showQuestions}
                //   onCancel={() => this.onCancel()}
                //   footer={false}
                // >
                //   <Table dataSource={this.state.questionsData} columns={questionColumns} />
                // </Modal>
              }
              {this.state.showPreAssessmentresult &&
                <Modal className="modal-custom-top" maskClosable={false}
                  title="Assessment Report" visible={this.state.showPreAssessmentresult}
                  onCancel={() => this.onCancel("PreAssessmentresult")} footer={false}
                >
                  <div style={{ marginBottom: '1%', float: 'right' }}>
                    <Button type="primary" onClick={() => this.PreAssessmentAgain()}>Take Pre-Assessment Again</Button>
                  </div>
                  <table class="table table-bordered mb-0">
                    <thead>
                      <tr>
                        <th></th>
                        <th style={{ textAlign: 'center' }}>Features</th>
                        <th>Presence</th>
                      </tr>
                    </thead>
                    {preAssessmentTestResult()}
                  </table>
                  <div>A detailed assessment on Specific Learning Disability (SLD) will be helpful to know the level and the type of learning disorder.If interested <a style={{ color: 'blue' }} onClick={this.SLDPaymentCLick}>click here to proceed</a></div>
                </Modal>
              }
              {this.state.showUploadAnswer &&
                <Modal className="modal-custom-top" maskClosable={false}
                  title="Upload Answer Sheet" visible={this.state.showUploadAnswer}
                  onCancel={() => this.onCancel("UploadAnswer")}
                  onOk={() => this.onSaveUploadAnswer()}
                >
                  <Spin indicator={<SettingOutlined spin={true} />} tip="Loading..." spinning={this.state.loading}>
                    <Space direction='vertical'>
                      <div>
                        <Row gutter={24}>
                          <Col className="gutter-row" span={10}>
                            <h5>Upload Answer</h5>
                          </Col>
                          <Col className="gutter-row" span={14}>
                            <Upload id="upload_file"  customRequest={this.dummyRequest} onChange={this.uploadAnswerChange} accept='.jpg,.png,.txt,.pdf' maxCount={1}>
                              <Button icon={<UploadOutlined />}>Upload Answer Sheet</Button>
                            </Upload>
                          </Col>
                        </Row>
                      </div>
                    </Space>
                  </Spin>
                </Modal>
              }
              {this.state.showUploadConsentForm &&
                <Modal className="modal-custom-top" maskClosable={false}
                  title="Upload Consent Form" visible={this.state.showUploadConsentForm}
                  onCancel={() => this.onCancelUploadConsentForm()}
                  onOk={() => this.onSaveUploadConsentForm()}
                >
                  <Spin indicator={<SettingOutlined spin={true} />} tip="Loading..." spinning={this.state.consentFormLoading}>
                    <Space direction='vertical'>
                      <div>
                        <Row gutter={24}>
                          <Col className="gutter-row" span={10}>
                            <h5>Upload Consent Form</h5>
                          </Col>
                          <Col className="gutter-row" style={{ lineHeight: '30px' }} span={2}>
                            <a download="https://viriddham.com/ConsentForm/TemplateFilePath/Consentform.pdf" target="_blank" href="https://viriddham.com/ConsentForm/TemplateFilePath/Consentform.pdf"><i className="fa fa-download"></i></a>
                          </Col>
                          <Col className="gutter-row" span={12}>
                            <Upload type='file' maxCount={1} value={this.state.fileData.fileName} customRequest={this.customRequest} accept='.jpg,.png'>
                              <Button type='primary' icon={<UploadOutlined />}  >Upload Consent Form</Button>
                            </Upload>
                          </Col>
                        </Row>
                      </div>
                    </Space>
                  </Spin>
                </Modal>
              }
              {this.state.showAddChild &&
                <Drawer
                  width={500} title={this.state.addChildTitle}
                  onClose={() => this.onCancelAddChild()}
                  visible={this.state.showAddChild}
                  maskClosable={false}>
                  <div>
                    <Form {...drawerformlayout} ref={this.formRef} className="card-footer-right" labelWrap onFinish={() => this.SaveAddChild()}>
                      <Card id="addchild" className="scroll-hide" bordered={false}
                        actions={[
                          <Form.Item style={{ marginBottom: 0 }}>
                            <Space className="pull-right">
                              <Button onClick={() => this.onCancelAddChild()}>Cancel</Button>
                              <Button type="primary" htmlType="submit">Save</Button>
                            </Space>
                          </Form.Item>
                        ]}>
                        <Form.Item name="firstName" initialValue={this.state.addNewChildData.firstName} label="First Name" rules={[{ required: true, message: 'Please enter First Name!' }]}>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={24}>
                              <Input name="firstName" value={this.state.addNewChildData.firstName} onChange={e => this.onAddNewChildChange(e)} />
                            </Col>
                          </Row>
                        </Form.Item>
                        <Form.Item name="lastName" initialValue={this.state.addNewChildData.lastName} label="Last Name" rules={[{ required: true, message: 'Please enter Last name!' }]}>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={24}>
                              <Input name="lastName" value={this.state.addNewChildData.lastName} onChange={e => this.onAddNewChildChange(e)} />
                            </Col>
                          </Row>
                        </Form.Item>
                        {this.state.userType === 4 &&
                          <div>
                            <Form.Item name="parentFirstName" className='form-fixheight' initialValue={this.state.addNewChildData.parentFirstName} label="Parent/Caretaker FirstName" rules={[{ required: true, message: 'Please enter Parent/Caretaker First Name!' }]}>
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={24}>
                                  <Input name="parentFirstName" value={this.state.addNewChildData.parentFirstName} onChange={e => this.onAddNewChildChange(e)} />
                                </Col>
                              </Row>
                            </Form.Item>
                            <Form.Item name="parentLastName" className='form-fixheight' initialValue={this.state.addNewChildData.parentLastName} label="Parent/Caretaker LastName" rules={[{ required: true, message: 'Please enter Parent/Caretaker Last name!' }]}>
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={24}>
                                  <Input name="parentLastName" value={this.state.addNewChildData.parentLastName} onChange={e => this.onAddNewChildChange(e)} />
                                </Col>
                              </Row>
                            </Form.Item>
                          </div>
                        }
                        <Form.Item name="dob" label="DOB" initialValue={this.state.addNewChildData.dob ? this.state.addNewChildData.dob !== '' ? moment(this.state.addNewChildData.dob, format) : '' : ''} rules={[{ required: true, message: 'Please enter DOB!' }]}>
                          <DatePicker name="dob" style={{ width: '100%' }} disabledDate={(current) => {
                            let customDate = moment().format("DD-MM-YYYY");
                            return current && current > moment(customDate, "DD-MM-YYYY");
                          }}
                            placeholder={"DD-MM-YYYY"} format="DD-MM-YYYY" onChange={(e) => this.onAddChildSelectChange('Date', e)} defaultValue={this.state.addNewChildData.dob ? this.state.addNewChildData.dob !== '' ? moment(this.state.addNewChildData.dob, format) : '' : ''} value={this.state.addNewChildData.dob ? this.state.addNewChildData.dob !== '' ? moment(this.state.addNewChildData.dob, format) : '' : ''} />
                        </Form.Item>
                        <Form.Item name="classId" initialValue={this.state.addNewChildData.classId} label="Class" rules={[{ required: true, message: 'Please select Class!' }]}>
                          <Select id="childClass" name="classId" defaultValue={this.state.addNewChildData.classId} value={this.state.addNewChildData.classId} onChange={(e) => this.onAddChildSelectChange('classId', e)} >
                            {this.state.childSeedInfo.classes.map((classes) =>
                              <Option key={classes.classId} dataRef={classes} value={classes.classId}>{classes.name}</Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item name="genderId" initialValue={this.state.addNewChildData.genderId} label="Gender" rules={[{ required: true, message: 'Please select Gender!' }]}>
                          <Select name="genderId" defaultValue={this.state.addNewChildData.genderId} value={this.state.addNewChildData.genderId} onChange={(e) => this.onAddChildSelectChange('genderId', e)}>
                            <Option key="1" value={1}>Male</Option>
                            <Option key="2" value={2}>Female</Option>
                            <Option key="3" value={3}>Other</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="nativeLanguageId" initialValue={this.state.addNewChildData.nativeLanguageId} label="Native Language" rules={[{ required: true, message: 'Please select Language!' }]}>
                          <Select name="nativeLanguageId" defaultValue={this.state.addNewChildData.nativeLanguageId} value={this.state.addNewChildData.nativeLanguageId} onChange={(e) => this.onAddChildSelectChange('nativeLanguageId', e)}>
                            <Option value={1}>English</Option>
                            <Option value={2}>Hindi</Option>
                            <Option value={3}>Assamese</Option>
                            <Option value={4}>Bengali</Option>
                            <Option value={5}>Gujarati</Option>
                            <Option value={6}>Kannada</Option>
                            <Option value={7}>Kashmiri</Option>
                            <Option value={8}>Konkani</Option>
                            <Option value={9}>Malayalam</Option>
                            <Option value={10}>Marathi</Option>
                            <Option value={11}>Nepali</Option>
                            <Option value={12}>Odia</Option>
                            <Option value={13}>Punjabi</Option>
                            <Option value={14}>Sanskrit</Option>
                            <Option value={15}>Tamil</Option>
                            <Option value={16}>Telugu</Option>
                            <Option value={17}>Urdu</Option>
                            {/* {this.state.childSeedInfo.nativeLanguages.map((NativeLanguages) =>
                                <Option key={NativeLanguages.languageId} dataRef={NativeLanguages} value={NativeLanguages.languageId}>{NativeLanguages.name}</Option>)} */}
                          </Select>
                        </Form.Item>
                        <Form.Item name="schoolId" initialValue={this.state.addNewChildData.schoolId} label="School" rules={[{ required: true, message: 'Please select School!' }]}>
                          <Select name="schoolId" defaultValue={this.state.addNewChildData.schoolId} onChange={(e) => this.onAddChildSelectChange('schoolId', e)}>
                            {this.state.childSeedInfo.schoolInfos.map((schoolInfos) =>
                              <Option key={schoolInfos.schoolId} dataRef={schoolInfos} value={schoolInfos.schoolId}>{schoolInfos.name}</Option>)}
                          </Select>
                        </Form.Item>
                        <Form.Item name="prefferedSlot" initialValue={(this.state.addNewChildData.prefferedStartTime && this.state.addNewChildData.prefferedEndTime) ? [this.state.addNewChildData.prefferedStartTime ? this.state.addNewChildData.prefferedStartTime !== '' && this.state.addNewChildData.prefferedStartTime !== null ? moment(this.state.addNewChildData.prefferedStartTime, "HH:mm") : '' : '', this.state.addNewChildData.prefferedEndTime ? this.state.addNewChildData.prefferedEndTime !== '' && this.state.addNewChildData.prefferedEndTime !== null ? moment(this.state.addNewChildData.prefferedEndTime, "HH:mm") : '' : ''] : null} label="Preferred Time" {...rangeConfig}>
                          <TimePicker.RangePicker id="prefferedtimeSlot" order={false} format={"HH:mm"} defaultValue={(this.state.addNewChildData.prefferedStartTime && this.state.addNewChildData.prefferedEndTime) ? this.state.addNewChildData.prefferedStartTime === null && this.state.addNewChildData.prefferedEndTime === null ? null : [this.state.addNewChildData.prefferedStartTime ? this.state.addNewChildData.prefferedStartTime !== '' ? moment(this.state.addNewChildData.prefferedStartTime, "HH:mm") : null : null, this.state.addNewChildData.prefferedEndTime ? this.state.addNewChildData.prefferedEndTime !== '' ? moment(this.state.addNewChildData.prefferedEndTime, "HH:mm") : null : null] : null} onChange={(e) => this.onAddChildSelectChange('timeslot', e)} />
                        </Form.Item>
                        {/* {this.state.userType === 4 &&
                          <Form.Item name="UploadConsent" className='form-fixuploadheight'  label="Upload Consent Form" getValueFromEvent={(e) =>this.getFile(e)} rules={[{ required: true, message: 'Please Upload Consent Form!' }]}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                              <Col className="gutter-row" span={24}>
                                <Upload id="upload_Consentfile" customRequest={this.dummyRequest} onChange={this.uploadtheraphyChange} accept='.jpg,.png,.pdf' maxCount={1}>
                                  <Button icon={<UploadOutlined />}>Upload Consent Form</Button>
                                </Upload>
                              </Col>
                            </Row>
                          </Form.Item>
                        } */}
                      </Card>
                    </Form>
                  </div>
                </Drawer>
              }
              {this.state.showConsentForm &&
                <Modal className="modal-custom-top consent-form"
                  maskClosable={false}
                  title="Consent Form" width={1200}
                  visible={this.state.showConsentForm}
                  onCancel={() => this.onCancelConsentForm()}
                  footer={[
                    <Button onClick={() => this.onCancelConsentForm()}>Cancel</Button>,
                    <Button type="primary" loading={this.state.consentLoading} onClick={() => this.onSubmitConsentForm()}>Submit</Button>
                  ]}>
                  <Space direction="vertical">
                    {/* <Spin indicator={<SettingOutlined spin={true} />} tip="Loading..." spinning={this.state.consentLoading}> */}
                    <div dangerouslySetInnerHTML={{ __html: this.state.consentFormData }}></div>
                    <Checkbox onChange={e => this.onChkterms(e)}>I Consent to the Above Form</Checkbox>
                    {/* </Spin> */}
                  </Space>
                </Modal>}
              {this.state.showPaymentMethod &&
                <Modal className="modal-custom-top"
                  maskClosable={false} height={200} bodyStyle={{ textAlign: 'center' }}
                  title="Payment Options"
                  onCancel={() => this.onPaymentCancel()}
                  visible={this.state.showPaymentMethod}
                  footer={false}>
                  <Space direction='vertical'>
                    <div>
                      <h3 style={{ float: 'left' }}>Note:</h3><h5 className='paymentnote'>For Taking SLD Assessment charges applicable of Rs.250</h5>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <Space >
                        <Button type="primary" onClick={() => this.onNeftPaymentClick()}>Pay Using NEFT</Button>
                        <Button type="primary" onClick={() => this.onSubmitOtherPaymentmethod()}>Other Payment Mode</Button>
                      </Space>
                    </div>
                  </Space>
                </Modal>}
            </div>
          </div>
          {/* } */}
          {this.state.showNEFTPayment &&
            <Modal className="modal-custom-top"
              maskClosable={false} height={200} width={600} bodyStyle={{ textAlign: 'center' }}
              title="NEFT Payment"
              onCancel={() => this.onNEFTPaymentCancel()}
              visible={this.state.showNEFTPayment}
              footer={false}>
              <Space direction='vertical'>
                <h3>Bank Details</h3>
                <div>
                  <table class="table table-bordered mb-0">
                    <tbody>
                      <tr>
                        <td>Account Holder Name</td>
                        <td style={{ textAlign: 'center' }}>
                          VIRIDDHAM
                        </td>
                      </tr>
                      <tr>
                        <td>Account Number</td>
                        <td style={{ textAlign: 'center' }}>
                          4397002100224888
                        </td>
                      </tr>
                      <tr>
                        <td>IFSC Code</td>
                        <td style={{ textAlign: 'center' }}>
                          PUNB0439700
                        </td>
                      </tr>
                      <tr>
                        <td>Bank Name</td>
                        <td style={{ textAlign: 'center' }}>
                          Punjab National Bank
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <Space >
                    <label style={{ float: 'left' }}>Transaction ID</label>
                    <Input style={{ float: 'left' }} name="transactionId" className="form-control" value={this.state.transactionId} onChange={e => this.onInputTransactionChange(e)} />
                    <Button type="primary" loading={this.state.neftpaymentLoading} onClick={() => this.onNeftTransactionClick()}>Submit</Button>
                  </Space >
                </div>
              </Space>
            </Modal>}
          {this.state.regPaymentNotDone &&
            <Modal className="modal-custom-top"
              maskClosable={false} height={200} bodyStyle={{ textAlign: 'center' }}
              title="Payment Options"
              visible={this.state.regPaymentNotDone}
              onCancel={() => this.onNEFTRegPaymentCancel()}
              footer={false}>
              <Space direction='vertical'>
                <div>
                  <h3 style={{ float: 'left' }}>Note:</h3><h5 className='paymentnote'>Inorder to Proceed further,Pay the Registration Fee of Rs.100</h5>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Space >
                    <Button type="primary" onClick={() => this.onNeftRegPaymentClick()}>Pay Using NEFT</Button>
                    <Button type="primary" onClick={() => this.onSubmitRegisterOtherPaymentmethod()}>Other Paymnet Mode</Button>
                  </Space>
                </div>
              </Space>
            </Modal>
          }
        </div>
        <Footer></Footer>
      </div >
    );
  }
}
const mapStateToProp = state => {
  return {
    userLoginStatus: state.userLoginStatus,
    userDetailsJson: state.userDetailsJson,
    childIdValue: state.childIdValue
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    UserDetails: () => dispatch(UserDetails()),
    LoginStatus: userLoginStatus => dispatch(LoginStatus(userLoginStatus)),
    ChildIdData: childIdValue => dispatch(ChildIdData(childIdValue))
  }
}

export default connect(mapStateToProp, mapDispatchToProps)(withRouter(UserDashboard));