import { userDetails, loginStatus, childIdData,paymentDetails } from './Redux.Type'

const initialstate = {
    userDetailsJson: [], userLoginStatus: false,childIdValue: [],
    paymentDetailsJson:[]
}

const ReduxReducer = (state = initialstate, action) => {
    switch (action.type) {
        case userDetails: return {
            ...state,
            userDetailsJson: action.userDetailsJson
        }
        case loginStatus: return {
            ...state,
            userLoginStatus: action.userLoginStatus
        }
        case childIdData: return {
            ...state,
            childIdValue: action.childIdValue
        }
        case paymentDetails: return {
            ...state,
            paymentDetailsJson: action.paymentDetailsJson
        }
        default: return state
    }
}

export default ReduxReducer