import React, { Component } from 'react';
import Header from '../DashBoard/DashBoard';
import Footer from '../Footer/Footer';
import '../MediaCss/viri.css';
import '../MediaCss/form.css';
// import '../MediaCss/bootstrap.min.css';
import './Home.css';
import 'antd/dist/antd.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import "../../node_modules/bootstrap/dist/css/bootstrap.css"
import Carousel from 'react-bootstrap/Carousel'

class Home extends Component {
    constructor(props) {
        super(props);
        AOS.init({ duration: 800 });
    }

    render() {
        return (
            <div>
                <Header></Header>
                <main role="main" class="Maincontainer">
                    <div style={{height:'500px'}}>
                        <div class="part1">
                            <Carousel fade >
                                <Carousel.Item >
                                    <img src="Images/banner1.jpg" style={{height:'500px',width:'100%'}} class="fill" />
                                    <Carousel.Caption class="hero hero-bannertxt1 bannerh1Txt">
                                        <h1>
                                            We address academic<br />
                                            difficulties of your child
                                        </h1>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item >
                                    <img src="Images/banner2.jpg" style={{height:'500px',width:'100%'}} class="fill" />
                                    <Carousel.Caption class="hero hero-bannertxt2 banner2h1Txt">
                                        <h1>
                                            We provide online <br />
                                            assessment, remedial services <br />
                                            to overcome Learning Difficulties
                                        </h1>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item >
                                    <img src="Images/banner3.jpg" style={{height:'500px',width:'100%'}} class="fill" />
                                    <Carousel.Caption class="hero hero-bannertxt3 banner3h1Txt">
                                        <h1>
                                            We offer best medical and<br />
                                            allied health consultations
                                        </h1>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                            {/* <Carousel autoplay >
                            <div >
                                <img src="Images/banner1.jpg" class="fill" />
                                <div class="hero hero-bannertxt1 bannerh1Txt">
                                    <hgroup>
                                        <h1>
                                            We address academic<br />
                                            difficulties of your child
                                        </h1>
                                    </hgroup>
                                </div>
                            </div>
                            <div >
                                <img src="Images/banner2.jpg" class="fill" />
                                <div class="hero hero-bannertxt2 banner2h1Txt">
                                    <hgroup>
                                        <h1>
                                            We provide online <br />
                                            assessment  remedial services <br />
                                            to overcome Learning Difficulties
                                        </h1>
                                    </hgroup>
                                </div>
                            </div>
                            <div >
                                <img src="Images/banner3.jpg" class="fill" />
                                <div class="hero hero-bannertxt3 banner3h1Txt">
                                    <hgroup>
                                        <h1>
                                            We offer best medical and<br />
                                            allied health consultations
                                        </h1>
                                    </hgroup>
                                </div>
                            </div>
                        </Carousel> */}
                            {/* <div id="myCarousel" class="carousel slide" data-interval="2500" data-ride="carousel">
                                <ol class="carousel-indicators">
                                    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                                    <li data-target="#myCarousel" data-slide-to="1"></li>
                                    <li data-target="#myCarousel" data-slide-to="2"></li>
                                </ol>
                                <div class="carousel-inner">
                                    <div class="item active">
                                        <img src="Images/banner1.jpg" class="fill" />
                                        <div class="hero hero-bannertxt1 bannerh1Txt">
                                            <hgroup>
                                                <h1>
                                                    We address academic<br />
                                                    difficulties of your child
                                                </h1>
                                            </hgroup>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <img src="Images/banner2.jpg" class="fill" />
                                        <div class="hero hero-bannertxt2 banner2h1Txt">
                                            <hgroup>
                                                <h1>
                                                    We provide online <br />
                                                    assessment & remedial services <br />
                                                    to overcome Learning Difficulties
                                                </h1>
                                            </hgroup>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <img src="Images/banner3.jpg" class="fill" />
                                        <div class="hero hero-bannertxt3 banner3h1Txt">
                                            <hgroup>
                                                <h1>
                                                    We offer best medical and<br />
                                                    allied health consultations
                                                </h1>
                                            </hgroup>
                                        </div>
                                    </div>
                                </div>

                                <a class="left carousel-control" href="#myCarousel" data-slide="prev"> <span class="icon-prev"></span> </a> <a class="right carousel-control" href="#myCarousel" data-slide="next"> <span class="icon-next"></span> </a>
                            </div> */}
                        </div>
                        <div class="headbdr"></div>
                    </div>
                    <div class="container-fluid">
                        <div >
                            <div class="container" id="home">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="training" style={{ padding: 0 }}>
                                            <h1 data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000" >Specific learning  difficulty Training </h1>
                                            <p >Specific Learning Disability is a disorder in one or more of the basic psychological processes involved in understanding or in using language, spoken or written which manifest itself in imperfect ability to listen, think, speak, read, write, spell or to do mathematical calculations.</p>
                                            <div class="traininglist">
                                                <ul>
                                                    <li><img src="Images/listicon.jpg" />Earlier it was known as Dyslexia.</li>
                                                    <li><img src="Images/listicon.jpg" />While, Dyslexia is a Reading Disorder.</li>
                                                    <li><img src="Images/listicon.jpg" />Dysgraphia is a Writing Disorder.</li>
                                                    <li><img src="Images/listicon.jpg" />Dyspraxia is a Spelling Disorder.</li>
                                                    <li><img src="Images/listicon.jpg" />Dyscalculia is a Mathematical Disorder.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="welcomebg">
                                            <div class="welcomtxt">
                                                <div class="container">
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-8" id="abt">
                                                                <div style={{ marginTop: '4%', textAlign: 'left' }}>
                                                                    <h2 data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500" >Welcome to <span class="titlevidd">Viriddham</span></h2>
                                                                </div>
                                                                <p class="row">Viriddham is an online programme, one to one basis for children with Specific Learning Difficulty namely reading, writing, spelling and mathematical difficulty. Also it offers children with Attention Deficit Hyperactivity Disorder, Autism Spectrum Disorder with appropriate medical and other supportive services to overcome speech/ motor difficulties with the consultation/ assistance from our well qualified and experienced Viriddham panel of consultants.</p>
                                                            </div>
                                                            <div data-aos="fade-up-left" class="col-md-4"><img src="Images/welcomeimg.png" class="img-responsive mtop14 wow bounceInUp" alt="Viriddham" title="Viriddham" /> </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer></Footer>
            </div>
        );
    }
}

export default Home;